import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { isHavePermissionScene, isNullOrEmpty } from './utils/stringUtils';
import { ROUTERS } from './utils/constants';
import { LOCAL_STORAGE_VALUE } from './utils/enum';

import Dashboard from './containers/Dashboard';

import Accounts from './containers/Accounts';
import ConfigLevelSale from './containers/ConfigLevelSale';

import DataManager from './containers/DataManager';
import ImportData from './containers/DataManager/ImportData';
import CreateData from './containers/DataManager/CreateData';
import SplitDataHistory from './containers/DataManager/SplitDataHistory';

import RegisterPackages from './containers/RegisterPackages/RegisterPackages';
import CreateRegisterPackages from './containers/RegisterPackages/CreateRegisterPackages';
// import RegisterPackagesOutside from './containers/RegisterPackagesOutside';
import ExtendPackages from './containers/ExtendPackages';

import Search from './containers/Search';
import TopUp from './containers/TopUp';

import RevenueReports from './containers/RevenueReportsDaily';
import RevenueReportsSaleman from './containers/RevenueReportsSaleman';
import ReportsRatioTotal from './containers/ReportsRatioTotal';

import Page404 from './containers/Page404';

import Login from './containers/Login';
import ChangePassword from './containers/Login/ChangePassword';
import LocalStore from './utils/localStorage';

import DataDivided from './containers/DataManager/DataDivided';
import DataDividedDetail from './containers/DataManager/DataDividedDetail';
import ReportsRatioByDay from './containers/ReportsRatioByDay';
import MainLayout from './components/Layout';
import TakeCareByDay from './containers/TakeCareByDay';
import DataLx3 from './containers/DataManager/DataLx3';
import CallCenter from './containers/CallCenter';
import UserProfile from './containers/UserProfile';
import RevenueReportOld from './containers/RevenueReportsDailyOld';
import RevenueSalemanReportOld from './containers/RevenueReportsSalemanOld';
import RevenueReportsPhoneNumber from './containers/RevenueReportsPhoneNumber';
import Home from './containers/Home';
import { ListProduct } from './containers/ListProduct';
import SearchARPU from './containers/SearchARPU';
import TalkTime from './containers/TalkTime';

const MyRoutes = () => {
  const loginSession = LocalStore.getInstance().read(
    LOCAL_STORAGE_VALUE.loginSession
  );
  // const currentLoggedUser = LocalStore.getInstance().read(
  //   LOCAL_STORAGE_VALUE.currentLoggedUser
  // );

  return (
    <BrowserRouter>
      <Routes>
        {!isNullOrEmpty(loginSession) ? (
          <Route>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              {/* {isHavePermissionScene(ROUTERS.Accounts.key) && (
                <Route path="/" element={<Home />} />
              )} */}
              {isHavePermissionScene(ROUTERS.Accounts.key) && (
                <Route path={ROUTERS.Accounts.path} element={<Accounts />} />
              )}
              {isHavePermissionScene(ROUTERS.ConfigLevelSale.key) && (
                <Route
                  path={ROUTERS.ConfigLevelSale.path}
                  element={<ConfigLevelSale />}
                />
              )}
              {isHavePermissionScene(ROUTERS.DataManager.key) && (
                <Route
                  path={ROUTERS.DataManager.path}
                  element={<DataManager />}
                />
              )}
              {isHavePermissionScene(ROUTERS.DataImport.key) && (
                <Route
                  path={ROUTERS.DataImport.path}
                  element={<ImportData />}
                />
              )}
              {isHavePermissionScene(ROUTERS.DataCreate.key) && (
                <Route
                  path={ROUTERS.DataCreate.path}
                  element={<CreateData />}
                />
              )}
              {isHavePermissionScene(ROUTERS.DataDivided.key) && (
                <Route
                  path={ROUTERS.DataDivided.path}
                  element={<DataDivided />}
                />
              )}
              <Route
                path={ROUTERS.DataDividedDetail.path}
                element={<DataDividedDetail />}
              />
              {isHavePermissionScene(ROUTERS.DataLx3.key) && (
                <Route path={ROUTERS.DataLx3.path} element={<DataLx3 />} />
              )}
              <Route path={ROUTERS.CallCenter.path} element={<CallCenter />} />
              {isHavePermissionScene(ROUTERS.SplitDataHistory.key) && (
                <Route
                  path={ROUTERS.SplitDataHistory.path}
                  element={<SplitDataHistory />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RegisterPackage.key) && (
                <Route
                  path={ROUTERS.RegisterPackage.path}
                  element={<RegisterPackages />}
                />
              )}
              {isHavePermissionScene('create-register-package') && (
                <Route
                  path="/create-register-package"
                  element={<CreateRegisterPackages />}
                />
              )}
              {/* {isHavePermissionScene('register-package-outside') && (
              <Route
                path="/register-package-outside"
                element={<RegisterPackagesOutside />}
              />
            )} */}
              {isHavePermissionScene('extend-packages') && (
                <Route path="/extend-packages" element={<ExtendPackages />} />
              )}
              {isHavePermissionScene('search') && (
                <Route path="/search" element={<Search />} />
              )}
              {isHavePermissionScene(ROUTERS.SearchARPU.key) && (
                <Route
                  path={ROUTERS.SearchARPU.path}
                  element={<SearchARPU />}
                />
              )}
              {isHavePermissionScene('top-up') && (
                <Route path="/top-up" element={<TopUp />} />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsDaily.key) && (
                <Route
                  path={ROUTERS.RevenueReportsDaily.path}
                  element={<RevenueReports />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsSaleman.key) && (
                <Route
                  path={ROUTERS.RevenueReportsSaleman.path}
                  element={<RevenueReportsSaleman />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsPhone.key) && (
                <Route
                  path={ROUTERS.RevenueReportsPhone.path}
                  element={<RevenueReportsPhoneNumber />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsDailyOld.key) && (
                <Route
                  path={ROUTERS.RevenueReportsDailyOld.path}
                  element={<RevenueReportOld />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsSalemanOld.key) && (
                <Route
                  path={ROUTERS.RevenueReportsSalemanOld.path}
                  element={<RevenueSalemanReportOld />}
                />
              )}
              {isHavePermissionScene(ROUTERS.ReportsRatioTotal.key) && (
                <Route
                  path={ROUTERS.ReportsRatioTotal.path}
                  element={<ReportsRatioTotal />}
                />
              )}
              {isHavePermissionScene(ROUTERS.ReportsRatioByDay.key) && (
                <Route
                  path={ROUTERS.ReportsRatioByDay.path}
                  element={<ReportsRatioByDay />}
                />
              )}
              {isHavePermissionScene(ROUTERS.TakeCareByDay.key) && (
                <Route
                  path={ROUTERS.TakeCareByDay.path}
                  element={<TakeCareByDay />}
                />
              )}
              <Route
                path={ROUTERS.UserProfile.path}
                element={<UserProfile />}
              />

              {isHavePermissionScene(ROUTERS.RevenueReportsSaleman.key) && (
                <Route
                  path={ROUTERS.RevenueReportsSaleman.path}
                  element={<RevenueReportsSaleman />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsPhone.key) && (
                <Route
                  path={ROUTERS.RevenueReportsPhone.path}
                  element={<RevenueReportsPhoneNumber />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsDailyOld.key) && (
                <Route
                  path={ROUTERS.RevenueReportsDailyOld.path}
                  element={<RevenueReportOld />}
                />
              )}
              {isHavePermissionScene(ROUTERS.RevenueReportsSalemanOld.key) && (
                <Route
                  path={ROUTERS.RevenueReportsSalemanOld.path}
                  element={<RevenueSalemanReportOld />}
                />
              )}
              {isHavePermissionScene(ROUTERS.ReportsRatioTotal.key) && (
                <Route
                  path={ROUTERS.ReportsRatioTotal.path}
                  element={<ReportsRatioTotal />}
                />
              )}
              {isHavePermissionScene(ROUTERS.ReportsRatioByDay.key) && (
                <Route
                  path={ROUTERS.ReportsRatioByDay.path}
                  element={<ReportsRatioByDay />}
                />
              )}
              {isHavePermissionScene(ROUTERS.TakeCareByDay.key) && (
                <Route
                  path={ROUTERS.TakeCareByDay.path}
                  element={<TakeCareByDay />}
                />
              )}
              {isHavePermissionScene(ROUTERS.ListProduct.key) && (
                <Route
                  path={ROUTERS.ListProduct.path}
                  element={<ListProduct />}
                />
              )}
              {isHavePermissionScene(ROUTERS.TalkTimeMonitor.key) && (
                <Route
                  path={ROUTERS.TalkTimeMonitor.path}
                  element={<TalkTime />}
                />
              )}
              <Route
                path={ROUTERS.UserProfile.path}
                element={<UserProfile />}
              />
              <Route path="*" element={<Page404 />} />
            </Route>
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route
              path={ROUTERS.ChangePassword.path}
              element={<ChangePassword />}
            />
            <Route path="*" element={<Page404 />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};
export default MyRoutes;
