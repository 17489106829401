import React, { useState } from 'react';
import {
  DatabaseOutlined,
  DownloadOutlined,
  MoreOutlined,
  SearchOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
  MenuProps,
  notification,
  Popover,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import { DATE_FORMAT, ROLES, SOURCES_SYS_LIST } from 'src/utils/constants';
import {
  convertDataTree,
  isHavePermission,
  isNullOrEmpty,
  isUserHaveOneOfPermissions,
} from 'src/utils/stringUtils';
import SelectSaleMan from 'src/components/SelectSaleMan';
import dataManagerServices from 'src/services/dataManager.service';
import ContentMoveData from './ContentMoveData';
import ContentRecallData from './ContentRecallData';
import { DataDividedState } from '..';
import {
  contentBoxDivided,
  inputStyleDivided,
  txtTitleDivided,
} from 'src/styles';
import { useWindowSize } from 'src/utils/customHooks';
import FiltersButton from 'src/components/FiltersButton';
import { SHOW_PARENT } from 'rc-tree-select';
import moment from 'moment';
import './style.scss';

type Props = {
  style?: any;
  dataDividedState: DataDividedState;
  extendSearch: boolean;
  isLoadingExport: boolean;
  loadingReset: boolean;
  selectedMsisdns: string[];
  onChangeDateDivided: (values: any, formatString: [string, string]) => void;
  onChangePhone: (phone: any) => void;
  onKeyDown: (e: any) => void;
  onChangeSourceSys: (value: string) => void;
  onChangePackage: (e: any) => void;
  onChangeExpiredDate: (values: any, formatString: [string, string]) => void;
  onChangeStaff: (value: string[]) => void;
  onChangeTakeCare: (value: string[]) => void;
  onClearTakeCare: () => void;
  onExtend: () => void;
  onSearch: () => void;
  onExport: () => void;
  onReset: () => void;
  onRefresh: () => void;
  getSelectedPhones: () => Promise<any>;
  onChangeReason: (value: any) => void;
  onChangeLastCare: (value: any) => void;
  onChangeAllocated: (value: any) => void;
};

export type State = {
  openModalMoveData: boolean;
  openModalRecallData: boolean;
  selectedStaff: string | undefined;
  loadingRecall: boolean;
  loadingMove: boolean;
  loadingFetchMoveData: boolean;
  loadingFetchRecallData: boolean;
  selectedPhones: string[];
  transferPhones: string[];
};

export const DataDividedFilters: React.FC<Props> = (props) => {
  const {
    style,
    dataDividedState,
    extendSearch,
    isLoadingExport,
    loadingReset,
    selectedMsisdns,
    onChangeDateDivided,
    onChangePhone,
    onKeyDown,
    onChangePackage,
    onChangeExpiredDate,
    onChangeStaff,
    onChangeTakeCare,
    onClearTakeCare,
    onExtend,
    onSearch,
    onExport,
    onReset,
    getSelectedPhones,
    onChangeReason,
    onChangeAllocated,
    onChangeSourceSys,
  } = props;

  const { RangePicker } = DatePicker;

  const defaultState = {
    selectedStaff: undefined,
    openModalMoveData: false,
    openModalRecallData: false,
    loadingRecall: false,
    loadingMove: false,
    loadingFetchMoveData: true,
    loadingFetchRecallData: true,
    selectedPhones: [],
    transferPhones: [],
  };
  const [UIstate, setUIState] = useState<State>(defaultState);
  const windowSize = useWindowSize();
  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  // recall data
  const getInfoRecallData = async () => {
    const phones = await getSelectedPhones();
    setUIState((cur) => ({
      ...cur,
      openModalRecallData: true,
    }));
    const response = await dataManagerServices.filterRecallData(phones);
    setUIState((cur) => ({
      ...cur,
      loadingFetchRecallData: false,
      selectedPhones: phones,
      transferPhones: response.data,
    }));
  };

  const onOpenRecallData = async (visible: boolean) => {
    // case close
    if (!visible) {
      setUIState((cur) => ({
        ...cur,
        openModalRecallData: false,
        loadingFetchRecallData: true,
        selectedPhones: [],
        transferPhones: [],
      }));
      return;
    }
    // case open
    if (selectedMsisdns?.length > 0) {
      getInfoRecallData();
    } else {
      notification.error({
        message: 'Vui lòng chọn data cần thu hồi',
      });
    }
  };

  const onRecallData = async () => {
    setUIState((cur) => ({
      ...cur,
      loadingRecall: true,
    }));
    const response = await dataManagerServices.evictData(
      UIstate.transferPhones
    );
    if (response?.status === 200) {
      notification.success({
        message: response?.data,
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      notification.error({
        message:
          response?.data?.errorCode === 'recall_data_list_empty'
            ? 'Không thể chuyển data vì đã ghi nhận doanh thu'
            : 'Có lỗi xảy ra!!!',
      });
    }

    setUIState((cur) => ({
      ...cur,
      loadingRecall: false,
      openModalRecallData: false,
    }));
  };

  // move data
  const getInfoMoveData = async () => {
    const phones = await getSelectedPhones();
    setUIState((cur) => ({
      ...cur,
      openModalMoveData: true,
    }));
    const response = await dataManagerServices.filterMoveData(phones);
    setUIState((cur) => ({
      ...cur,
      loadingFetchMoveData: false,
      selectedPhones: phones,
      transferPhones: response.data,
    }));
  };

  const onOpenMoveData = async (visible: boolean) => {
    // case close
    if (!visible) {
      setUIState((cur) => ({
        ...cur,
        openModalMoveData: false,
        loadingFetchMoveData: true,
        selectedPhones: [],
        transferPhones: [],
      }));
      return;
    }
    // case open
    if (selectedMsisdns?.length > 0) {
      getInfoMoveData();
    } else {
      notification.error({
        message: 'Vui lòng chọn data cần chuyển',
      });
    }
  };

  const onSelectSaleMan = (value: string) => {
    if (isNullOrEmpty(value)) {
      setUIState((cur) => ({
        ...cur,
        selectedStaff: undefined,
      }));
      return;
    }
    // is selected sale leader
    if (value.includes('team_sale_')) {
      const itemSelected = dataDividedState.staffs?.find(
        (item: any) => item.value === value
      );
      setUIState((cur: State) => ({
        ...cur,
        selectedStaff: itemSelected?.userName,
      }));
    } else {
      setUIState((cur: State) => ({
        ...cur,
        selectedStaff: value,
      }));
    }
  };

  const onMoveData = async () => {
    if (isNullOrEmpty(UIstate.selectedStaff)) {
      return;
    }
    setUIState((cur) => ({
      ...cur,
      loadingMove: true,
    }));
    const response = await dataManagerServices.moveData(
      UIstate.selectedStaff as string,
      UIstate.transferPhones
    );
    if (response?.status === 200) {
      notification.success({
        message: response?.data,
      });
      setTimeout(() => {
        window.location.reload();
      }, 400);
    } else {
      notification.error({
        message:
          response?.data?.errorCode === 'move_data_list_empty'
            ? 'Không thể chuyển data vì đã ghi nhận doanh thu'
            : 'Có lỗi xảy ra!!!',
      });
    }
    setUIState((cur) => ({
      ...cur,
      loadingMove: false,
      openModalMoveData: false,
    }));
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button size={size} icon={<DatabaseOutlined />} className={'btn-menu'}>
          Thu hồi data
        </Button>
      ),
      onClick: () => onOpenRecallData(true),
    },
    {
      key: '2',
      label: (
        <Button
          size={size}
          icon={<UserSwitchOutlined />}
          className={'btn-menu'}
        >
          Chuyển data
        </Button>
      ),
      onClick: () => onOpenMoveData(true),
    },
  ];

  const filterTreeNode = (string: string, node: any) => {
    if (!node?.title) return false;
    const strSearch = string.toLowerCase();
    return (node?.title as string)?.toLowerCase()?.indexOf(strSearch) !== -1
      ? true
      : false;
  };

  const onDropdownOpenChange = (open: boolean) => {
    if (!open && UIstate.openModalMoveData) {
      setUIState((cur) => ({
        ...cur,
        openModalMoveData: false,
      }));
    }
  };

  const overlayStyle = {
    width: 320,
  };
  const isSaleMan = isHavePermission(ROLES.ROLE_SALE_MAN);
  if (loadingReset) return <div style={{ height: 180 }} />;

  return (
    <div style={style}>
      <div
        className={`dpl-flex align-items-center pd-8 pdl-15 pdr-15 ${
          extendSearch ? 'box-showdow-bottom' : ''
        }`}
      >
        <InputNumber
          size={size}
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder={'Nhập Số điện thoại...'}
          controls={false}
          style={{
            ...inputStyleDivided,
            width: '24%',
            marginLeft: 0,
            marginBottom: '0px !important',
          }}
          onChange={onChangePhone}
          onKeyDown={onKeyDown}
        />
        {!extendSearch && (
          <>
            <Button
              onClick={onExtend}
              type="primary"
              size={size}
              style={{ marginLeft: 12 }}
            >
              Bộ lọc
            </Button>
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.5)',
                marginLeft: 10,
              }}
            >
              Lọc theo trường: Trạng thái:{' '}
              <span style={{ fontWeight: 'bold' }}>Đang chăm sóc</span>
            </span>
          </>
        )}

        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {!isSaleMan && (
            <Button
              className="btn-export"
              onClick={onExport}
              type="default"
              size={size}
              icon={<DownloadOutlined />}
              loading={isLoadingExport}
            >
              Export
            </Button>
          )}
          {isUserHaveOneOfPermissions([
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_SALE_MANAGER,
            ROLES.ROLE_SALE_LEADER,
          ]) && (
            <>
              <Dropdown
                menu={{ items }}
                placement="bottomLeft"
                onOpenChange={onDropdownOpenChange}
              >
                <Button
                  key="options"
                  className="btn-filter"
                  style={{ width: 24, marginLeft: 8 }}
                  size={size}
                  icon={<MoreOutlined style={{ fontSize: 24 }} />}
                />
              </Dropdown>

              <div style={{ marginTop: 20 }}>
                <Popover
                  key="evict-data"
                  open={UIstate.openModalRecallData}
                  placement="bottomRight"
                  trigger="click"
                  overlayStyle={overlayStyle}
                  content={
                    <ContentRecallData
                      UIstate={UIstate}
                      onRecallData={onRecallData}
                    />
                  }
                  onOpenChange={onOpenRecallData}
                />
                <Popover
                  key="move-data"
                  open={UIstate.openModalMoveData}
                  placement="bottomRight"
                  trigger="click"
                  overlayStyle={overlayStyle}
                  content={
                    <ContentMoveData
                      UIstate={UIstate}
                      staffs={dataDividedState.staffs}
                      onSelectSaleMan={onSelectSaleMan}
                      onMoveData={onMoveData}
                    />
                  }
                  onOpenChange={onOpenMoveData}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {extendSearch && (
        <div className={'data-divided-filters box-showdow-bottom'}>
          <Row>
            <Col span={6} style={{ marginBottom: '10px' }}>
              <div style={contentBoxDivided}>
                <p style={txtTitleDivided}>Gói đang dùng</p>
                <Input
                  style={inputStyleDivided}
                  size={size}
                  onKeyDown={onKeyDown}
                  onChange={onChangePackage}
                  value={dataDividedState.filters.packActive}
                  placeholder="Nhập tên gói..."
                />
              </div>
              <div style={contentBoxDivided}>
                <p style={txtTitleDivided}>Ngày chia data</p>
                <RangePicker
                  allowClear
                  size={size}
                  onChange={onChangeDateDivided}
                  placeholder={['Từ', 'Đến']}
                  format={DATE_FORMAT}
                  style={inputStyleDivided}
                  defaultValue={
                    dataDividedState?.filters?.assignedDateFrom
                      ? [
                          moment(dataDividedState?.filters?.assignedDateFrom),
                          moment(dataDividedState?.filters?.assignedDateTo),
                        ]
                      : undefined
                  }
                />
              </div>
            </Col>

            <Col span={6}>
              <div style={contentBoxDivided}>
                <p style={txtTitleDivided}>Ngày hết hạn</p>
                <RangePicker
                  allowClear
                  size={size}
                  onChange={onChangeExpiredDate}
                  placeholder={['Từ', 'Đến']}
                  format={DATE_FORMAT}
                  style={inputStyleDivided}
                  defaultValue={
                    dataDividedState?.filters?.expiredDateFrom
                      ? [
                          moment(dataDividedState?.filters?.expiredDateFrom),
                          moment(dataDividedState?.filters?.expiredDateTo),
                        ]
                      : undefined
                  }
                />
              </div>
              <div style={contentBoxDivided}>
                <p style={txtTitleDivided}>Ngày phân bố data</p>
                <RangePicker
                  allowClear
                  size={size}
                  onChange={onChangeAllocated}
                  placeholder={['Từ', 'Đến']}
                  format={DATE_FORMAT}
                  style={inputStyleDivided}
                  defaultValue={
                    dataDividedState?.filters?.allocatedDateFrom
                      ? [
                          moment(dataDividedState?.filters?.allocatedDateFrom),
                          moment(dataDividedState?.filters?.allocatedDateTo),
                        ]
                      : undefined
                  }
                />
              </div>
            </Col>

            <Col span={6}>
              {!isNullOrEmpty(dataDividedState.treeTakeCare) && (
                <div style={contentBoxDivided}>
                  <p style={{ ...txtTitleDivided }}>Trạng thái chăm sóc</p>
                  <TreeSelect
                    allowClear
                    showArrow
                    size={size}
                    style={inputStyleDivided}
                    treeData={convertDataTree(dataDividedState.treeTakeCare)}
                    maxTagCount="responsive"
                    placeholder={'Tất cả'}
                    treeDefaultExpandAll
                    onChange={onChangeTakeCare}
                    onClear={onClearTakeCare}
                    filterTreeNode={filterTreeNode}
                    treeCheckable={true}
                    value={dataDividedState.takeCareSelected}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  />
                </div>
              )}
              {/* <div style={contentBoxDivided}>
                <p style={txtTitleDivided}>Lần chăm sóc cuối</p>
                <RangePicker
                  allowClear
                  size={size}
                  onChange={onChangeLastCare}
                  placeholder={['Từ', 'Đến']}
                  format={'DD/MM/YYYY'}
                  style={inputStyleDivided}
                  defaultValue={
                    dataDividedState?.filters?.latestCaringDateFrom
                      ? [
                          moment(
                            dataDividedState?.filters?.latestCaringDateFrom
                          ),
                          moment(dataDividedState?.filters?.latestCaringDateTo),
                        ]
                      : undefined
                  }
                />
              </div> */}
              <div style={contentBoxDivided}>
                <p style={{ ...txtTitleDivided }}>Lý do</p>
                <TreeSelect
                  treeData={dataDividedState.treeReason}
                  style={inputStyleDivided}
                  size={size}
                  treeCheckable={true}
                  showCheckedStrategy={SHOW_PARENT}
                  onChange={onChangeReason}
                  defaultValue={dataDividedState.defaultTreeReason}
                  placeholder="Tất cả"
                />
              </div>
            </Col>
            <Col span={6}>
              {isUserHaveOneOfPermissions([
                ROLES.ROLE_ADMIN,
                ROLES.ROLE_SALE_MANAGER,
                ROLES.ROLE_SALE_LEADER,
                ROLES.ROLE_ACCOUNTANT,
              ]) && (
                <div style={contentBoxDivided}>
                  <p style={{ ...txtTitleDivided }}>Sale phụ trách</p>
                  <SelectSaleMan
                    style={inputStyleDivided}
                    size={size}
                    treeData={dataDividedState.staffs}
                    value={dataDividedState.staffSelected}
                    onChange={onChangeStaff}
                    placeholder={'Tất cả'}
                  />
                </div>
              )}
              <div style={contentBoxDivided}>
                <p style={{ ...txtTitleDivided }}>Loại thuê bao</p>
                <Select
                  style={inputStyleDivided}
                  size={size}
                  placeholder={'Tất cả'}
                  defaultValue={SOURCES_SYS_LIST[0].value}
                  onChange={onChangeSourceSys}
                  options={SOURCES_SYS_LIST}
                />
              </div>
            </Col>
          </Row>
          <FiltersButton
            size={size}
            onSearch={onSearch}
            onExtend={onExtend}
            onReset={onReset}
          />
        </div>
      )}
    </div>
  );
};
