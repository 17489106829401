import React, { useEffect, useState } from 'react';
import { SaveOutlined, SendOutlined } from '@ant-design/icons';
import {
  Button,
  InputNumber,
  Input,
  Select,
  Table,
  Modal,
  notification,
} from 'antd';
import registerPackageServices from '../../services/registerPackage.service';
import { updateQueryStringParameter } from '../../utils/stringUtils';
import { useWindowSize } from '../../utils/customHooks';
import moment from 'moment';
import _ from 'lodash';
import { GLOBAL_HEIGHT } from '../../utils/constants';
import { boxFilterStyle } from 'src/styles';
import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';

function CreateRegisterPackages() {
  const windowSize = useWindowSize();

  const [dataList, setDataList] = useState([]);
  const [dataListOrigin, setDataListOrigin] = useState([]);

  const [dataRegister, setDataRegister] = useState({});
  const [dataRegisterError, setDataRegisterError] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [listPackage, setListPackage] = useState([]);

  useEffect(() => {
    getListData();
    getListPackage();
  }, []);

  const getListData = async () => {
    setIsLoading(true);
    // let query = buildQueryString()
    const resp = await registerPackageServices.getListRegister();
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataList(data);
      setDataListOrigin(_.cloneDeep(data));
    }
    setIsLoading(false);
  };

  const getListPackage = async () => {
    const resp = await registerPackageServices.getListPackage();
    const data = resp?.data;
    if (resp?.status === 200) {
      setListPackage(data);
    }
  };

  const onValidateRegister = () => {
    let dataError = {};
    let check = false;
    if (!dataRegister?.msisdn) {
      dataError.msisdn = 'Số điện thoại sai định dạng';
      check = true;
    } else {
      if (dataRegister?.msisdn.toString().length !== 9) {
        dataError.msisdn = 'Số điện thoại sai định dạng';
        check = true;
      } else {
        dataError.msisdn = '';
      }
    }

    if (!dataRegister?.packageCode) {
      dataError.packageCode = 'Gói cước không hợp lệ';
      check = true;
    } else {
      dataError.packageCode = '';
    }

    setDataRegisterError(dataError);
    return check;
  };

  const onRegisterPackage = async () => {
    if (onValidateRegister()) {
      return;
    }

    let queryString = '';
    if (dataRegister.msisdn) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        dataRegister.msisdn
      );
    }
    if (dataRegister.packageCode) {
      queryString = updateQueryStringParameter(
        queryString,
        'packageCode',
        dataRegister.packageCode
      );
    }

    setIsSubmit(true);

    const resp = await registerPackageServices.createRegisterPackages(
      queryString
    );
    const data = resp?.data;

    if (resp?.status === 200) {
      setDataRegister({});
      getListData();
      Modal.success({
        title: 'Tạo yêu cầu đăng ký thành công',
        content:
          'Mã OTP đã được gửi tới số điện thoại của quý khách (Mã OTP có hiệu lực trong vòng 24h kể từ khi nhận mã)!',
      });
    } else {
      if (data?.errorCode === 'phone_invalid') {
        Modal.error({
          title: (
            <div>
              Yêu cầu đăng ký không được thực hiện do SĐT{' '}
              <span className="bold">{dataRegister.msisdn}</span> không tồn tại
              trong hệ thống
            </div>
          ),
          okText: 'Đã hiểu',
        });
      } else {
        Modal.error({
          title: 'Yêu cầu đăng ký thất bại',
          content: data?.message,
          okText: 'Đóng',
        });
      }
    }
    setIsSubmit(false);
  };

  const onSubmitConfirmOtp = async (item, index) => {
    let queryString = '';
    if (!item?.otp) {
      notification.error({
        message: 'Cần nhập mã OTP',
      });
      return;
    }

    let newArr = [...dataList];
    newArr[index] = { ...item, isSubmit: true };
    setDataList(newArr);

    if (item.phoneNumber) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        item.phoneNumber
      );
    }
    if (item.phonePackage) {
      queryString = updateQueryStringParameter(
        queryString,
        'packageCode',
        item.phonePackage
      );
    }
    if (item.otp) {
      queryString = updateQueryStringParameter(queryString, 'otp', item.otp);
    }

    const resp = await registerPackageServices.registerPackages(queryString);
    const data = resp?.data;
    if (resp?.status === 200) {
      Modal.success({
        title: 'Đăng ký thành công',
        content: data?.message,
      });
      getListData();
    } else {
      getListData();
      Modal.error({
        title: 'Đăng ký thất bại',
        content: data?.message,
      });
    }
  };

  const onUpdateOtp = async (item, index, cb) => {
    const isEdit = (item?.otp || '') !== (dataListOrigin[index]?.otp || '');
    if (!isEdit) {
      let newArr = [...dataListOrigin];
      newArr[index] = { ...item };
      setDataListOrigin(newArr);
      return;
    }

    const resp = await registerPackageServices.updateOtp(item?.id, item?.otp);
    const data = resp?.data;
    if (resp?.status === 200) {
      let newArr = [...dataListOrigin];
      newArr[index] = { ...item };
      setDataListOrigin(newArr);
      notification.success({
        message: 'Cập nhật OTP',
        description: 'Thành công',
      });
      if (cb) {
        cb(item, index);
      }
    } else {
      Modal.error({
        title: 'Cập nhật thất bại',
        content: data?.message,
      });
    }
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (_cell, _record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '15%',
      render: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Số điện thoại đăng ký',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
    },
    {
      title: 'Gói cước',
      dataIndex: 'phonePackage',
      key: 'phonePackage',
      width: '10%',
    },
    {
      title: 'Nhập mã xác nhận',
      dataIndex: 'otp',
      key: 'otp',
      render: (cell, record, index) => {
        const isEdit =
          (record?.otp || '') !== (dataListOrigin[index]?.otp || '');
        return (
          <div className="cell-otp-container">
            <Input
              placeholder="Nhập mã xác nhận"
              value={record?.otp}
              onChange={(e) => {
                let newArr = [...dataList];
                newArr[index] = { ...record, otp: e.target.value };
                setDataList(newArr);
                console.log('newArr');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  if (isEdit) {
                    onUpdateOtp(record, index);
                  }
                }
              }}
              addonAfter={
                <div
                  className={isEdit ? 'cursor-pointer' : ''}
                  onClick={() => {
                    if (isEdit) {
                      onUpdateOtp(record, index);
                    }
                  }}
                >
                  <SaveOutlined
                    style={{ color: isEdit ? '#52c41a' : '#a8a8a8' }}
                  />
                </div>
              }
            />
          </div>
        );
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (cell, record, index) => {
        const isEdit =
          (record?.otp || '') !== (dataListOrigin[index]?.otp || '');
        return (
          <div>
            <Button
              loading={record.isSubmit}
              className="btn-primary-ghost"
              icon={<SendOutlined />}
              onClick={() => {
                if (isEdit) {
                  onUpdateOtp(record, index, onSubmitConfirmOtp);
                } else {
                  onSubmitConfirmOtp(record, index);
                }
              }}
            >
              Đăng ký
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      render: (cell) => {
        return <div dangerouslySetInnerHTML={{ __html: cell }} />;
      },
    },
  ];

  let tableHeight =
    windowSize?.height -
    GLOBAL_HEIGHT -
    52 -
    (dataRegisterError?.msisdn || dataRegisterError?.packageCode ? 92 : 72);

  return (
    <div>
      <Helmet>
        <title>Tạo yêu cầu đăng ký</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <div className="box-register-package mgt-15">
        <div className="box-register-package-item">
          <InputNumber
            placeholder="Nhập số điện thoại"
            value={dataRegister?.msisdn}
            onChange={(e) => {
              setDataRegister({ ...dataRegister, msisdn: e });
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onRegisterPackage();
              }
            }}
            style={boxFilterStyle}
          />
          {dataRegisterError?.msisdn && (
            <div className="txt-error">{dataRegisterError?.msisdn}</div>
          )}
        </div>

        <div className="box-register-package-item">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            placeholder={'Gói cước đăng ký'}
            value={dataRegister?.packageCode}
            onChange={(value) =>
              setDataRegister({ ...dataRegister, packageCode: value })
            }
          >
            {listPackage.map((item, index) => (
              <Select.Option key={index} value={item.code}>
                {item.code}
              </Select.Option>
            ))}
          </Select>
          {dataRegisterError?.packageCode && (
            <div className="txt-error">{dataRegisterError?.packageCode}</div>
          )}
        </div>

        <Button loading={isSubmit} onClick={onRegisterPackage} type="primary">
          Tạo yêu cầu
        </Button>
      </div>

      <Table
        loading={isLoading}
        rowKey={'id'}
        dataSource={dataList}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: dataList.length,
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default CreateRegisterPackages;
