import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import careSoftServices from 'src/services/caresoft.service';
import { CARE_SOFT_ACTION } from 'src/services/careSoft/useCareSoftHook';
import { ROUTERS } from 'src/utils/constants';
import soundRings from '../../assets/rings.mp3';
import Phone from './assets/phone.svg';
import ComingPhone from './assets/comingPhone.svg';
import CancelPhone from './assets/cancelPhone.svg';
import Bell from './assets/bell.svg';
import MuteBell from './assets/muteBell.svg';
import AppContext from 'src/context/AppContext';

const BubbleCallIn = () => {
  const { careSoftState, careSoftDispatch }: ContextType =
    useContext(AppContext);
  const [isMuteBell, setIsMuteBell] = useState(false);
  const audio = useMemo(() => new Audio(soundRings), []);
  audio.loop = true;
  audio.play();
  const navigate = useNavigate();

  const handleChangeBell = () => {
    setIsMuteBell(!isMuteBell);
  };

  const onCancel = () => {
    careSoftServices.endCall();
    audio.pause();
  };

  const onAcceptCall = () => {
    careSoftDispatch({ type: CARE_SOFT_ACTION.onAcceptCall });
    const path = generatePath(ROUTERS.DataDividedDetail.path, {
      phone: Number(careSoftState?.phoneCallRinging),
    });
    navigate(path);
    audio.pause();
  };

  useEffect(() => {
    return () => {
      audio.pause();
    };
  }, [audio]);

  useEffect(() => {
    if (isMuteBell) {
      audio.pause();
    } else {
      audio.play();
    }
  }, [audio, isMuteBell]);

  return (
    <div id={'bubble'} style={containerStyle}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #dcd9d9a3',
          padding: '5px 20px',
        }}
      >
        <div onClick={handleChangeBell} style={{ cursor: 'pointer' }}>
          {isMuteBell ? (
            <img
              src={MuteBell}
              style={{ width: 25, height: 25, marginRight: 10 }}
            />
          ) : (
            <img
              src={Bell}
              style={{ width: 25, height: 25, marginRight: 10 }}
            />
          )}
        </div>
      </div>
      <div style={{ padding: 30 }}>
        <div style={{ display: 'flex' }}>
          <p style={{ fontSize: 20, marginBottom: 0 }}>
            <img
              src={Phone}
              style={{ width: 25, height: 25, marginRight: 10 }}
            />{' '}
            Đang có cuộc gọi đến
          </p>
        </div>
        <p style={txtPhone}>{careSoftState?.phoneCallRinging}</p>

        <div style={boxBtn}>
          <div
            style={{
              ...boxPhone,
              background: '#f11e1e',
            }}
            onClick={onCancel}
          >
            <img src={CancelPhone} style={{ width: 70, height: 70 }} />
          </div>
          <div
            style={{
              ...boxPhone,
              background: 'rgb(88, 215, 88)',
            }}
            onClick={onAcceptCall}
          >
            <img src={ComingPhone} style={{ width: 50, height: 50 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BubbleCallIn);

const containerStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: 20,
  right: 10,
  background: '#fff',
  boxShadow:
    'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
  justifyContent: 'center',
  borderRadius: 15,
  zIndex: 100,
};

const txtPhone: React.CSSProperties = {
  fontSize: 24,
  fontWeight: 'bold',
  color: '#277CBE',
  paddingLeft: 10,
  marginBottom: 0,
  textAlign: 'center',
};

const boxBtn: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '60px',
  marginTop: '30px',
};

const boxPhone: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  width: '70px',
  height: '70px',
  cursor: 'pointer',
};
