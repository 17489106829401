import { useState } from 'react';
import { notification, TreeSelect } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import dataManagerServices from 'src/services/dataManager.service';

type Props = {
  dataId: number;
  onSuccess: () => void;
  listStaff: any;
  disabled?: boolean;
  type?: 'undivided' | 'recall';
};

const AsignData = (props: Props) => {
  const [inputState, setInputState] = useState(true);
  const [value, setValue] = useState<any>();
  const { dataId, listStaff, onSuccess, disabled, type = 'undivided' } = props;

  const assignData = () => {
    if (!disabled) {
      setInputState(!inputState);
      setValue(null);
    }
  };

  const blurAsign = () => {
    if (!value) {
      setInputState(true);
    } else {
      setInputState(false);
    }
  };

  const getChange = (staff: any) => {
    const curStaff = listStaff.find((x: any) => x.value === staff);
    if (!curStaff) {
      setValue(staff);
    }
  };

  const postData = async () => {
    if (type === 'undivided') {
      const res = await dataManagerServices.postAssignData(dataId, value);
      if (res?.status === 200) {
        notification.success({
          message: 'Gán data thành công',
          description: 'Thành công',
        });
        onSuccess?.();
      } else {
        notification.error({
          message: 'Gán data thất bại',
          description: 'Thất bại',
        });
      }
    } else {
      const res = await dataManagerServices.postAssignDataRecall(dataId, value);
      if (res?.status === 200) {
        notification.success({
          message: 'Gán data thành công',
        });
        onSuccess?.();
      } else {
        notification.error({
          message: 'Lỗi ! Data thu hồi chỉ được gán cho saleman',
        });
      }
    }

    assignData();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 0 !important',
      }}
    >
      {inputState ? (
        <span
          onClick={assignData}
          style={{ cursor: 'pointer', color: disabled ? 'gray' : '#61b6fb' }}
        >
          Gán data
        </span>
      ) : (
        <>
          <TreeSelect
            defaultOpen
            size="small"
            value={value ? value + ' ' : null}
            style={{
              width: '85%',
              position: 'relative',
            }}
            showSearch
            dropdownStyle={{
              maxHeight: 300,
              overflow: 'auto',
              minWidth: 300,
            }}
            placeholder="Chọn sale"
            dropdownMatchSelectWidth={false}
            allowClear
            showArrow={false}
            treeDefaultExpandAll
            placement="bottomRight"
            autoFocus
            treeData={listStaff}
            onChange={(staff) => {
              getChange(staff);
              if (!staff) {
                setInputState(true);
              } else {
                setInputState(false);
              }
            }}
            onBlur={blurAsign}
          />
          {value && (
            <ArrowRightOutlined
              onClick={postData}
              style={{
                position: 'absolute',
                right: '3px',
                cursor: 'pointer',
                color: '#69BE27',
                fontSize: '20px',
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AsignData;
