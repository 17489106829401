import { LoadingOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import React, { useEffect } from 'react';
import AppContext from 'src/context/AppContext';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';
import useDataCaring from 'src/hooks/useDataCaring';
import CustomTable from '../Table';
import { isUserHaveOneOfPermissions } from 'src/utils/stringUtils';
import { ROLES } from 'src/utils/constants';

function DataCaring() {
  const { appState, dispatch } = React.useContext(AppContext);
  // const { dataCaring, getDataCaring } = useDataCaring();

  // useEffect(() => {
  //   isUserHaveOneOfPermissions([
  //     ROLES.ROLE_SALE_MAN,
  //     ROLES.ROLE_SALE_LEADER,
  //     ROLES.ROLE_SALE_MANAGER,
  //   ]) && getDataCaring();
  // }, []);

  return (
    <div className="mt-5">
      <div className="pl-6 pr-6 mt-1">
        <div>
          Xin Chào <span className="bold">{appState.profile?.fullName}</span> 👋🏽
        </div>{' '}
        <div>Chúc bạn một ngày đầy hứng khởi!</div>
        <div className="h-px bg-slate-400 mt-1" />
      </div>

      {/* <p id="phoneNo"></p>
      {isUserHaveOneOfPermissions([
        ROLES.ROLE_SALE_MAN,
        ROLES.ROLE_SALE_LEADER,
        ROLES.ROLE_SALE_MANAGER,
      ]) && (
        <div className="dataCaringView">
          <p className="bold pl-6">Danh sách data cần thực hiện trong ngày</p>
          <div className="ml-6 mr-6">
            {dataCaring?.loading ? (
              <div className="LoadingView">
                <LoadingOutlined className="loading" />
              </div>
            ) : (
              <Collapse
                defaultActiveKey={[`${appState?.currentCollapse}`]}
                collapsible="icon"
                accordion
                style={{ border: '0px' }}
                onChange={(index) => {
                  dispatch({
                    type: APP_CONTEXT_ACTIONS.SET_CURRENT_COLLAPSE,
                    payload: index,
                  });
                }}
              >
                <CollapsePanel
                  className="collapsePanel appointment"
                  key="1"
                  header={`Data có lịch hẹn gọi lại (${dataCaring?.data?.dataAppointments?.total} Data)`}
                  collapsible="header"
                >
                  <CustomTable
                    data={dataCaring?.data?.dataAppointments?.phonePacks}
                  />
                </CollapsePanel>
                <CollapsePanel
                  className="collapsePanel newData"
                  key="2"
                  header={`Data cấp mới (${dataCaring?.data?.dataNew?.total} Data)`}
                  collapsible="header"
                >
                  <CustomTable data={dataCaring?.data?.dataNew?.phonePacks} />
                </CollapsePanel>
                <CollapsePanel
                  className="collapsePanel careAgain"
                  key="3"
                  collapsible="header"
                  header={`Data cần chăm sóc lại (${dataCaring?.data?.dataNeedToCareAgain?.total} Data)`}
                >
                  <CustomTable
                    data={dataCaring?.data?.dataNeedToCareAgain?.phonePacks}
                  />
                </CollapsePanel>
              </Collapse>
            )}
          </div>
        </div>
      )} */}
    </div>
  );
}

export default DataCaring;
