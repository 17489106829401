/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DollarCircleOutlined,
  MoreOutlined,
  PhoneOutlined,
  SearchOutlined,
  SendOutlined,
} from '@ant-design/icons';
import {
  Button,
  notification,
  PageHeader,
  Popconfirm,
  Popover,
  Tooltip,
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TURN_UP_TKC } from 'src/constants';
import { CARE_SOFT_ACTION } from 'src/services/careSoft/useCareSoftHook';
import { ROLES, ROUTERS } from 'src/utils/constants';
import { isUserHaveOneOfPermissions } from 'src/utils/stringUtils';

import ModalWarning from 'src/components/ModalWarning';
import {
  BoxCallToCustomer,
  ModalAddNewTransaction,
  TopUpContent,
} from './components';

import careSoftServices from 'src/services/caresoft.service';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import LocalStoreInstance from 'src/utils/localStorage';
import RegisterPopup from './components/RegisterPopup';
import './style.scss';
import PingColor from './components/PingColor';
import AppContext from 'src/context/AppContext';
import { CustomerTransfer } from './components/CustomersTransfer';
import { RecordTopupOutside } from './components/RecordTopupOutside';

type Props = {
  profile?: DataDividedProfile;
  onReloadData: () => void;
  state: InfoState;
  isEnableBtnRegisterAndTopup: any;
  isDisableCRM: any;
  onOpenChangeRegister: any;
  onOpenChangeTopup: (newOpen: boolean) => void;
  handleHideTopUp: any;
  updateOtp: any;
  hiddenModal: any;
  onCreateRequest: (packagesCode: string, type: string) => any;
  onRegisterPackage: () => any;
  onSaveOtp: any;
  handleSearch: any;
  openModalRegisterPackage: any;
  hideModalRegisterPackage: any;
  openTopupTransaction: (newOpen: boolean) => void;
  openTopupRecordOutside: (newOpen: boolean) => void;
  handleFinishTopupTransfer: (options: any) => void;
  openModalConfirmTransaction: (values: any) => void;
  refConfirmTransaction: any;
  callBackUpdate?: () => void;
};

const Info: React.FC<Props> = ({
  profile,
  onReloadData,
  state,
  isEnableBtnRegisterAndTopup,
  isDisableCRM,
  onOpenChangeRegister,
  onOpenChangeTopup,
  handleHideTopUp,
  updateOtp,
  hiddenModal,
  onCreateRequest,
  onRegisterPackage,
  onSaveOtp,
  handleSearch,
  openModalRegisterPackage,
  hideModalRegisterPackage,
  openTopupTransaction,
  handleFinishTopupTransfer,
  openTopupRecordOutside,
  openModalConfirmTransaction,
  refConfirmTransaction,
  callBackUpdate,
}) => {
  const navigate = useNavigate();

  const [isOpenModalWarning, setOpenModalWarning] = useState(false);
  const [isPoorNetwork, setIsPoorNetwork] = useState<boolean>(true);

  const { careSoftState, careSoftDispatch } = useContext(AppContext);

  const phoneNumber =
    profile?.subscriberInfoWithoutSearching?.phoneNumber ??
    profile?.subscriberInfoWithSearching?.phoneNumber;

  const onGoback = () => {
    navigate(ROUTERS.DataDivided.path, { state: { isGoBack: true } });
  };

  const handleCheckingNetwork = (value: boolean) => {
    setIsPoorNetwork(value);
  };

  useEffect(() => {
    navigator?.mediaDevices
      ?.getUserMedia({ audio: true, video: false })
      .then(() => {})
      .catch((err) => {
        window.chrome?.tabs?.create({
          url: 'request-mic.html',
        });
      });
  }, []);

  const makeACall = (phone: string) => {
    careSoftServices.enableCall();

    setTimeout(() => {
      careSoftDispatch({ type: CARE_SOFT_ACTION.makeACall, phone });
    }, 500);
  };

  const handleOnGoback = () => {
    if (careSoftState?.callStatus === 'calling') {
      setOpenModalWarning(true);
    } else {
      onGoback();
    }
  };

  const closeModalWarning = () => {
    setOpenModalWarning(false);
  };

  const topUpContent = () => (
    <TopUpContent
      phone={phoneNumber}
      dataId={profile?.dataId}
      openTopUp={state.openTopUp}
      handleHideTopUp={handleHideTopUp}
      callBackUpdate={callBackUpdate}
    />
  );

  const onPressBtnCall = () => {
    const callStatus = LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.callStatus
    );
    if (callStatus === 'calling') {
      notification.warn({
        message:
          'Vui lòng kết thúc cuộc gọi hiện tại trước khi thực hiện cuộc gọi mới',
      });
    } else {
      makeACall(phoneNumber);
    }
  };
  const renderExtraHeader = () => [
    <Popconfirm
      key={'Popconfirm'}
      placement="topLeft"
      title={'Tốc độ mạng kém! Vẫn muốn thực hiện cuộc gọi đi?'}
      onConfirm={onPressBtnCall}
      okText="Xác nhận"
      cancelText="Huỷ"
      disabled={isPoorNetwork}
    >
      {!careSoftState?.initSuccess ? (
        <Popover
          content={
            <>
              Vui lòng bật <span className="bold">Microphone</span> hoặc thêm{' '}
              <span className="bold">IpPhone</span> để thực hiện cuộc gọi
            </>
          }
        >
          <Button
            key="btn-call"
            className="btn-calling"
            icon={<PhoneOutlined style={{ transform: 'rotate(90deg)' }} />}
            onClick={isPoorNetwork ? onPressBtnCall : () => {}}
            loading={careSoftState?.isLoading}
            disabled={
              careSoftState?.callStatus !== 'ending' ||
              careSoftState?.isLoading ||
              !careSoftState?.initSuccess ||
              profile?.assignedStatus === 'auto_recall'
            }
          >
            Gọi điện
            <PingColor handleCheckingNetwork={handleCheckingNetwork} />
          </Button>
        </Popover>
      ) : (
        <Button
          key="btn-call"
          className="btn-calling"
          icon={<PhoneOutlined style={{ transform: 'rotate(90deg)' }} />}
          onClick={isPoorNetwork ? onPressBtnCall : () => {}}
          loading={careSoftState?.isLoading}
          disabled={
            careSoftState?.callStatus !== 'ending' ||
            careSoftState?.isLoading ||
            !careSoftState?.initSuccess ||
            profile?.assignedStatus === 'auto_recall'
          }
        >
          Gọi điện
          <PingColor handleCheckingNetwork={handleCheckingNetwork} />
        </Button>
      )}
    </Popconfirm>,
    <Button
      key="search-tkc"
      className="btn-export"
      icon={<SearchOutlined />}
      disabled={true}
      onClick={() => handleSearch(TURN_UP_TKC)}
      loading={state.loadingTurnUpTKC}
    >
      Tra cứu TKC
    </Button>,

    <Popover
      key="register"
      open={state.openCreateRequest}
      placement="bottomRight"
      trigger={`${profile?.assignedStatus === 'auto_recall' ? '' : 'click'}`}
      content={
        <RegisterPopup
          profile={profile}
          state={state}
          onCreateRequest={onCreateRequest}
          updateOtp={updateOtp}
          onSaveOtp={onSaveOtp}
          hiddenModal={hiddenModal}
          onRegisterPackage={onRegisterPackage}
        />
      }
      onOpenChange={onOpenChangeRegister}
    >
      <Button
        htmlType="submit"
        className="btn-export"
        icon={<SendOutlined />}
        disabled={
          !isEnableBtnRegisterAndTopup ||
          profile?.assignedStatus === 'auto_recall'
        }
      >
        Đăng ký
      </Button>
    </Popover>,

    isUserHaveOneOfPermissions([
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_SALE_MANAGER,
      ROLES.ROLE_SALE_LEADER,
    ]) && (
      <Popover
        key="top-up-1"
        open={state.topupCustomerTransfer}
        placement="bottomRight"
        trigger={'click'}
        overlayClassName="top-up-popover"
        content={
          <CustomerTransfer
            phoneNumber={phoneNumber}
            refConfirmTransaction={refConfirmTransaction}
            openModalConfirmTransaction={openModalConfirmTransaction}
            handleFinishTransfer={handleFinishTopupTransfer}
          />
        }
        onOpenChange={openTopupTransaction}
      >
        <Button className="btn-export" icon={<DollarCircleOutlined />}>
          KH chuyển khoản
        </Button>
      </Popover>
    ),
    isUserHaveOneOfPermissions([
      ROLES.ROLE_ADMIN,
      ROLES.ROLE_SALE_MANAGER,
      ROLES.ROLE_SALE_LEADER,
    ]) && (
      <Popover
        key="transfer-customers"
        overlayClassName="top-up-popover"
        placement="bottomRight"
        trigger={'click'}
        open={state?.topupRecordTransferOutside}
        onOpenChange={openTopupRecordOutside}
        content={
          <RecordTopupOutside
            phoneNumber={phoneNumber}
            handleFinishTopupTransfer={handleFinishTopupTransfer}
            loadingTransfer={state?.loading}
          />
        }
      >
        <Button className="btn-export" icon={<DollarCircleOutlined />}>
          Ghi nhận nạp ngoài CRM
        </Button>
      </Popover>
    ),
    <Popover
      key="top-up-2"
      open={state.openTopUp}
      placement="bottomRight"
      trigger={`${profile?.assignedStatus === 'auto_recall' ? '' : 'click'}`}
      overlayClassName="top-up-popover"
      content={topUpContent}
      onOpenChange={onOpenChangeTopup}
    >
      <Button
        className="btn-export"
        disabled={
          !isEnableBtnRegisterAndTopup ||
          profile?.assignedStatus === 'auto_recall'
        }
        icon={<DollarCircleOutlined />}
      >
        Nạp thẻ
      </Button>
    </Popover>,
    isUserHaveOneOfPermissions([
      'ROLE_SALE_LEADER',
      'ROLE_SALE_MANAGER',
      'ROLE_ADMIN',
    ]) && (
      <Tooltip key="options" title="Đăng ký ngoài CRM" placement="bottomRight">
        <Button
          key="options"
          className="btn-export"
          disabled={isDisableCRM || profile?.assignedStatus === 'auto_recall'}
          icon={<MoreOutlined style={{ fontSize: 24 }} />}
          onClick={openModalRegisterPackage}
        />
      </Tooltip>
    ),
  ];

  return (
    <div id="data-divided-info" style={{ backgroundColor: 'red' }}>
      <PageHeader
        onBack={handleOnGoback}
        title={`SỐ THUÊ BAO ${phoneNumber} (${profile?.assignedStaff.toUpperCase()})`}
        extra={renderExtraHeader()}
      />
      <BoxCallToCustomer onReloadData={onReloadData} />
      {state.isOpenRegisterPackageOutSide && (
        <ModalAddNewTransaction
          dataId={profile?.dataId}
          assignedStaff={profile?.assignedStaff}
          handleCancel={hideModalRegisterPackage}
          onSubmitSuccess={onReloadData}
        />
      )}
      <ModalWarning
        isModalOpen={isOpenModalWarning}
        content={
          'Bạn đang trong cuộc gọi với khách hàng. Nếu rời khỏi trang web, cuộc gọi của bạn sẽ bị kết thúc.'
        }
        handleOk={onGoback}
        handleCancel={closeModalWarning}
      />
    </div>
  );
};

export default Info;
