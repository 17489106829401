import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import moment from 'moment';
import { FC } from 'react';
import FiltersButton from 'src/components/FiltersButton';
import {
  boxFilterStyle,
  contentBox,
  datePickerStyle,
  filterBoxStyle,
  txtTitle,
} from 'src/styles';
import { DATE_FORMAT, ROLES, TOP_UP_STATUS } from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { isUserHaveOneOfPermissions } from 'src/utils/stringUtils';

import './style.scss';

type Props = {
  style?: any;
  status: any;
  staffs: any;
  listStaff: any;
  extendSearch: boolean;
  isLoadingExport: boolean;
  isLoadingReset: boolean;
  onChangePhone: (e: any) => void;
  onKeyDown: (e: any) => void;
  onExtend: () => void;
  onSearch: () => void;
  onExport: () => void;
  onReset: () => void;
  onChangeState: (value: any) => void;
  onChangeCreater: (value: any) => void;
  onChangeDate: (values: any, formatString: [string, string]) => void;
};

const TopUpFilters: FC<Props> = ({
  style,
  status,
  listStaff,
  staffs,
  extendSearch,
  isLoadingExport,
  isLoadingReset,
  onChangePhone,
  onKeyDown,
  onExtend,
  onExport,
  onSearch,
  onReset,
  onChangeState,
  onChangeCreater,
  onChangeDate,
}) => {
  const windowSize = useWindowSize();

  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  if (isLoadingReset) return <div style={{ height: 150 }} />;

  return (
    <div style={style}>
      <div
        className={`dpl-flex align-items-center pd-8 pdl-15 pdr-15 ${
          extendSearch ? 'box-showdow-bottom' : ''
        }`}
      >
        <InputNumber
          size={size}
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder={'Nhập Số điện thoại...'}
          controls={false}
          style={{
            width: '20vw',
            borderRadius: 0,
            ...boxFilterStyle,
          }}
          onChange={onChangePhone}
          onKeyDown={onKeyDown}
        />
        {!extendSearch && (
          <>
            <Button
              onClick={onExtend}
              type="primary"
              size={size}
              style={{ marginLeft: 12 }}
            >
              Bộ lọc
            </Button>
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.5)',
                marginLeft: 10,
              }}
            >
              Lọc theo trường: Ngày tạo:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {moment().startOf('month').format('DD/MM/YYYY')} -{' '}
                {moment().subtract(0, 'days').endOf('day').format('DD/MM/YYYY')}
              </span>
            </span>
          </>
        )}

        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
          {isUserHaveOneOfPermissions([
            ROLES.ROLE_ADMIN,
            ROLES.ROLE_SALE_MANAGER,
            ROLES.ROLE_SALE_LEADER,
            ROLES.ROLE_ACCOUNTANT,
          ]) && (
            <Button
              className="btn-export"
              size={size}
              onClick={onExport}
              type="default"
              icon={<DownloadOutlined />}
              loading={isLoadingExport}
            >
              Export
            </Button>
          )}
        </div>
      </div>

        <div
          className={
            extendSearch ? 'topup-filters  box-showdow-bottom' : 'hide-box'
          }
        >
          <Row>
            <Col span={8}>
              <div style={contentBox}>
                <p style={txtTitle}>Trạng thái</p>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder={'Tất cả'}
                  value={status}
                  size={size}
                  style={filterBoxStyle}
                  onChange={onChangeState}
                  options={TOP_UP_STATUS}
                />
              </div>
            </Col>

            <Col span={8}>
              {isUserHaveOneOfPermissions([
                'ROLE_ADMIN',
                'ROLE_SALE_LEADER',
                'ROLE_SALE_MANAGER',
                'ROLE_ACCOUNTANT',
              ]) && (
                <div style={contentBox}>
                  <p style={{ ...txtTitle, width: '4vw' }}>Người tạo</p>
                  <TreeSelect
                    treeData={listStaff}
                    allowClear
                    showArrow
                    style={filterBoxStyle}
                    size={size}
                    maxTagCount="responsive"
                    placeholder={'Tất cả'}
                    onChange={onChangeCreater}
                    filterTreeNode={(string, node) => {
                      if (!node?.title) return false;
                      const strSearch = string.toLowerCase();
                      return node?.title?.toLowerCase()?.indexOf(strSearch) !==
                        -1
                        ? true
                        : false;
                    }}
                    treeCheckable={true}
                    value={staffs}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                  />
                </div>
              )}
            </Col>

            <Col span={8}>
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={{ ...txtTitle, width: '4vw' }}>Ngày tạo</p>
                <DatePicker.RangePicker
                  allowClear
                  size={size}
                  style={datePickerStyle}
                  placeholder={['Từ', 'Đến']}
                  defaultValue={[
                    moment().startOf('month').startOf('day'),
                    moment().endOf('day'),
                  ]}
                  onChange={onChangeDate}
                  format={DATE_FORMAT}
                />
              </div>
            </Col>
          </Row>
          <FiltersButton
            style={{ marginTop: 8 }}
            size={size}
            onSearch={onSearch}
            onExtend={onExtend}
            onReset={onReset}
          />
        </div>
    </div>
  );
};

export default TopUpFilters;
