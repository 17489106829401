import axios from 'axios';

import sign from 'jwt-encode';
import moment from 'moment';
import { scriptCustom } from './careSoft/custom';
import LocalStoreInstance from 'src/utils/localStorage';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import { compareDateTime } from 'src/utils/time';

const domain = process.env.REACT_APP_CARE_SOFT_DOMAIN ?? '';
const voiceApi = process.env.REACT_APP_SECRET_CARE_SOFT_VOICE_API ?? '';

class CareSoftServices {
  login(token: string): Promise<any> {
    return axios.post(`https://capi.caresoft.vn/${domain}/thirdParty/login`, {
      token: token,
    });
  }

  // ipphone: '79612',
  // chi thuy: 79592
  // my 79611
  getToken = (
    ip: string
  ): {
    token: string;
    ipphone: string;
    expired: string;
  } => {
    const payload = {
      ipphone: ip,
      expired: moment()
        .add(10, 'days')
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss'),
    };

    const token = sign(payload, voiceApi);
    console.log('CareSoft token', token);
    return {
      token,
      ...payload,
    };
  };

  async init(ipPhone: string) {
    await this.addScripts();
    let careSoftSession = LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.careSoftSession
    );
    const isTokenExpired = compareDateTime(new Date(), careSoftSession?.expired);
    // console.log('init careSoft', isTokenExpired, careSoftSession);
    if (
      careSoftSession?.token &&
      !isTokenExpired &&
      ipPhone === careSoftSession?.ipphone
    ) {
      window?.csInit(careSoftSession?.token, domain);
    } else {
      const infoToken = this.getToken(ipPhone);
      LocalStoreInstance.getInstance().save(
        LOCAL_STORAGE_VALUE.careSoftSession,
        infoToken
      );
      const loginResponse = await this.login(infoToken?.token);
      // console.log('loginResponse careSoft', loginResponse);
      if (loginResponse?.status === 200) {
        window?.csInit(infoToken?.token, domain);
      }
    }
  }

  addCaptureVideo = (
    id: string,
    muted: boolean = true,
    autoplay: boolean = true,
    playsInline: boolean = true
  ) => {
    const video = document.createElement('video');
    video.muted = muted;
    video.autoplay = autoplay;
    video.playsInline = playsInline;
    video.id = id;
    video.style.display = 'none';
    document.body.appendChild(video);
  };

  addScript = (text: string) => {
    const script = document.createElement('script');
    script.text = text;
    document.body.appendChild(script);
  };

  async addScripts() {
    const results = await Promise.all([
      axios.get('https://capi.caresoft.vn/js/embed/jquery.min.js'),
      axios.get('https://capi.caresoft.vn/js/embed/jssip-3.2.10.js'),
      axios.get('https://capi.caresoft.vn/js/embed/init-3.0.7.js'),
      axios.get('https://capi.caresoft.vn/js/embed/web.push.js'),
      axios.get('https://capi.caresoft.vn/js/embed/cs_const.js'),
      axios.get('https://capi.caresoft.vn/js/embed/cs_voice.js'),
    ]);

    for (const response of results) {
      this.addScript(response?.data);
    }
    this.addScript(scriptCustom);
    this.addCaptureVideo('my-video');
    this.addCaptureVideo('peer-video', false);
  }

  disconnect() {
    window?.csUnregister();
    if (window?.csVoice?.enableVoice) {
      window?.reConfigDeviceType();
    }
  }

  enableCall() {
    window?.csEnableCall();
  }

  makeACall(phoneNumber: string) {
    window?.csCallout('0' + phoneNumber);
  }

  endCall() {
    console.log('run endCall', window?.endCall);
    window?.endCall();
    this.addScript(`$(window).off('beforeunload')`);
  }

  holdCall() {
    console.log('run holdCall', window?.holdCall);
    window?.holdCall?.();
  }

  muteCall() {
    window?.muteCall?.();
  }

  onAcceptCall() {
    window?.onAcceptCall();
  }
}
const careSoftServices = new CareSoftServices();

export default careSoftServices;
