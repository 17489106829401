import { notification } from 'antd';
import { useContext, useState } from 'react';
import AppContext from 'src/context/AppContext';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';
import dataManagerServices from 'src/services/dataManager.service';
import { RESPONSE_CODE } from 'src/utils/constants';

type State = {
  data: any;
  loading: boolean;
  totalData?: TotalDataInterface;
};

const useDataCaring = () => {
  const [dataCaring, setDataCaring] = useState<State>({
    data: null,
    loading: false,
    totalData: undefined,
  });

  const { dispatch }: ContextType = useContext(AppContext);

  const getDataCaring = async () => {
    setDataCaring((prev) => ({
      ...prev,
      loading: true,
    }));
    const response = await dataManagerServices.getDataCaring();

    if (response?.status === RESPONSE_CODE.SUCCESS) {
      getTotalData();
      setDataCaring((prev) => ({
        ...prev,
        data: response?.data,
        loading: false,
      }));
    } else {
      setDataCaring((prev) => ({
        ...prev,
        loading: false,
      }));
      notification.error(response?.data ?? 'Có lỗi xảy ra!');
    }
  };

  const getTotalData = async () => {
    setDataCaring((prev) => ({
      ...prev,
      loading: true,
    }));
    const res = await dataManagerServices.getTotalDataCaring();
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setDataCaring((prev) => ({
        ...prev,
        loading: false,
        totalData: res?.data,
      }));
      dispatch({
        type: APP_CONTEXT_ACTIONS.SET_TOTAL_DATA_CARING,
        payload: res?.data,
      });
    } else {
      setDataCaring((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  return { dataCaring, getDataCaring, getTotalData };
};

export default useDataCaring;
