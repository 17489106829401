import moment, { Moment } from 'moment';
import { generatePath } from 'react-router-dom';
import LevelSaleCode from 'src/components/LevelSale/LevelSaleCode';
import { isSaleMan } from 'src/utils';
import { ROUTERS } from 'src/utils/constants';
import AsignData from '../AssignData/AssignData';

export const getColumnDataLx3 = (onSearch: () => void, listStaff: any) => {
  return [
    {
      title: 'Ngày phân bố data',
      dataIndex: 'allocatedDate',
      key: 'allocatedDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
      fixed: 'left',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
      width: '9%',
      render: (value: string) => {
        const path = generatePath(ROUTERS.DataDividedDetail.path, {
          phone: value,
        });

        return (
          <div
            style={{
              textDecoration: 'underline',
              color: 'blue',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(path);
            }}
          >
            {value}
          </div>
        );
      },
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'sourceSys',
      key: 'sourceSys',
      width: '8%',
    },
    {
      title: 'Gói đang dùng',
      dataIndex: 'packActive',
      key: 'packActive',
      width: '9%',
    },
    {
      title: 'Gói đang dùng(cập nhật)',
      dataIndex: 'currentRegisteredPackage',
      key: 'currentRegisteredPackage',
      width: '14%',
    },
    {
      title: 'Gói cước phù hợp',
      dataIndex: 'suggestedPackage',
      key: 'suggestedPackage',
      width: '10%',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expiredDate',
      key: 'expiredDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Trạng thái chăm sóc',
      dataIndex: 'assignedStatus',
      key: 'assignedStatus',
      width: '12%',
      render: (cell: string, record: DataLx3) => (
        <LevelSaleCode
          code={record.customerStage}
          color={record.customerStageDisplayColor}
          style={{ marginLeft: '1vw' }}
        />
      ),
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
      width: '18%',
      render: (cell: string) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'customerStageNote',
      key: 'customerStageNote',
      width: '18%',
      render: (cell: string) => (
        <div
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {cell}
        </div>
      ),
    },
    {
      title: 'Lần chăm sóc cuối',
      dataIndex: 'latestCaringDate',
      key: 'latestCaringDate',
      width: '13%',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Sale phụ trách cũ',
      dataIndex: 'oldAssignedStaff',
      key: 'oldAssignedStaff',
      width: '12%',
      hidden: isSaleMan,
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      fixed: 'right',
      render: (value: any, record: any) => {
        return (
          <AsignData
            dataId={record.id}
            onSuccess={onSearch}
            listStaff={listStaff}
            type="recall"
          />
        );
      },
    },
  ].filter((item) => !item.hidden);
};
