import React, { useEffect, useState } from 'react';
import { Button, Input, Select } from 'antd';
import { SearchOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { filterBoxStyle, inputStyle } from 'src/styles';
import { useWindowSize } from 'src/utils/customHooks';
import { ACCOUNT_STATUS, DEPARTMENT } from 'src/utils/constants';

type Props = {
  onChangeSearch: (text: string | null) => void;
  onPressEnter: (e: any) => void;
  onAddAccount: () => void;
  onSearch: () => void;
  onClear: () => void;
  onChangeStatus: (status: number) => void;
  onSelectManager: (account: string) => void;
  status?: number;
  managers: any;
  department?: string;
  onchangeDepartment: (department: string) => void;
};

const AccountFilters: React.FC<Props> = ({
  onChangeSearch,
  onPressEnter,
  onAddAccount,
  onChangeStatus,
  onSelectManager,
  onSearch,
  onClear,
  status,
  managers,
  onchangeDepartment,
  department,
}) => {
  const [clear, setClear] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  const onChange = (e: any) => {
    onChangeSearch(e.target.value);
  };

  const onClickClear = () => {
    onClear();
    setClear(true);
  };

  useEffect(() => {
    if (clear) {
      setClear(false);
    }
  }, [clear]);

  if (clear) return null;
  return (
    <div
      className={
        'dpl-flex justify-content-space-between pd-8 pdl-15 pdr-15 mgt-10'
      }
    >
      <div style={{ width: '100%' }}>
        <Input
          size={size}
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder={'Nhập Tên, tài khoản...'}
          style={{ ...inputStyle, width: '24%', marginLeft: 0 }}
          onChange={onChange}
          onKeyDown={onPressEnter}
        />
        <Select
          allowClear
          size={size}
          style={filterBoxStyle}
          options={ACCOUNT_STATUS}
          value={status}
          placeholder={'Trạng thái hoạt động'}
          onChange={onChangeStatus}
        />
        <Select
          allowClear
          size={size}
          placeholder="Người phụ trách"
          style={filterBoxStyle}
          onChange={onSelectManager}
        >
          {Array.isArray(managers) &&
            managers.map((x: any, y) => (
              <Select.Option key={y} value={x.username}>
                {x?.fullName} ({x?.username})
              </Select.Option>
            ))}
        </Select>
        <Select
          allowClear
          size={size}
          style={filterBoxStyle}
          options={DEPARTMENT}
          value={department}
          placeholder={'Phòng ban'}
          onChange={onchangeDepartment}
        />
        <Button
          onClick={onSearch}
          type="primary"
          icon={<SearchOutlined />}
          style={{ marginLeft: 16 }}
        />
        <Button
          onClick={onClickClear}
          icon={<CloseOutlined />}
          style={{ marginLeft: 8 }}
        />
      </div>
      <Button onClick={onAddAccount} type="primary" icon={<PlusOutlined />}>
        Thêm tài khoản
      </Button>
    </div>
  );
};

export { AccountFilters };
