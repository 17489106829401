import { getQueryString, getRequestUrl } from 'src/utils/stringUtils';
import { deleteParamsNotUsing } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';
import { TYPE_REGISTER_PHONE_PACKAGE } from 'src/utils/constants';

class RegisterPackageServices extends APIService {
  async getListData(query) {
    return await this.request(
      'GET',
      `register-package/search${query || ''}`,
      {}
    );
  }

  async getOTP(query) {
    return await this.request(
      'GET',
      `register-package/get-otp${query || ''}`,
      {}
    );
  }

  async confirmOTP(query) {
    return await this.request(
      'GET',
      `register-package/register-package${query || ''}`,
      {}
    );
  }

  async getListPackage(phonePackType?: string) {
    const params = {
      page: 0,
      size: 100,
      phonePackType,
    };
    deleteParamsNotUsing(params);
    const url = getQueryString('categories/phone-packages', params);
    const response = await this.request('GET', url);
    return response?.data;
  }

  async getListStaff(query?: any) {
    return await this.request(
      'GET',
      `categories/register-phone-pack-staffs${query || ''}`,
      {}
    );
  }

  async getListStaffTree(query?: any) {
    return await this.request(
      'GET',
      `categories/users-for-search${query || ''}`,
      {}
    );
  }

  async getRegisterPackages(params) {
    deleteParamsNotUsing(params);
    const url = getQueryString('register-package/v1_5/search', params);
    return await this.request('GET', url);
  }
  async exportRegisterPackages(params) {
    deleteParamsNotUsing(params);
    const url = getQueryString('register-package/v1_5/export', params);
    return await this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }
  async updateRegisterPackages(id, body) {
    deleteParamsNotUsing(body);
    return await this.request(
      'PUT',
      `register-package/v1_5/update-outside-request/${id}`,
      body
    );
  }

  async createRegisterPackages(query) {
    return await this.request(
      'POST',
      `register-package-request${query || ''}`,
      {}
    );
  }

  async registerPackages(query) {
    return await this.request(
      'POST',
      `register-package-request/register${query || ''}`,
      {}
    );
  }

  postCreateRequest(dataId: string, packageCode: string, type: string) {
    const params = {
      packageCode,
    };
    const url = getRequestUrl(
      'separated-data/detail',
      {
        parentId: dataId,
        partial:
          type === TYPE_REGISTER_PHONE_PACKAGE.GA
            ? 'create-request'
            : 'prm/create-request',
      },
      params
    );

    return this.request('POST', url);
  }

  async postRegisterPackages(
    dataId: string,
    packageCode: string,
    otp: string,
    type: string,
    orderCode: string | null
  ) {
    const params: any = {
      packageCode,
      otp,
    };
    if (type === TYPE_REGISTER_PHONE_PACKAGE.DAG) {
      params.orderCode = orderCode;
    }
    const url = getRequestUrl(
      'separated-data/detail',
      {
        parentId: dataId ?? 0,
        partial:
          type === TYPE_REGISTER_PHONE_PACKAGE.GA ? 'register' : 'prm/register',
      },
      params
    );

    return await this.request('POST', url);
  }

  async getCurrentRequest(query?: any) {
    return await this.request(
      'GET',
      `register-package-request/current-requests${query || ''}`,
      {}
    );
  }

  putUpdateOtp(dataId: number, otp: string) {
    return this.request(
      'PUT',
      `register-package-request/${dataId}/update-otp?otp=${otp}`
    );
  }

  async getListRegister(query) {
    return await this.request(
      'GET',
      `register-package-request/current-requests${query || ''}`,
      {}
    );
  }

  async updateOtp(id, otp) {
    return await this.request(
      'PUT',
      `register-package-request/${id}/update-otp?otp=${otp}`,
      {}
    );
  }

  async getListOutside(query) {
    return await this.request(
      'GET',
      `outside-register-package/search${query || ''}`,
      {}
    );
  }

  async createOutsideRequest(dataId, data) {
    return await this.request(
      'POST',
      `separated-data/detail/${dataId}/create-outside-request`,
      data
    );
  }

  async createOutside(data) {
    return await this.request('POST', `outside-register-package`, data);
  }

  async updateOutside(id, data) {
    return await this.request('PUT', `outside-register-package/${id}`, data);
  }

  async deleteOutside(id) {
    return await this.request('DELETE', `outside-register-package/${id}`, {});
  }

  async extendPackages(query) {
    return await this.request(
      'POST',
      `extend-1075/extend-package${query || ''}`,
      {}
    );
  }

  async extendPackagesList(query) {
    return await this.request(
      'GET',
      `extend-1075/current-requests${query || ''}`,
      {}
    );
  }

  async extendPackagesSearch(requestId) {
    return await this.request(
      'GET',
      `extend-1075/search-extend-request/${requestId}`,
      {}
    );
  }

  async saveLogRegister(callId: string, type: string) {
    return await this.request(
      'POST',
      `call-center/create-history-9332?callId=${callId}&type=${type}`,
      {}
    );
  }

  async registerPackage9332(
    id: number | string | undefined,
    packageCode: string,
    type: string
  ) {
    return await this.request(
      'POST',
      `separated-data/detail/${id}/register-9332?packageCode=${packageCode}&command=${type}`,
      {}
    );
  }

  async exportFile(query) {
    return await this.request(
      'GET',
      `register-package/export${query || ''}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async exportOutSide(query) {
    return await this.request(
      'GET',
      `outside-register-package/export${query || ''}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async turnUp(query: string) {
    return await this.request('GET', `separated-data/${query}/searchV2`);
  }

  async turnUpTKC(query: string) {
    return await this.request(
      'GET',
      `separated-data/detail/${query}/search-account-info`
    );
  }

  async topUp(params: any, query?: number) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`separated-data/detail/${query}/top-up`, params);
    return await this.request('POST', url);
  }

  async importFile(file: any) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await this.request(
      'POST',
      'outside-register-package/import',
      bodyFormData,
      {
        headers: {
          'Content-Disposition': 'attachment',
          'Content-Type': 'application/vnd.ms-excel',
        },
      }
    );
  }
}
const registerPackageServices = new RegisterPackageServices();
export default registerPackageServices;
