import React, { useEffect, useState } from 'react';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Table, DatePicker, Pagination, Divider } from 'antd';
import reportsServices from '../../services/reports-old.service';
import {
  isUserHaveOneOfPermissions,
  modifyArrTreeSelect,
  updateQueryStringParameter,
} from '../../utils/stringUtils';
import { useQuery, useWindowSize } from '../../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import {
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from '../../utils/constants';
import moment from 'moment';
import { saveAs } from 'file-saver';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { datePickerStyle, filterBoxStyle } from 'src/styles';
import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';

function RevenueReportOld() {
  const listBtn1 = [
    {
      id: 2,
      name: 'Tuần này',
      value: [
        moment().startOf('week').startOf('day'),
        moment().endOf('week').endOf('day'),
      ],
    },
    {
      id: 3,
      name: 'Tháng này',
      value: [moment().startOf('month').startOf('day'), moment().endOf('day')],
    },
  ];
  const listBtn2 = [
    {
      id: 2,
      name: 'Tuần trước',
      value: [
        moment().subtract(1, 'weeks').startOf('week').startOf('day'),
        moment().subtract(1, 'weeks').endOf('week').endOf('day'),
      ],
    },
    {
      id: 3,
      name: 'Tháng trước',
      value: [
        moment().subtract(1, 'months').startOf('month').startOf('day'),
        moment().subtract(1, 'months').endOf('month').endOf('day'),
      ],
    },
  ];
  const navigate = useNavigate();
  const query = useQuery();
  const windowSize = useWindowSize();

  const page = query.get('page') ? parseFloat(query.get('page') as string) : 1;
  const pageSize = query.get('size')
    ? parseFloat(query.get('size') as string)
    : DEFAULT_PAGE_SIZE;

  const staffsQuery = query.get('staffs');
  const createdDateFromQuery = query.get('createdDateFrom');
  const createdDateToQuery = query.get('createdDateTo');

  const [staffs, setStaffs] = useState(
    staffsQuery ? staffsQuery.split(',') : []
  );
  const [createdDateFrom, setCreatedDateFrom] = useState(
    createdDateFromQuery
      ? moment(parseFloat(createdDateFromQuery))
      : moment().startOf('month').startOf('day')
  );
  const [createdDateTo, setCreatedDateTo] = useState(
    createdDateToQuery
      ? moment(parseFloat(createdDateToQuery))
      : moment().endOf('day')
  );

  const [dataList, setDataList] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  const [total, setTotal] = useState<any>();
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  useEffect(() => {
    getListData();
    getTotal();
  }, [page, pageSize, staffsQuery, createdDateFromQuery, createdDateToQuery]);

  useEffect(() => {
    getListStaff();
  }, []);

  const getListData = async () => {
    setIsLoading(true);
    let query = buildQueryString();
    const resp = await reportsServices.getListDataDaily(query);
    const data = resp?.data;
    const headers = resp?.headers;
    if (resp?.status === 200) {
      setDataList(data);
      // console.log('data', data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    }
    setIsLoading(false);
  };

  const exportFile = async () => {
    setIsLoadingExport(true);
    let query = buildQueryString();
    const resp = await reportsServices.exportFileDaily(query);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `CRM_HH_theo_ngay_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
    setIsLoadingExport(false);
  };

  const getListStaff = async () => {
    const resp = await reportsServices.getListStaff();
    const data = resp?.data;
    if (resp?.status === 200) {
      let newArr = modifyArrTreeSelect(data) as any;
      setListStaff(newArr);
      // console.log('list staff', newArr);
    }
  };

  const getTotal = async () => {
    let query = buildQueryString();
    const resp = await reportsServices.getTotal(query);
    const data = resp?.data;
    if (resp?.status === 200) {
      setTotal(data);
    }
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  const buildQueryString = (data = {}, isUrl, isClear) => {
    let queryString = '';
    let dataValue = {
      staffs,
      createdDateTo,
      createdDateFrom,
      page,
      pageSize,
      ...data,
    };

    if (isClear) {
      dataValue = {
        page,
        pageSize,
        ...data,
      };
    }

    if (
      isUserHaveOneOfPermissions([
        'ROLE_ADMIN',
        'ROLE_SALE_LEADER',
        'ROLE_SALE_MANAGER',
        'ROLE_ACCOUNTANT',
      ])
    ) {
      if (Array.isArray(dataValue?.staffs) && dataValue?.staffs.length > 0) {
        let newStaffs = [];
        for (let i = 0; i < dataValue?.staffs.length; i++) {
          const curStaff = listStaff.find(
            (x) => x.value === dataValue?.staffs[i]
          );
          if (curStaff) {
            if (Array.isArray(curStaff?.children)) {
              curStaff?.children.map((x) => {
                newStaffs.push(x.value);
              });
            }
          } else {
            newStaffs.push(dataValue?.staffs[i]);
          }
        }
        queryString = updateQueryStringParameter(
          queryString,
          'staffs',
          newStaffs
        );
      }
    }
    if (dataValue.createdDateFrom) {
      queryString = updateQueryStringParameter(
        queryString,
        'createdDateFrom',
        dataValue.createdDateFrom.valueOf()
      );
    }
    if (dataValue.createdDateTo) {
      queryString = updateQueryStringParameter(
        queryString,
        'createdDateTo',
        dataValue.createdDateTo.valueOf()
      );
    }

    if (isUrl) {
      if (dataValue.page && dataValue.page > 1) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          dataValue.page
        );
      }

      if (dataValue.pageSize && dataValue.pageSize !== DEFAULT_PAGE_SIZE) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    } else {
      if (dataValue.page && dataValue.page > 0) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          parseFloat(dataValue.page) - 1
        );
      }

      if (dataValue.pageSize) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    }

    return queryString;
  };

  const onSearch = () => {
    let query = buildQueryString({ page: 1 }, true);
    const currPath = window.location.search;
    if (query !== currPath) {
      navigate(`/revenue-reports-daily-old${query || ''}`);
    } else {
      getListData();
    }
  };

  const onPageChange = (curPage, size) => {
    let query = buildQueryString({ page: curPage, pageSize: size }, true);
    navigate(`/revenue-reports-daily-old${query || ''}`);
  };

  const onChangeCreatedDate = (values: any) => {
    setCreatedDateFrom(values[0] ? values[0].startOf('day') : null);
    setCreatedDateTo(values[1] ? values[1].endOf('day') : null);
  };

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'day',
      key: 'day',
      render: (cell, record) => {
        if (record?.isTotal) {
          return <div className="bold">{cell}</div>;
        }
        return cell ? moment(cell).format('DD/MM/YYYY') : '---';
      },
    },
    {
      title: 'Số lượng đơn hàng',
      dataIndex: 'totalOrder',
      key: 'totalOrder',
      render: (cell, record) => {
        if (record?.isTotal) {
          return (
            <div className="bold">{cell ? cell.toLocaleString() : cell}</div>
          );
        }
        return cell ? cell.toLocaleString() : '0';
      },
    },
    {
      title: 'Doanh thu',
      dataIndex: 'totalRevenue',
      key: 'totalRevenue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {(cell || 0).toLocaleString()}
          </div>
        );
      },
    },
    // {
    //     title: 'Hoa hồng MBF',
    //     dataIndex: 'totalShareValueMBF',
    //     key: 'totalShareValueMBF',
    //     render: (cell, record) => {
    //         return <div className={record?.isTotal ? "bold" : ""}>
    //             {
    //                 (cell||0).toLocaleString()
    //             }
    //         </div>
    //     }
    // },
    {
      title: 'Hoa hồng thực nhận',
      dataIndex: 'totalNetShareValue',
      key: 'totalNetShareValue',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {(cell || 0).toLocaleString()}
          </div>
        );
      },
    },
    {
      title: 'Chiết khấu khách hàng',
      dataIndex: 'totalCustomerDiscount',
      key: 'totalCustomerDiscount',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {(cell || 0).toLocaleString()}
          </div>
        );
      },
    },
    {
      title: 'Hoa hồng sau chiết khấu',
      dataIndex: 'totalAfterDiscount',
      key: 'totalAfterDiscount',
      width: '15%',
      render: (cell, record) => {
        return (
          <div className={record?.isTotal ? 'bold' : ''}>
            {(cell || 0).toLocaleString()}
          </div>
        );
      },
    },
    {
      title: 'Tỷ lệ chiết khấu',
      dataIndex: 'discountPercentage',
      key: 'discountPercentage',
      render: (cell, record) => {
        if (record?.isTotal) {
          return (
            <div className="bold">
              {cell || cell === 0 ? `${cell.toFixed(2)}%` : '--'}
            </div>
          );
        }
        return cell || cell === 0 ? `${cell.toFixed(2)}%` : '--';
      },
    },
  ];

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;
  const renderBoxSearch = () => {
    return (
      <div className="box-search pdbt-10 pdt-10">
        {listBtn1.map((item, index) => {
          const isActive =
            createdDateFrom && createdDateTo
              ? createdDateFrom.valueOf() === item.value[0].valueOf() &&
                createdDateTo.valueOf() === item.value[1].valueOf()
              : false;
          return (
            <Button
              key={index}
              className={`mgr-5 ${isActive ? 'active' : 'default'}`}
              onClick={() => {
                setCreatedDateFrom(item.value[0]);
                setCreatedDateTo(item.value[1]);
                onSearch({
                  createdDateFrom: item.value[0],
                  createdDateTo: item.value[1],
                });
              }}
            >
              {item.name}
            </Button>
          );
        })}
        {listBtn2.map((item, index) => {
          const isActive =
            createdDateFrom && createdDateTo
              ? createdDateFrom.valueOf() === item.value[0].valueOf() &&
                createdDateTo.valueOf() === item.value[1].valueOf()
              : false;
          return (
            <Button
              key={index}
              className={`mgr-5 ${isActive ? 'active' : 'default'}`}
              onClick={() => {
                setCreatedDateFrom(item.value[0]);
                setCreatedDateTo(item.value[1]);
                onSearch({
                  createdDateFrom: item.value[0],
                  createdDateTo: item.value[1],
                });
              }}
            >
              {item.name}
            </Button>
          );
        })}
        <DatePicker.RangePicker
          allowClear
          style={{ ...datePickerStyle, width: '17vw', marginLeft: 0 }}
          placeholder={['Ngày tạo từ', 'Ngày tạo đến']}
          onChange={onChangeCreatedDate}
          format={DATE_FORMAT}
          value={[createdDateFrom, createdDateTo]}
        />
        {isUserHaveOneOfPermissions([
          'ROLE_ADMIN',
          'ROLE_SALE_LEADER',
          'ROLE_SALE_MANAGER',
          'ROLE_ACCOUNTANT',
        ]) && (
          <SelectSaleMan
            style={filterBoxStyle}
            treeData={listStaff}
            value={staffs}
            placeholder={'Người tạo'}
            onChange={(value) => setStaffs(value)}
          />
        )}
        <Button
          onClick={onSearch}
          type="primary"
          icon={<SearchOutlined />}
          loading={isLoading}
          style={{ marginLeft: 8 }}
        >
          Tìm kiếm
        </Button>

        <Button
          className="btn-export"
          style={{ marginLeft: 8 }}
          onClick={exportFile}
          type="default"
          icon={<DownloadOutlined />}
          loading={isLoadingExport}
        >
          Export
        </Button>
      </div>
    );
  };

  const newArrData =
    Array.isArray(dataList) && dataList.length > 0
      ? [
          {
            isTotal: true,
            day: 'Tổng',
            discountPercentage: total?.discountPercentageForAll,
            totalAfterDiscount: total?.totalAfterDiscountForAll,
            totalCustomerDiscount: total?.totalCustomerDiscountForAll,
            totalNetShareValue: total?.totalNetShareValueForAll,
            totalOrder: total?.totalOrderForAll,
            totalRevenue: total?.totalRevenueForAll,
            totalShareValueMBF: total?.totalShareValueMBFForAll,
          },
          ...dataList,
        ]
      : [];
  return (
    <div>
      <Helmet>
        <title>Hoa hồng theo ngày</title>
        <meta name="description" content={projectName} />
      </Helmet>
      {renderBoxSearch()}
      <Table
        rowKey={'id'}
        dataSource={newArrData}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 200,
        }}
        scroll={{ y: tableHeight }}
      />
      <div className="custom-pagination">
        <Pagination
          {...{
            size: 'small',
            showSizeChanger: true,
            current: page,
            total: metadata.total,
            defaultPageSize: pageSize,
            pageSizeOptions: PAGE_SIZE_LIST,
            onChange: onPageChange,
            showTotal: (total) => (
              <div>Tổng số bản ghi {total.toLocaleString()}</div>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default RevenueReportOld;
