import { Tag, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';

const getColumnsConfig = (
  handleDetails: (id: number) => void,
  page: number,
  pageSize: number
) => {
  const columns: ColumnType<any>[] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return (page - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Gói cước',
      dataIndex: 'code',
      key: 'code',
      width: '11%',
    },
    {
      title: 'Doanh thu',
      dataIndex: 'price',
      key: 'price',
      render: (cell) => {
        return <div>{(cell || 0).toLocaleString()} VNĐ</div>;
      },
      width: '11%',
    },
    {
      title: 'Hoa hồng',
      dataIndex: 'shaveValueMBF',
      key: 'shaveValueMBF',
      render: (cell) => {
        return <div>{(cell || 0).toLocaleString()} VNĐ</div>;
      },
      width: '11%',
    },
    {
      title: 'Chu kì',
      dataIndex: 'cycle',
      key: 'cycle',
      render: (cell) => {
        return <div>{cell || 0}</div>;
      },
      width: '7%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (cell) => {
        if (cell === 1) {
          return <div style={{ color: 'green' }}>Đang kinh doanh</div>;
        } else {
          return <div style={{ color: 'red' }}>Ngừng kinh doanh</div>;
        }
      },
      width: '11%',
    },
    {
      title: 'Thông tin ưu đãi',
      dataIndex: 'promotionInformation',
      key: 'promotionInformation',
      width: '35%',
      render: (cell) => {
        return <Typography className=" whitespace-pre-line">{cell}</Typography>;
      },
    },

    {
      title: 'Thao tác',
      dataIndex: 'actions',
      key: 'actions',
      render: (_: any, record: any) => {
        return (
          <Tag
            onClick={handleDetails?.bind(null, record?.id)}
            className="cursor-pointer"
          >
            Xem chi tiết
          </Tag>
        );
      },
      width: '11%',
    },
  ];
  return columns;
};

export default getColumnsConfig;
