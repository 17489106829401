import { message } from 'antd';
import React, { useRef } from 'react';
import { productService } from 'src/services/product.service';
import { RESPONSE_CODE } from 'src/utils/constants';

export const useUseProduct = () => {
  const [state, setState] = React.useState<{
    filter: ParamsListPackage;
    listPackage: ListPackage[];
    refresh: boolean;
    isEdit: boolean;
  }>({
    filter: {
      page: 0,
      size: 20,
      code: '',
    },
    refresh: false,
    listPackage: [],
    isEdit: false,
  });
  const createModalRef = useRef<any>(null);
  const detailsModalRef = useRef<any>(null);

  React.useEffect(() => {
    getListPackage();
  }, [
    state?.filter?.page,
    state?.filter?.size,
    state?.filter?.status,
    state?.filter?.code,
  ]);

  React.useEffect(() => {
    if (state?.refresh) {
      getListPackage();
    }
  }, [state?.refresh]);

  const getListPackage = async () => {
    const params = {
      page: state?.filter?.page,
      size: state?.filter?.size,
      status: state?.filter?.status,
      code: state?.filter?.code,
    };
    const res = await productService.listPackage(params);
    const { data, headers } = res;
    if (res.status === RESPONSE_CODE.SUCCESS) {
      setState((cur) => ({
        ...cur,
        listPackage: data,
        filter: {
          ...cur.filter,
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
        refresh: false,
      }));
    }
  };
  const handleDetails = async (id: number) => {
    const res = await productService.detailsPackage(id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      detailsModalRef?.current?.open(res?.data);
    } else {
      message?.error(res?.data);
    }
  };

  const openModalCreatePackage = () => {
    setState((prevState) => ({
      ...prevState,
      isEdit: false,
    }));
    createModalRef?.current?.open();
  };

  const onChangeStatuspackage = (value: number) => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        status: value,
        page: 0,
      },
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
    }));
  };

  const handleEdit = (data?: ListPackage | undefined) => {
    detailsModalRef?.current?.close();
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        isEdit: true,
      }));
      createModalRef?.current?.open(data);
    }, 100);
  };

  const createPackage = async (params: ListPackage) => {
    const res = await productService.createPackage(params);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        refresh: true,
      }));
      message?.success('Thêm thành công !');
      createModalRef?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };
  const updatePackage = async (params: ListPackage, id: number) => {
    const res = await productService.updatePackage(params, id);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((prevState) => ({
        ...prevState,
        refresh: true,
      }));
      message?.success('Cập nhật thành công !');
      createModalRef?.current?.close();
    } else {
      message?.error(res?.data?.message);
    }
  };

  const handleChangePackage = (e: { target: { value: string } }) => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        code: e?.target?.value,
        page: 0,
      },
    }));
  };

  return {
    state,
    openModalCreatePackage,
    onPageChange,
    handleDetails,
    createModalRef,
    detailsModalRef,
    handleEdit,
    createPackage,
    updatePackage,
    onChangeStatuspackage,
    handleChangePackage,
  };
};
