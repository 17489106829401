// import React, { useEffect, useState } from 'react';
// import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
// import { Button, Table, DatePicker, Pagination, message } from 'antd';
// import reportsServices from '../../services/reports.service';
// import {
//   isUserHaveOneOfPermissions,
//   modifyArrTreeSelect,
//   updateQueryStringParameter,
// } from '../../utils/stringUtils';
// import { useQuery, useWindowSize } from '../../utils/customHooks';
// import { useNavigate } from 'react-router-dom';
// import {
//   DEFAULT_PAGE_SIZE,
//   GLOBAL_HEIGHT,
//   PAGE_SIZE_LIST,
// } from '../../utils/constants';
// import moment from 'moment';
// import { saveAs } from 'file-saver';
// import SelectSaleMan from 'src/components/SelectSaleMan';
// import { datePickerStyle, filterBoxStyle } from 'src/styles';
// import { Helmet } from 'react-helmet';
// import { projectName } from 'src/utils';

import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';
import { Filter } from './Filter';
import { useRevenReportsDaily } from 'src/hooks/Revenue/RevenReportsDaily';
import getColumnsConfig from './TableColumns';
import { Table } from 'antd';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';

const RevenueReport = () => {
  const {
    state,
    onPageChange,
    onChangeSelectDate,
    handleLoadingExport,
    handleOnChangeStaffs,
    handleSelectBox,
  } = useRevenReportsDaily();
  const columns = getColumnsConfig();

  const windowSize = useWindowSize();

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;

  return (
    <div>
      <Helmet>
        <title>Hoa hồng theo ngày</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <Filter
        fromDate={state?.filters?.fromDate}
        toDate={state?.filters?.toDate}
        treeData={state?.listStaff}
        staffs={state?.staffs}
        loading={state?.loading}
        onChangeSelectDate={onChangeSelectDate}
        loadingExport={state?.loadingExport}
        handleLoadingExport={handleLoadingExport}
        onChangeStaffs={handleOnChangeStaffs}
        handleSelectBox={handleSelectBox}
      />
      <Table
        rowKey={'id'}
        className="ml-4 mr-4"
        dataSource={state?.listDaily}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: state?.filters?.page + 1,
          total: state?.metadata?.total,
          defaultPageSize: state?.filters?.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ x: 1000, y: tableHeight }}
      />
    </div>
  );
};
export default RevenueReport;
