import React, { useContext } from 'react';
import { Button, Tooltip } from 'antd';

import { ReactComponent as IconCalling } from 'src/assets/dataDivided/calling.svg';
import { ReactComponent as PhoneWaves } from 'src/assets/dataDivided/phoneWaves.svg';
import { ReactComponent as IconMute } from 'src/assets/dataDivided/mute.svg';

import { CARE_SOFT_ACTION } from 'src/services/careSoft/useCareSoftHook';
import CountUp from '../CountUp';

import careSoftServices from 'src/services/caresoft.service';
import './style.scss';
import AppContext from 'src/context/AppContext';

type Props = {
  onReloadData: () => void;
};

const BoxCallToCustomer: React.FC<Props> = ({ onReloadData }) => {
  const { careSoftState, careSoftDispatch } = useContext(AppContext);

  const onEndCall = () => {
    careSoftServices.endCall();
    onReloadData();
  };

  const onHold = () => {
    careSoftDispatch({ type: CARE_SOFT_ACTION.holdcall });
  };

  const onMute = () => {
    careSoftDispatch({ type: CARE_SOFT_ACTION.mute });
  };

  if (careSoftState?.callStatus === 'holding') {
    return (
      <div id={'box-call-to-customer'}>
        <p className={'txt-normal'}>Cuộc gọi đi: </p>
        <p className={'txt-normal blue bold-500 pdl-10'}>Đang kết nối</p>
        <Button className="btn-turn-off" onClick={onEndCall}>
          Kết thúc
        </Button>
      </div>
    );
  }
  if (careSoftState?.callStatus === 'calling') {
    return (
      <div id={'box-call-to-customer'}>
        <p className={'txt-normal'}>
          Cuộc gọi {careSoftState?.isCallingFromCustomer ? 'đến' : 'đi'}:{' '}
        </p>
        <CountUp />
        <PhoneWaves style={{ marginLeft: 12 }} />
        {careSoftState?.isCallingFromCustomer && (
          <Tooltip title={careSoftState?.mute ? 'Bật tiếng' : 'Tắt tiếng'}>
            <Button
              icon={
                <IconMute
                  fill={careSoftState?.mute ? '#277CBE' : '#000000'}
                  stroke={careSoftState?.mute ? '#277CBE' : '#000000'}
                />
              }
              onClick={onMute}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>
        )}

        <Tooltip
          title={careSoftState?.holdCall ? 'Tiếp tục cuộc gọi' : 'Giữ cuộc gọi'}
        >
          <Button
            icon={
              <IconCalling
                fill={careSoftState?.holdCall ? '#277CBE' : '#000000'}
                stroke={careSoftState?.holdCall ? '#277CBE' : '#000000'}
              />
            }
            onClick={onHold}
            style={{ marginLeft: 8 }}
          />
        </Tooltip>

        <Button className="btn-turn-off" onClick={onEndCall}>
          Kết thúc
        </Button>
      </div>
    );
  }
  return null;
};

export { BoxCallToCustomer };
