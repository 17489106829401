/* eslint-disable react/prop-types */
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  notification,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { useEffect, useState } from 'react';
import registerPackageServices from 'src/services/registerPackage.service';
import { REGISTER_PACKAGE_PAYMENT_CHANNEL } from 'src/utils/constants';

const dateFormat = 'DD/MM/YYYY'

const NewModalRegister = (props) => {
  const { state, setState, onSearch } = props;
  const [dataOnchangePopup, setDataOnchangePopup] = useState({
    phonePackage: state?.isSaleMan?.phonePackage,
    registeredDate: moment(moment(state?.isSaleMan?.registeredDate)).startOf('date').format('X') * 1000,
    paymentChannel: state?.isSaleMan.paymentChannel
  })

  const [isDisableButton, setIsDisableButton] = useState(false)

  const handleCancel = () => {
    setState((cur) => ({
      ...cur,
      showModal: false,
    }));
  };

  const assignedDate = (date) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur?.filter,
        registeredDate: moment(date).startOf('date').format('X') * 1000,
      },
    }));

    setDataOnchangePopup({
      ...dataOnchangePopup,
      registeredDate: moment(date).startOf('date').format('X') * 1000,
    })
    console.log('state?.filter:', state?.filter);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onPut = async (_data) => {
    let resp = {};
    const id = state.isSaleMan.id;
    resp = await registerPackageServices.updateRegisterPackages(
      id,
      state?.filter
    );
    if (resp?.status === 200) {
      notification.success({
        message: 'Chỉnh sửa giao dịch',
        description: 'Thành công',
      });
      setState((cur) => ({
        ...cur,
        showModal: false,
        filter: {
          ...cur.filter,
          paymentChannel: undefined
        }
      }));
      onSearch()
    } else {
      notification.error({
        message: resp?.data?.message,
      });
    }
    console.log('state?.filter:', state?.filter);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    if (dataOnchangePopup.paymentChannel !== state?.isSaleMan.paymentChannel || dataOnchangePopup.phonePackage !== state?.isSaleMan.phonePackage || dataOnchangePopup.registeredDate !== moment(moment(state?.isSaleMan?.registeredDate)).startOf('date').format('X') * 1000) {
      setIsDisableButton(false)
      return
    }

    setIsDisableButton(true)
  }, [dataOnchangePopup])


  return (
    <div>
      <Modal
        title="Chỉnh sửa giao dịch đăng ký gói cước"
        open={state?.showModal}
        onCancel={handleCancel}
        footer={[
          <Button key="1" onClick={handleCancel}>
            Hủy bỏ
          </Button>,
          <Button key="2" type="primary" onClick={onPut} disabled={isDisableButton}>
            Chỉnh sửa
          </Button>,
        ]}
        width={650}
      >
        <Form name="advanced_search" className="ant-advanced-search-form">
          <Row gutter={12} style={{ marginBottom: '2rem', display: 'flex', justifyContent: 'space-between' }}>
            <Col span={11}>
              <span>Gói cước *</span>
              <Select
                options={state?.listPackage.map((item) => {
                  return {
                    value: item?.code,
                  };
                })}
                onChange={(e) => {
                  setState((cur) => ({
                    ...cur,
                    filter: {
                      ...cur.filter,
                      phonePackage: e,
                    },
                  }));

                  setDataOnchangePopup({
                    ...dataOnchangePopup,
                    phonePackage: e,
                  })
                }}
                style={{ width: '100%' }}
                defaultValue={state?.isSaleMan.phonePackage}
              ></Select>
            </Col>
            <Col span={12}>
              <span>Sale man</span>
              <Input
                disabled
                value={state?.isSaleMan.staff}
                onChange={(e) => {
                  setState((cur) => ({
                    ...cur,
                    filter: {
                      ...cur.filter,
                      staffs: e,
                    },
                  }));
                }}
              />
            </Col>
          </Row>
          <Row gutter={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col span={11}>
              <span>Ngày đăng ký thành công *</span>
              <DatePicker
                style={{ width: '100%' }}
                onChange={(e) => {
                  assignedDate(e);
                }}
                defaultValue={
                  moment(state?.isSaleMan?.registeredDate)
                }
                disabledDate={disabledDate}
                format={dateFormat}
              ></DatePicker>
            </Col>
            <Col span={12}>
              <span>Kênh *</span>
              <Select
                style={{ width: '100%' }}
                options={REGISTER_PACKAGE_PAYMENT_CHANNEL}
                onChange={(e) => {
                  setState((cur) => ({
                    ...cur,
                    filter: {
                      ...cur.filter,
                      paymentChannel: e,
                    },
                  }));

                  setDataOnchangePopup({
                    ...dataOnchangePopup,
                    paymentChannel: e,
                  })
                }}
                defaultValue={state?.isSaleMan.paymentChannel}
              ></Select>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div >
  );
};

export default NewModalRegister;
