import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class DashboardServices extends APIService {
  async getGeneralData(params: { fromDate: string; staffs: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/call/overview`, params);
    return await this.request('GET', url);
  }

  async getCallConnect(params: { fromDate: string; staffs: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/call/call-connect`, params);
    return await this.request('GET', url);
  }
  async getAccumulation(params: { fromDate: string; staffs: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/call/accumulation`, params);
    return await this.request('GET', url);
  }

  async getLatestAction(staffs?: string) {
    const params = { staffs };
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/latest-action`, params);
    return await this.request('GET', url);
  }

  async getComissionGeneralData(params: { dateString: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/chart/revenue/all/total`, params);
    return await this.request('GET', url);
  }

  async getComissionGeneralDataByUser(params: {
    dateString: string;
    staff: string;
    isTeam: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/chart/revenue/team-sale/total`, params);
    return await this.request('GET', url);
  }

  async getTotalRevenueAllSale(params: { dateString: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/chart/revenue/all/by-hour`, params);
    return await this.request('GET', url);
  }

  async getTotalRevenueAllSaleByTeam(params: {
    dateString: string;
    staff: string;
    isTeam: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      `report/chart/revenue/team-sale/by-hour`,
      params
    );
    return await this.request('GET', url);
  }

  async getTeamSaleGeneralData(params: {
    dateString: string;
    staff?: string;
    isTeam?: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/chart/revenue/team-sale/total`, params);
    return await this.request('GET', url);
  }

  async getArpuAndArpe(params: {
    dateString: string;
    staff?: string;
    isTeam?: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      `report/chart/revenue/team-sale/by-hour-accumulated`,
      params
    );
    return await this.request('GET', url);
  }

  async getTeamSaleTotalRevenue(params: {
    dateString: string;
    staff?: string;
    isTeam?: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      `report/chart/revenue/team-sale/by-hour`,
      params
    );
    return await this.request('GET', url);
  }

  async get5SaleEarnMost(params: {
    dateString: string;
    top?: number;
    team?: string;
    isAll?: boolean;
  }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      `report/chart/revenue/top-net-share-value`,
      params
    );
    return await this.request('GET', url);
  }

  async getHotCloseContact(params: { fromDate: string; staffs: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/contact/hot-close`, params);
    return await this.request('GET', url);
  }

  async getHandledContact(params: { fromDate: string; staffs: string }) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`report/contact/cutoff-by-hour`, params);
    return await this.request('GET', url);
  }
}
const dasboardServiecs = new DashboardServices();
export default dasboardServiecs;
