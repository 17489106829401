import React, { useEffect, useRef, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { notification, Table, Modal, Tag, Spin } from 'antd';
import { Helmet } from 'react-helmet';

import { useWindowSize } from '../../utils/customHooks';
import ModalCreate from './ModalCreate';
import { AccountFilters } from './AccountFilters';
import userServices from 'src/services/users.service';
import {
  DEFAULT_PAGE_SIZE,
  DEPARTMENT,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
  ROLES_LIST,
} from 'src/utils/constants';
import { isHavePermission } from 'src/utils/stringUtils';
import { projectName } from 'src/utils';

const defaultFilters: AccountFilterPrams = {
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  active: 1,
  manager: undefined,
  nameSearchKey: '',
};

function Accounts(): JSX.Element {
  const windowSize = useWindowSize();

  const [filters, setFilters] = useState<AccountFilterPrams>(defaultFilters);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [dataDetail, setDataDetail] = useState<any>();
  const [managers, setManagers] = useState([]);
  const [metadata, setMetadata] = useState<MetadataInterface | undefined>(
    undefined
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSaleManager = isHavePermission('ROLE_SALE_MANAGER');

  const tempSearch = useRef<string>('');

  const getListAccounts = async () => {
    setLoading(true);
    const response = await userServices.getListAccounts(filters);
    const { data, headers } = response;

    if (response?.status === 200) {
      setAccounts(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    }

    setLoading(false);
  };

  const resetPassword = async (id: number) => {
    const resp = await userServices.resetPasswordAccount(id);
    if (resp?.status === 200) {
      notification.success({
        message: 'Reset mật khẩu',
        description: 'Thành công',
      });
      getListAccounts();
    }
  };

  const onResetPassword = (data: any) => {
    Modal.confirm({
      title: `Bạn có muốn reset lại mật khẩu của ${data?.username} không?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Đồng ý',
      onOk: () => resetPassword(data?.id),
      cancelText: 'Hủy bỏ',
    });
  };

  const onPageChange = (curPage: number, size: number) => {
    setFilters((prev: any) => ({
      ...prev,
      page: curPage - 1,
      size,
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    getListAccounts();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onPressEnter = (
    e: React.KeyboardEvent<HTMLImageElement> | undefined
  ) => {
    if (e?.keyCode === 13) {
      onSearch();
    }
  };

  const onSearch = () => {
    setFilters((prev: any) => ({
      ...prev,
      nameSearchKey: tempSearch.current,
    }));
  };

  const onClear = () => {
    setFilters(defaultFilters);
  };

  const onChangeSearch = (search: string | null) => {
    tempSearch.current = search ?? '';
  };

  const onSelectManager = (account: any) => {
    setFilters((prev: any) => ({
      ...prev,
      manager: account,
    }));
  };
  const onChangeDepartment = (department: string) => {
    setFilters((prev: any) => ({
      ...prev,
      department,
    }));
  };

  const onChangeStatus = (status: number) => {
    console.log('onChangeStatus ', status);
    setFilters((prev: any) => ({
      ...prev,
      active: status,
    }));
  };

  const onAddAccount = () => {
    setDataDetail({
      roles: ['ROLE_SALE_MAN'],
      password: 'abc12345@',
      active: 1,
      department: DEPARTMENT[0].value,
    });
    showModal();
  };

  useEffect(() => {
    getListAccounts();
    
  }, [filters]);

  const getListManager = async () => {
    const resp = await userServices.getListManager();
    if (resp?.status === 200) {
      setManagers(resp?.data);
    }
  };

  useEffect(() => {
    getListManager();
  }, []);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell: any, record: any, index: number) =>
        filters.page * filters.size + index + 1,
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Phân quyền',
      dataIndex: 'roles',
      key: 'roles',
      render: (cell: any[]) => (
        <div>
          {Array.isArray(cell) &&
            cell.map((x, z) => {
              const role = ROLES_LIST.find((y) => y.id === x);
              return <div key={z}>{role?.name}</div>;
            })}
        </div>
      ),
    },
    {
      title: 'Phòng ban',
      dataIndex: 'department',
      key: 'department',
      width: '8%',
    },
    {
      title: 'Người phụ trách',
      dataIndex: 'manager',
      key: 'manager',
      render: (cell: any) => cell || 'Chưa có người phụ trách',
    },
    {
      title: 'IP Phone',
      dataIndex: 'ipPhone',
      key: 'ipPhone',
      width: '8%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'active',
      key: 'active',
      render: (cell: any) => (
        <Tag color={cell === 1 ? 'green' : 'volcano'}>
          {cell === 1 ? 'Đang hoạt động' : 'Ngừng hoạt động'}
        </Tag>
      ),
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      className: 'text-right',
      render: (cell: any, record: any) => {
        const disabled =
          isSaleManager &&
          (record.roles.includes('ROLE_ADMIN') ||
            record.roles.includes('ROLE_SALE_ADMIN') ||
            record.roles.includes('ROLE_ACCOUNTANT') ||
            record.roles.includes('ROLE_SALE_MANAGER'));
        return (
          <div className="dpl-flex justify-content-start">
            <div
              className={`cursor-pointer ${disabled ? `txt-gray` : `txt-blue`}`}
              onClick={() => {
                if (!disabled) onResetPassword(record);
              }}
            >
              Reset password
            </div>
            <div className="pdr-10 pdl-10">|</div>
            <div
              className={`cursor-pointer ${disabled ? `txt-gray` : `txt-blue`}`}
              onClick={() => {
                if (!disabled) {
                  showModal();
                  setDataDetail(record);
                }
              }}
            >
              Chỉnh sửa
            </div>
          </div>
        );
      },
    },
  ];

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT;

  return (
    <Spin spinning={loading}>
      <Helmet>
        <title>Quản lý tài khoản</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <AccountFilters
        onChangeSearch={onChangeSearch}
        onPressEnter={onPressEnter}
        onAddAccount={onAddAccount}
        onChangeStatus={onChangeStatus}
        onSelectManager={onSelectManager}
        onSearch={onSearch}
        onClear={onClear}
        status={filters.active}
        managers={managers}
        department={filters.department}
        onchangeDepartment={onChangeDepartment}
      />

      <Table
        rowKey={'id'}
        dataSource={accounts}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          defaultCurrent: (filters.page ?? 0) + 1,
          total: metadata?.total,
          defaultPageSize: filters.size,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />

      {isModalOpen && (
        <ModalCreate
          managers={managers}
          dataDetail={dataDetail}
          modalStatus={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </Spin>
  );
}

export default Accounts;
