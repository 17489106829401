import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';
import { AxiosResponse } from 'axios';

class DataManagerServices extends APIService {
  async getListData(params: SearchListDataParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('data-import/phone-packs/search', params);
    return await this.request('GET', url);
  }

  async exportFile(params: SearchListDataParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('data-import/phone-packs/export', params);
    return await this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async getListManager(query: any) {
    return await this.request('GET', `account/managers${query || ''}`, {});
  }

  async getListImport(query: any) {
    return await this.request(
      'GET',
      `file-import-batches/search${query || ''}`,
      {}
    );
  }

  async getSeparatedData(query?: any) {
    return await this.request(
      'GET',
      `separated-data/list-sale${query || ''}`,
      {}
    );
  }

  async getLevelSaleParents() {
    return await this.request('GET', 'level-sale');
  }

  async getListReason() {
    return await this.request('GET', 'separated-data/v1_6/search/list-reason');
  }

  async getListError(batchId: number) {
    return await this.request(
      'GET',
      `file/batches/${batchId}/export-phone-error-package`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async getImportResult(batchId: number) {
    return await this.request(
      'GET',
      `file/import-phone-package/result?batchId=${batchId}`,
      {}
    );
  }

  async importData(file: any) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    return await this.request(
      'POST',
      'file/import-phone-package',
      bodyFormData,
      {
        headers: {
          'Content-Disposition': 'attachment',
          'Content-Type': 'application/vnd.ms-excel',
        },
      }
    );
  }

  async getListDataManual(query) {
    return await this.request(
      'GET',
      `data-import/manual/search${query || ''}`,
      {}
    );
  }

  async createDataManual(query) {
    return await this.request('POST', `data-import/manual${query || ''}`, {});
  }

  async phoneCheck(params: any) {
    const url = getQueryString('data-import/phone-packs/check', params);
    return await this.request('GET', url);
  }

  async separatedDataSaleLeader(body: any, filters: SearchListDataParams) {
    deleteParamsNotUsing(filters);
    const url = getQueryString('separated-data/sale-leader', filters);
    return await this.request('POST', url, body);
  }

  async separatedDataSaleMan(body: any, filters: SearchListDataParams) {
    deleteParamsNotUsing(filters);
    const url = getQueryString('separated-data/sale-man', filters);
    return await this.request('POST', url, body);
  }

  async postAssignData(dataId: any, username: any) {
    return await this.request(
      'POST',
      `separated-data/assign-data/${dataId}?sale=${username}`
    );
  }

  async postAssignDataRecall(dataId: any, username: any) {
    return await this.request(
      'POST',
      `separated-data/v1_6/assign-data-for-recall/${dataId}?sale=${username}`
    );
  }

  async getFilterStateTakeCareOf(): Promise<any> {
    return await this.request('GET', 'separated-data/search/list-sale-level');
  }

  async getListDataDivided(
    params: PagingInterface,
    body: SearchDataDividedBody
  ) {
    deleteParamsNotUsing(body);
    deleteParamsNotUsing(params);
    const url = getQueryString('separated-data/v1_6/search', params);
    return await this.request('POST', url, body);
  }

  async exportFileDivided(
    params: PaginationParams,
    body: SearchDataDividedBody
  ) {
    deleteParamsNotUsing(body);
    deleteParamsNotUsing(params);
    const url = getQueryString('separated-data/v1_6/export', params);
    return await this.request('POST', url, body, {
      responseType: 'blob',
    });
  }

  async getReasonsLevelSale(id: string) {
    return await this.request(
      'GET',
      `separated-data/detail/level-sale-reasons?levelSaleId=${id}`
    );
  }

  async updateDetailLevelSale(id: number, body: UpdateLevelSaleDividedBody) {
    deleteParamsNotUsing(body);
    return await this.request(
      'PUT',
      `separated-data/detail/${id}/update-customer-stage`,
      body
    );
  }

  async getCustomerStages(id: string) {
    return await this.request(
      'GET',
      `separated-data/detail/${id}/get-customer-stages`
    );
  }

  async getDataDividedDetail(phoneNumber: string) {
    return await this.request(
      'GET',
      `separated-data/detail?msisdn=${phoneNumber}`
    );
  }

  getDataB9(dataId: string): Promise<any> {
    return this.request('GET', `separated-data/detail/${dataId}/search-b9`);
  }

  async getListDataAutoSearch(params: SearchListDataParams) {
    deleteParamsNotUsing(params);
    const url = getQueryString('data-import/phone-packs/auto-search', params);
    return await this.request('GET', url, undefined, { timeout: 1000 * 60 });
  }

  async getConfigPhonePackagesAutoSearch() {
    const url = getQueryString(
      'categories/configuration-phone-packages_auto_search'
    );
    return await this.request('GET', url);
  }

  async checkAutoSearchRunning() {
    const url = getQueryString(
      'data-import/phone-packs/check-auto-search-running'
    );
    return await this.request('GET', url);
  }

  async searchB9(query?: string) {
    const url = getQueryString(`separated-data/detail/${query}/search-b9`);
    return await this.request('GET', url);
  }

  getAllPhoneNumber(body: SearchDataDividedBody) {
    deleteParamsNotUsing(body);
    return this.request('POST', 'separated-data/get-action-data-all', body);
  }

  // evict data
  filterRecallData(msisdnList: string[]) {
    return this.request('POST', 'separated-data/recall-data/get-total-data', {
      msisdnList,
    });
  }

  evictData(msisdnList: string[]) {
    return this.request('POST', 'separated-data/recall-data', {
      msisdnList,
    });
  }

  // move data
  filterMoveData(msisdnList: string[]) {
    return this.request('POST', 'separated-data/move-data/get-total-data', {
      msisdnList,
    });
  }

  moveData(destinationStaff: string, msisdnList: string[] = []) {
    const url = getQueryString('separated-data/move-data', {
      destinationStaff,
    });
    return this.request('POST', url, {
      msisdnList,
    });
  }

  // data lx3
  getTakeCareData() {
    return this.request('GET', 'separated-data/search-lx3/list-sale-level');
  }

  getDataLx3(params: DataLx3Params, body: DataLx3Body) {
    deleteParamsNotUsing(params);
    deleteParamsNotUsing(body);
    const url = getQueryString(
      'separated-data/v1_6/search-auto-recall',
      params
    );
    return this.request('POST', url, body);
  }

  exportDataLx3(params: DataLx3Params, body: DataLx3Body) {
    deleteParamsNotUsing(params);
    deleteParamsNotUsing(body);
    const url = getQueryString(
      'separated-data/v1_6/export-auto-recall',
      params
    );
    return this.request('POST', url, body, {
      responseType: 'blob',
    });
  }

  async getDataCaring() {
    const url = getQueryString('customer-care');
    return await this.request('GET', url);
  }

  getTotalDataCaring(): Promise<AxiosResponse<TotalDataInterface>> {
    const url = getQueryString('customer-care/remain');
    return this.request('GET', url);
  }
}
const dataManagerServices = new DataManagerServices();
export default dataManagerServices;
