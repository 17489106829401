import { isNullOrEmpty } from './stringUtils';

export const validatediscountPercentTopupOutSide = (
  rule: any,
  value: any,
  callback: any
) => {
  const numericValue = parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
  if (numericValue === 0) {
    callback();
  } else if (isNaN(numericValue)) {
    callback('Vui lòng nhập tỉ lệ chiết khấu');
  } else {
    callback();
  }
};

export const validateamountTopup = (rule: any, value: any, callback: any) => {
  const numericValue = parseFloat(value.replace(/,/g, '').replace(/\./g, ''));
  if (numericValue === 0) {
    callback();
  } else if (isNullOrEmpty(numericValue)) {
    callback('Vui lòng nhập số tiền nạp');
  } else {
    callback();
  }
};
