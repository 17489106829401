/* eslint-disable react/prop-types */
import { Button, Modal } from 'antd';

import './styles.scss';

type Props = {
  title?: string;
  content: string;
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const ModalWarning: React.FC<Props> = (props) => {
  const {
    title = 'Thông báo',
    content,
    isModalOpen,
    handleOk,
    handleCancel,
  } = props;
  return (
    <Modal
      title={<p className={'text title'}>{title}</p>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className="modal-style"
      footer={[
        <Button key="1" onClick={handleCancel}>
          Hủy bỏ
        </Button>,
        <Button key="2" type="primary" onClick={handleOk}>
          Rời khỏi
        </Button>,
      ]}
    >
      <p className={'text text-center'}>{content}</p>
    </Modal>
  );
};

export default ModalWarning;
