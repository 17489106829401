import { Table, Tag, message } from 'antd';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import registerPackageServices from 'src/services/registerPackage.service';
import {
  PAGE_SIZE_LIST,
  DEFAULT_PAGE_SIZE,
  ROLES,
  RESPONSE_CODE,
} from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { isHavePermission, modifyArrTreeSelect } from 'src/utils/stringUtils';
import { convertTimeStamp } from 'src/utils/time';
import NewModalRegister from './NewModalRegister';
import { saveAs } from 'file-saver';
import RegisterPackageFilters from './RegisterPackageFilters';
import { formatMonetary } from 'src/utils/formatter';
import { Helmet } from 'react-helmet';
import { projectId, projectName } from 'src/utils';

type RegisterPackageFilterParams = {
  status?: string;
  staffs?: any;
  paymentChannel?: string;
  sourceSys?: string;
  registeredDateFrom?: number;
  registeredDateTo?: number;
};

type State = {
  filter: RegisterPackageFilterParams;
  isLoading: boolean;
  showModal: boolean;
  extendSearch: boolean;
  listStaff: any[];
  listPackage: any[];
  listRegisterPackages: any[];
  exportLoading: boolean;
  isLoadingReset: boolean;
  isSaleMan: object;
  metadata?: MetadataInterface;
};

const RegisterPackages = () => {
  const windowSize = useWindowSize();

  const defaultFilterParams = {
    status: undefined,
    staffs: undefined,
    paymentChannel: undefined,
    sourceSys: undefined,
    registeredDateFrom: moment().startOf('month').format('X') * 1000,
    registeredDateTo:
      moment().subtract(0, 'days').endOf('day').format('X') * 1000,
  };
  const [state, setState] = useState<State>({
    filter: defaultFilterParams,
    listStaff: [],
    listPackage: [],
    listRegisterPackages: [],
    isLoading: true,
    isLoadingReset: false,
    exportLoading: false,
    showModal: false,
    extendSearch: false,
    isSaleMan: {},
    metadata: undefined,
  });

  const searchListRegisterPackage = async (head?: any) => {
    const res = await registerPackageServices.getRegisterPackages(head);
    const data = res?.data;
    const headers = res?.headers;
    if (res?.status === 200) {
      setState((cur) => ({
        ...cur,
        listRegisterPackages: data,
        isLoading: false,
        isLoadingReset: false,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    }
  };

  const getListStaff = async () => {
    const resp = await registerPackageServices.getListStaffTree();
    const data = resp?.data;
    if (resp?.status === 200) {
      const newArr = modifyArrTreeSelect(data);
      setState((cur) => ({
        ...cur,
        listStaff: newArr,
      }));
    }
  };

  const getListPackage = async () => {
    const res = await registerPackageServices.getListPackage();
    const data = res?.data;
    if (res?.status === 200) {
      setState((cur) => ({
        ...cur,
        listPackage: data,
      }));
    }
  };

  useEffect(() => {
    getListStaff();
    getListPackage();
  }, []);

  const onSearch = () => {
    searchListRegisterPackage(state?.filter);
  };

  const onReset = () => {
    setState((cur) => ({
      ...cur,
      filters: {},
      filter: {},
      isLoading: true,
      isLoadingReset: true,
    }));
  };

  const onExtend = () => {
    setState((cur) => ({
      ...cur,
      extendSearch: !cur.extendSearch,
    }));
  };

  const onChangeAssignedDate = (dates: any) => {
    const startDate = dates?.[0].startOf('day').format();
    const endDate = dates?.[1]?.endOf('day').format();
    const registeredDateFrom = convertTimeStamp(moment(startDate));
    const registeredDateTo = convertTimeStamp(moment(endDate));
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur?.filter,
        registeredDateFrom: startDate ? registeredDateFrom : startDate,
        registeredDateTo: endDate ? registeredDateTo : endDate,
      },
    }));
  };

  const onExport = async () => {
    const resp = await registerPackageServices.exportRegisterPackages(
      state?.filter
    );
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Lich_su_dang_ky_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
    setState((cur) => ({
      ...cur,
      exportLoading: false,
    }));
  };

  const onImport = async (options: any) => {
    const res = await registerPackageServices.importFile(options.file);
    if (res?.status === RESPONSE_CODE.SUCCESS) {
      setState((cur) => ({
        ...cur,
        isLoading: true,
      }));
      message.success('Import thành công !');
    } else {
      message.error(res?.data);
    }
  };

  const onChangePhone = (phone: string | null) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        msisdn: phone,
      },
    }));
  };

  const onChangeStatus = (e: any) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        status: e,
      },
    }));
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      onSearch();
    }
  };

  const onSelectSaleMan = (e: any) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        staffs: e,
      },
    }));
  };

  const onChangePackage = (e: any) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        phonePackage: e,
      },
    }));
  };

  const onChangeChanel = (e: any) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        paymentChannel: e,
      },
    }));
  };

  const onChangeSystem = (e: any) => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        sourceSys: e,
      },
    }));
  };

  const getTagColor = (cell: string) => {
    switch (cell) {
      case 'Thành công':
        return 'green';
      case 'Thất bại':
        return 'red';
    }
  };

  const isSaleMan = isHavePermission(ROLES.ROLE_SALE_MAN);
  const isRoleAccountant = isHavePermission(ROLES.ROLE_ACCOUNTANT);

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell: any, record: any, index: number) => index + 1,
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'registeredDate',
      key: 'registeredDate',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Saleman',
      dataIndex: 'saleMan',
      key: 'saleMan',
      width: '7%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '7%',
    },
    {
      title: 'Gói cước',
      dataIndex: 'phonePackage',
      key: 'phonePackage',
      width: '7%',
    },
    {
      title: 'Giá gói cước',
      dataIndex: 'phonePackagePrice',
      key: 'phonePackagePrice',
      width: '8%',
      render: (cell: number) => formatMonetary.format(cell),
    },
    {
      title: 'Kênh',
      dataIndex: 'paymentChannel',
      key: 'paymentChannel',
      width: '7%',
    },
    {
      title: 'Hệ thống',
      dataIndex: 'sourceSystem',
      key: 'sourceSystem',
      width: '8%',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '9%',
      render: (cell: string) => (
        <Tag color={getTagColor(cell)} style={{ fontSize: '0.75vw' }}>
          {cell}
        </Tag>
      ),
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '9%',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (cell: Moment) =>
        cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---',
    },
    {
      title: 'Thao tác',
      width: '9%',
      hidden: isSaleMan || isRoleAccountant,
      render: (cell: any, record: any) => {
        return (
          <>
            <a
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick={(e) => {
                if (record.sourceSystem !== 'CRM') {
                  setState((cur) => ({
                    ...cur,
                    showModal: true,
                    isSaleMan: {
                      id: cell.id,
                      phoneNumber: cell.phoneNumber,
                      staff: cell.saleMan,
                      phonePackage: cell.phonePackage,
                      registeredDate: cell.registeredDate,
                      paymentChannel: cell.paymentChannel,
                    },
                  }));
                }
                console.log('createdDate:', cell.createdDate);
              }}
              style={{ color: record.sourceSystem === 'CRM' && 'slategrey' }}
            >
              Chỉnh sửa
            </a>
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  const onPageChange = (page: number, size: number) => {
    const newPage = page - 1 > 0 ? page - 1 : 0;
    const newSize = size;
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        page: newPage,
        size: newSize,
      },
      isLoading: true,
    }));
  };

  useEffect(() => {
    if (state.isLoading) {
      searchListRegisterPackage(state?.filter);
    }
  }, [state.isLoading]);

  let tableHeight = state.extendSearch
    ? windowSize?.height * 0.65
    : windowSize?.height * 0.8;

  useEffect(() => {
    setState((cur) => ({
      ...cur,
      filter: {
        ...cur.filter,
        page: 0,
        size: 50,
      },
      isLoading: true,
    }));
  }, []);

  return (
    <div>
      <Helmet>
        <title>Lịch sử đăng ký</title>
        <meta name="description" content={projectName} />
      </Helmet>
      {state?.showModal && (
        <NewModalRegister
          state={state}
          setState={setState}
          onReset={onReset}
          onSearch={onSearch}
        />
      )}
      <RegisterPackageFilters
        defaultValue={state.filter}
        extendSearch={state?.extendSearch}
        listStaff={state?.listStaff}
        listPackage={state?.listPackage}
        isLoadingExport={false}
        isLoadingReset={state?.isLoadingReset}
        onChangeChanel={onChangeChanel}
        onSelectSaleMan={onSelectSaleMan}
        onChangePackage={onChangePackage}
        onChangePhone={onChangePhone}
        onChangeSystem={onChangeSystem}
        onChangeStatus={onChangeStatus}
        onChangeAssignedDate={onChangeAssignedDate}
        onKeyDown={onKeyDown}
        onExport={onExport}
        onExtend={onExtend}
        onSearch={onSearch}
        onReset={onReset}
        onImport={onImport}
      />
      <Table
        columns={columns}
        dataSource={state?.listRegisterPackages}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_LIST,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          current: Number(state?.metadata?.page) + 1,
          style: { marginRight: 12 },
          onChange: onPageChange,
          total: state?.metadata?.total,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
};

export default RegisterPackages;
