import { FC } from 'react';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber, Select } from 'antd';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { boxFilterStyle, itemStyle } from 'src/styles';
import { isHavePermission } from 'src/utils/stringUtils';
import { DATE_FORMAT, ROLES } from 'src/utils/constants';
import moment from 'moment';
import '../style.scss';

interface DataFilter {
  assignedStaffs?: string[] | undefined;
  callingStatus?: string | undefined;
  callingTimeEnd?: number | undefined;
  callingTimeStart?: number | undefined;
  callingType?: string | undefined;
  endingStatus?: string | undefined;
  page: number;
  size: number;
  staffs?: string[];
  msisdn?: string | undefined;
}

type Props = {
  size: any;
  defaultData: DataFilter;
  staffs: any;
  isLoadingExport: boolean;
  onSearch: () => void;
  onExport: () => void;
  onKeyDown: (e: any) => void;
  onChangePhone: (phone: any) => void;
  onChangeCallType: (value: string) => void;
  onChangeCallStatus: (value: string) => void;
  onChangeCallEndType: (value: string) => void;
  onChangeDateCall: (values: any, formatString: [string, string]) => void;
  onChangeStaff: (value: string[]) => void;
  onReset: () => void;
  isExtended: boolean;
  handleExtendFilter: () => void;
};

const CALLING_TYPE = [
  { label: 'Cuộc gọi đến', value: 'incoming' },
  { label: 'Cuộc gọi đi', value: 'outgoing' },
  { label: 'Cuộc gọi nhỡ', value: 'missed' },
];

const CALLING_STATUS = [
  { label: 'Nghe máy', value: 'answered' },
  { label: 'Không nghe máy', value: 'unanswered' },
];

const CALL_END_STATUS = [
  { label: 'Khách hàng', value: 'customer' },
  { label: 'Sale', value: 'sale' },
  { label: 'Nhỡ do không bắt máy', value: 'missed' },
];

const CallCenterFilters: FC<Props> = ({
  size,
  staffs,
  isLoadingExport,
  onSearch,
  onExport,
  onKeyDown,
  onChangePhone,
  onChangeCallType,
  onChangeCallStatus,
  onChangeCallEndType,
  onChangeDateCall,
  onChangeStaff,
  onReset,
  defaultData,
  isExtended,
  handleExtendFilter,
}) => {
  const isSaleMan = isHavePermission(ROLES.ROLE_SALE_MAN);

  return (
    <div className="p-2 px-4">
      <div className="flex justify-between">
        <div className="flex justify-between items-center">
          <InputNumber
            size={size}
            prefix={<SearchOutlined className="pr-2" />}
            placeholder={'Nhập Số điện thoại...'}
            controls={false}
            style={{
              width: '20vw',
              borderRadius: 0,
              ...boxFilterStyle,
            }}
            value={defaultData.msisdn ?? ''}
            onChange={onChangePhone}
            onKeyDown={onKeyDown}
          />
          {!isExtended && (
            <div>
              <Button
                type="primary"
                onClick={handleExtendFilter}
                className="ml-4"
              >
                Bộ lọc
              </Button>
              <span
                style={{
                  color: 'rgba(0, 0, 0, 0.5)',
                  marginLeft: 10,
                }}
              >
                Lọc theo trường: Ngày gọi:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {moment().startOf('month').format(DATE_FORMAT)} -{' '}
                  {moment()
                    .subtract(0, 'days')
                    .endOf('day')
                    .format(DATE_FORMAT)}
                </span>
              </span>
            </div>
          )}
        </div>
        {!isSaleMan && (
          <Button
            className="btn-export"
            onClick={onExport}
            type="default"
            size={size}
            icon={<DownloadOutlined />}
            loading={isLoadingExport}
          >
            Export
          </Button>
        )}
      </div>
      <div className={isExtended ? '' : 'set-height-0px'}>
        <div className={'filter-container box-showdow-bottom'}>
          <div
            className="column-input-base"
            style={{ gap: 20, marginRight: 50 }}
          >
            <div className="label-input-base">
              <div style={{ fontSize: '12px' }}>Loại cuộc gọi</div>
              <Select
                allowClear
                showSearch
                size={size}
                style={itemStyle}
                placeholder="Loại cuộc gọi"
                onChange={onChangeCallType}
                options={CALLING_TYPE}
                defaultValue={defaultData.callingType}
              />
            </div>
            <div className="label-input-base">
              <Button type="primary" onClick={onSearch}>
                Cập nhật
              </Button>
              <Button type="default" onClick={handleExtendFilter}>
                Thu gọn
              </Button>
              <Button
                type="text"
                onClick={() => {
                  onReset();
                }}
              >
                Reset
              </Button>
            </div>
          </div>

          <div className="column-input-base" style={{ marginRight: 50 }}>
            <div className="label-input-base">
              <div style={{ fontSize: '12px' }}>Trạng thái thoại</div>
              <Select
                allowClear
                showSearch
                size={size}
                style={itemStyle}
                placeholder="Trạng thái thoại"
                onChange={onChangeCallStatus}
                options={CALLING_STATUS}
                defaultValue={defaultData.callingStatus}
              />
            </div>
            <div className="label-input-base">
              <div style={{ fontSize: '12px', width: 88 }}>Saleman</div>
              <SelectSaleMan
                style={itemStyle}
                size={size}
                treeData={staffs}
                onChange={onChangeStaff}
                placeholder={'Sale'}
                defaultValue={defaultData.assignedStaffs}
              />
            </div>
          </div>

          <div className="column-input-base" style={{ marginRight: 50 }}>
            <div className="label-input-base">
              <div className="text-xs">Trạng thái kết thúc</div>
              <Select
                allowClear
                showSearch
                placeholder="Trạng thái kết thúc"
                style={itemStyle}
                size={size}
                options={CALL_END_STATUS}
                onChange={onChangeCallEndType}
                defaultValue={defaultData.endingStatus}
              />
            </div>
            <div className="label-input-base"></div>
          </div>
          <div className="column-input-base">
            <div className="label-input-base">
              <div className="w-24 text-xs">Ngày gọi</div>
              <DatePicker.RangePicker
                allowClear
                // size={size}
                onChange={onChangeDateCall}
                placeholder={['Ngày gọi', '']}
                format={DATE_FORMAT}
                style={itemStyle}
                // defaultValue={[
                //   defaultData.callingTimeStart
                //     ? moment(defaultData.callingTimeStart)
                //     : moment(defaultData.callingTimeStart).startOf('month'),
                //   defaultData.callingTimeEnd
                //     ? moment(defaultData.callingTimeEnd)
                //     : moment(defaultData.callingTimeEnd).endOf('day'),
                // ]}
              />
            </div>
            <div className="label-input-base"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallCenterFilters;
