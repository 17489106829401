import {
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Divider, Dropdown, Layout, Menu, Popover, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AppContext from 'src/context/AppContext';
import careSoftServices from 'src/services/caresoft.service';
import { ROLES, ROUTERS, getRoleName } from 'src/utils/constants';
import {
  isHavePermissionScene,
  isNullOrEmpty,
  isUserHaveOneOfPermissions,
} from 'src/utils/stringUtils';
import Logo from '../../assets/logo.png';
import LogoVertical from '../../assets/logo_vertical.webp';
import BubbleCallIn from '../BubbleCallIn';
import ModalWarning from '../ModalWarning';
import NotyMissedCall from '../NotyMissedCall';

import { useLocation } from 'react-router-dom';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';
import registerPackageServices from 'src/services/registerPackage.service';
import { getLinkAvatar, onLogout } from 'src/utils';
import './style.scss';

const { Sider } = Layout;

const MainLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === '/') {
      BreadcrumbsRef.current?.updateKeyPath(['dashboard']);
    }
  }, [location?.pathname]);

  const { careSoftState, appState, dispatch }: ContextType =
    React.useContext(AppContext);
  const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
  const [param, setParam] = useState('/');
  const BreadcrumbsRef = useRef<any>(null);

  const getPackages = async () => {
    if (appState?.phonePackages?.length > 0) {
      return;
    }

    const response = await registerPackageServices.getListPackage();
    dispatch({
      type: APP_CONTEXT_ACTIONS.UPDATE_PACKAGES,
      payload: {
        phonePackages: response?.data ?? [],
      },
    });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const filterMenu = [
    {
      label: 'Tra cứu thông tin',
      key: ROUTERS.Search.key,
      onClick: () => {
        handleOnclick(ROUTERS.Search.path);
      },
      hidden: getRoleName(appState.profile?.roles?.[0]) === 'Sale Man',
    },
    {
      label: 'Tạo yêu cầu Đăng ký',
      key: ROUTERS.CreateRegisterPackage.key,
      onClick: () => {
        handleOnclick(ROUTERS.CreateRegisterPackage.path);
      },
      hidden: getRoleName(appState.profile?.roles?.[0]) === 'Sale Man',
    },
    {
      label: 'Lịch sử cuộc gọi',
      key: ROUTERS.CallCenter.key,
      onClick: () => {
        navigate(ROUTERS.CallCenter.path);
      },
      hidden: false,
    },
    {
      label: 'Lịch sử nạp thẻ',
      key: ROUTERS.TopUp.key,
      onClick: () => {
        handleOnclick(ROUTERS.TopUp.path);
      },
      hidden: false,
    },
    {
      label: 'Lịch sử đăng ký',
      key: ROUTERS.RegisterPackage.key,
      onClick: () => {
        handleOnclick(ROUTERS.RegisterPackage.path);
      },
      hidden: false,
    },
    // {
    //   label: 'Đăng ký ngoài CRM',
    //   key: ROUTERS.RegisterPackageOutside.key,
    //   onClick: () => {
    //     handleOnclick(ROUTERS.RegisterPackageOutside.path);
    //   },
    // },
  ];

  const handleOk = () => {
    navigate(param);
    setIsOpenModalWarning(false);
    careSoftServices.endCall();
  };

  const handleCancel = () => {
    setIsOpenModalWarning(false);
  };

  const handleOnclick = (param: string) => {
    if (careSoftState.callStatus !== 'ending') {
      setIsOpenModalWarning(true);
      setParam(param);
    } else {
      setIsOpenModalWarning(false);
      setParam(param);
      navigate(param);
    }
  };

  const items: any = [
    {
      label: 'Trang chủ',
      key: ROUTERS.Home.key,
      icon: <HomeOutlined />,
      onClick: () => handleOnclick('/'),
    },
    {
      label: 'Dashboard',
      key: ROUTERS.Dashboard.key,
      icon: <DashboardOutlined />,
      onClick: () => handleOnclick(ROUTERS.Dashboard.path),
    },
    {
      label: <div>Quản lý data</div>,
      key: 'dataManager',
      icon: <DatabaseOutlined />,
      children: [
        {
          label: 'Import Data',
          key: ROUTERS.DataImport.key,
          onClick: () => handleOnclick(ROUTERS.DataImport.path),
        },
        {
          label: 'Thêm mới Data',
          key: ROUTERS.DataCreate.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataCreate.path);
          },
        },
        {
          label: 'Data chưa chia',
          key: ROUTERS.DataManager.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataManager.path);
          },
        },
        {
          label: 'Data đã chia',
          key: ROUTERS.DataDivided.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataDivided.path);
          },
        },
        {
          label: 'Data thu hồi',
          key: ROUTERS.DataLx3.key,
          onClick: () => {
            handleOnclick(ROUTERS.DataLx3.path);
          },
        },
      ],
    },
    {
      label: <div>Quản lý sản phẩm</div>,
      key: ROUTERS?.ProductManager.key,
      icon: <FileDoneOutlined />,
      children: [
        {
          label: 'Danh sách sản phẩm',
          key: ROUTERS.ListProduct.key,
          onClick: () => {
            handleOnclick(ROUTERS.ListProduct.path);
          },
        },
      ],
    },
    {
      label: <div>Tiện ích</div>,
      key: 'ultis',
      icon: <FileSearchOutlined />,
      children: filterMenu.filter((item) => !item.hidden),
    },
    {
      label: <div>Báo cáo</div>,
      key: 'reports',
      icon: <ContainerOutlined />,
      children: [
        {
          label: 'Hoa hồng theo ngày',
          key: ROUTERS.RevenueReportsDaily.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsDaily.path);
          },
        },
        {
          label: 'Hoa hồng theo Sale',
          key: ROUTERS.RevenueReportsSaleman.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsSaleman.path);
          },
        },
        {
          label: 'Hoa hồng theo Số điện thoại',
          key: ROUTERS.RevenueReportsPhone.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsPhone.path);
          },
        },
        {
          label: 'Hoa hồng theo ngày (Cũ)',
          key: ROUTERS.RevenueReportsDailyOld.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsDailyOld.path);
          },
        },
        {
          label: 'Hoa hồng theo Sale (Cũ)',
          key: ROUTERS.RevenueReportsSalemanOld.key,
          onClick: () => {
            handleOnclick(ROUTERS.RevenueReportsSalemanOld.path);
          },
        },
        // {
        //   label: 'Chăm sóc data theo ngày',
        //   key: ROUTERS.TakeCareByDay.key,
        //   onClick: () => {
        //     handleOnclick(ROUTERS.TakeCareByDay.path);
        //   },
        // },
        {
          label: 'Bộ tỉ lệ tổng',
          key: ROUTERS.ReportsRatioTotal.key,
          onClick: () => {
            handleOnclick(ROUTERS.ReportsRatioTotal.path);
          },
        },
        {
          label: 'Bộ tỉ lệ luồng',
          key: ROUTERS.ReportsRatioByDay.key,
          onClick: () => {
            handleOnclick(ROUTERS.ReportsRatioByDay.path);
          },
        },
        {
          label: 'Theo dõi talktime',
          key: ROUTERS.TalkTimeMonitor.key,
          onClick: () => {
            handleOnclick(ROUTERS.TalkTimeMonitor.path);
          },
        },
      ],
    },
    {
      label: <div>Administration</div>,
      key: 'adminis',
      icon: <SettingOutlined />,
      children: [
        {
          label: 'Quản lý tài khoản',
          key: ROUTERS.Accounts.key,
          onClick: () => {
            handleOnclick(ROUTERS.Accounts.path);
          },
        },
        {
          label: 'Cấu hình Level Sale',
          key: ROUTERS.ConfigLevelSale.key,
          onClick: () => {
            handleOnclick(ROUTERS.ConfigLevelSale.path);
          },
        },
      ],
    },
  ];

  const goToProfilePage = () => {
    // navigate(ROUTERS.UserProfile.path);
  };

  const renderMenuProfile = () => {
    return (
      <div className="list-menu-profile">
        <div className="list-menu-profile-item" onClick={goToProfilePage}>
          Thông tin cá nhân
        </div>
        <Divider />
        <div onClick={onLogout} className="list-menu-profile-item">
          Đăng xuất
        </div>
      </div>
    );
  };

  const renderAvatar = (size: number) => {
    if (isNullOrEmpty(appState?.profile?.avatar)) {
      return <Avatar size={size} icon={<UserOutlined />} />;
    }
    return (
      <Avatar src={getLinkAvatar(appState?.profile?.avatar)} size={size} />
    );
  };

  const renderAccountBox = () => (
    <Space style={accountBoxStyle}>
      <Dropdown overlay={renderMenuProfile()} trigger={['click']}>
        {collapsed ? (
          renderAvatar(35)
        ) : (
          <Space style={{ display: 'flex', width: 248 }}>
            {renderAvatar(50)}
            <div
              style={{
                color: 'white',
                justifyContent: 'center',
              }}
            >
              <div>{appState.profile?.fullName}</div>
              <div>{getRoleName(appState.profile?.roles?.[0])}</div>
            </div>
          </Space>
        )}
      </Dropdown>
    </Space>
  );

  const splitPathname = window.location.pathname.split('/')[1];

  const selectedKeys = splitPathname;
  let openKeys = '';
  let breadcrumb = ['Trang chủ'];

  console.log('___', openKeys);

  let arrMenus = [];

  for (let i = 0; i < items.length; i++) {
    if (Array.isArray(items[i]?.children)) {
      let newChild: any = [];
      items[i]?.children?.map((child: any) => {
        if (isHavePermissionScene(child.key)) {
          newChild.push(child);
        }
      });
      if (newChild.length > 0) {
        arrMenus.push({
          ...items[i],
          children: newChild,
        });
      }
    } else {
      if (isHavePermissionScene(items[i].key)) {
        arrMenus.push({
          ...items[i],
        });
      }
    }

    if (items[i].key === selectedKeys) {
      breadcrumb.push(items[i].label);
    } else {
      if (Array.isArray(items[i]?.children)) {
        if (items[i]?.children?.find((x: any) => x.key === selectedKeys)) {
          openKeys = items[i].key;
          breadcrumb.push(items[i].label);
          breadcrumb.push(
            items[i].children.find((x: any) => x.key === selectedKeys).label
          );
        }
      }
    }
  }

  const vers = require('../../../package.json');

  const content = (
    <div style={{ fontWeight: 500, display: 'flex' }}>
      Bạn còn
      <div className="numberOfDataCaring">{appState?.customerCareRemain}</div>
      data cần thực hiện
    </div>
  );

  const getNumberOfData = (role?: string) => {
    switch (role) {
      case ROUTERS.DataDivided.key:
        return appState?.dataDividedRemain;
      case ROUTERS.DataManager.key:
        return appState?.dataNewRemain;
      default:
        return appState?.customerCareRemain;
    }
  };
  return (
    <Layout
      className={'fixed-sidebar layout-common'}
      style={{ minHeight: '100vh' }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={248}
      >
        {collapsed ? (
          <div className="box">
            <img src={Logo} style={{ height: 40, aspectRatio: 1 }} />
          </div>
        ) : (
          <div className="box">
            <h1 className="logo">
              <img
                src={LogoVertical}
                style={{ height: 28, aspectRatio: 3312 / 582 }}
              />
            </h1>
            <p className="version">Version: {vers.version}</p>
          </div>
        )}
        <Menu
          onClick={(value) => {
            BreadcrumbsRef.current?.updateKeyPath(value?.keyPath);
          }}
          theme="dark"
          defaultSelectedKeys={[selectedKeys || ROUTERS.Home.key]}
          defaultOpenKeys={[openKeys]}
          mode="inline"
          items={arrMenus}
          subMenuCloseDelay={0.01}
          style={{ display: 'flex', flexDirection: 'column', height: '150%' }}
          itemIcon={(item: any) => {
            const salesRoles = [
              ROLES.ROLE_SALE_MAN,
              ROLES.ROLE_SALE_LEADER,
              ROLES.ROLE_SALE_MANAGER,
            ];
            const isSalesRole = isUserHaveOneOfPermissions(salesRoles);
            const isAllowedEvent = [
              ROUTERS.Home.key,
              ROUTERS.DataDivided.key,
              ROUTERS.DataManager.key,
            ].includes(item?.eventKey);
            if (isSalesRole && isAllowedEvent) {
              const showContent = [ROUTERS.Home.key].includes(item?.eventKey)
                ? content
                : false;
              return (
                <Popover
                  placement="bottom"
                  content={showContent}
                  showArrow={false}
                  align={{
                    offset: [0, -12],
                  }}
                >
                  {/* <div className="calls">{getNumberOfData(item?.eventKey)}</div> */}
                </Popover>
              );
            }
            return null;
          }}
        />

        {renderAccountBox()}
      </Sider>
      <Layout className="site-layout fixed-header">
        {/* <div className="site-layout-background">
          {location?.pathname === '/' &&
            isUserHaveOneOfPermissions([ROLES.ROLE_SALE_MAN]) && (
              <Breadcrumb menuItem={items} ref={BreadcrumbsRef} />
            )}
          </div> */}
        <Outlet />
      </Layout>
      {careSoftState.phoneCallRinging && <BubbleCallIn />}

      <NotyMissedCall />
      <ModalWarning
        content="Bạn đang trong cuộc gọi với khách hàng. Nếu rời khỏi trang web, cuộc gọi của bạn sẽ bị kết thúc"
        isModalOpen={isOpenModalWarning}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </Layout>
  );
};

export default MainLayout;

const accountBoxStyle: React.CSSProperties = {
  position: 'sticky',
  left: 0,
  bottom: -16,
  padding: 12,
  paddingBottom: 28,
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgb(0,21,41)',
};
