import { Button, Modal, notification, Row, Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import service from "src/services/dataManager.service";
import { InputNumber } from "antd";

type Props = {
  filters: SearchListDataParams;
  setOpenProps: any;
  metadataProps: any;
  onSuccess: () => void;
};

type State = {
  loading: boolean;
  submitLoading: boolean;
  totalData: number;
};

function ManagerDividedDataModal(props: Props) {
  const setOpen = props.setOpenProps;
  const metadata = props.metadataProps;
  const [state, setState] = useState<State>({
    loading: true,
    totalData: 0,
    submitLoading: false,
  });

  const saleLeaders = useRef<any>([]);

  const fetchData = async () => {
    const resq = await service.getSeparatedData();
    if (resq?.data && resq.status === 200) {
      saleLeaders.current = resq?.data;
      setState((cur) => ({ ...cur, loading: false }));
      console.log("data", resq?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = () => {
    if (state.totalData <= metadata?.total && state.totalData > 0) {
      postData();
      setOpen(false);
    } else {
      notification.error({
        message: "Chia data thất bại",
        description: `Số data đã nhập lớn hơn ${metadata?.total}`,
      });
    }
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onChange = (number: any, id: number) => {
    saleLeaders.current = saleLeaders.current.map((item: any) => {
      if (item.id === id) {
        return { ...item, numberOfData: number };
      } else {
        return item;
      }
    });
  };

  const countTotal = () => {
    let total = 0;
    saleLeaders.current.forEach((item: any) => {
      if (item.numberOfData) {
        total += item.numberOfData * 1;
      }
    });
    setState((cur) => ({ ...cur, totalData: total }));
  };

  const onBlur = () => {
    countTotal();
  };

  const postData = async () => {
    let body: any[] = [];
    saleLeaders.current.forEach((item: any) => {
      if (item.numberOfData && item?.numberOfData * 1 > 0) {
        body.push({ userName: item.username, numberOfData: item.numberOfData });
      }
    });

    const res = await service.separatedDataSaleLeader(body, props.filters);

    if (res?.status === 200) {
      notification.success({
        message: "Chia data thành công",
        description: "Thành công",
      });
      props.onSuccess?.();
    } else {
      Modal.error({
        title: "Chia data thất bại",
        okText: "Đóng",
      });
    }
  };

  return (
    <Modal
      open={true}
      title="CHIA DATA CHO TEAM SALE"
      onOk={onSubmit}
      onCancel={handleCancel}
      wrapClassName="edit-level-sale-modal"
      style={{
        display: "block",
        justifyContent: "center",
        minWidth: "750px",
        maxHeight: "500px",
      }}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={state.submitLoading}
          onClick={onSubmit}
          style={{
            display: "flex",
            margin: "0 auto",
          }}
        >
          Chia data
        </Button>,
      ]}
    >
      <div style={infoStyles}>
        <span>
          Số data cần chia:{" "}
          <span style={{ fontWeight: "700" }}>{metadata?.total}</span>
        </span>
        <span>
          Số data đã nhập:
          <span style={{ fontWeight: "700" }}>
            {" "}
            {state.totalData > 0 ? state.totalData : ""}
          </span>
        </span>
      </div>
      <div style={tableStyles}>
        <Row style={headTableStyles}>
          <Col>Teamsale</Col>
          <Col>Số lượng saleman</Col>
          <Col>Số data phân bố</Col>
        </Row>
        {!state.loading &&
          saleLeaders.current?.map((data, index) => (
            <LeaderItem
              key={data.id}
              data={data}
              index={index}
              onBlur={onBlur}
              onChange={onChange}
            />
          ))}
      </div>
    </Modal>
  );
}

export default ManagerDividedDataModal;

const LeaderItem = ({ data, index, onBlur, onChange }: any) => {
  const [text, setText] = useState(null);
  const onChangeText = (e: any) => {
    setText(e);
    onChange(e, data.id);
  };

  return (
    <Row gutter={24} style={{ padding: 12, borderBottom: "1px solid #ccc" }}>
      <Col span={11}>{`TeamSale ${index + 1} (${data?.username})`}</Col>
      <Col span={6}>{data?.totalSaleMan}</Col>
      <Col span={7}>
        <InputNumber
          min={0}
          type="number"
          placeholder="Nhập số lượng data"
          style={{ width: "100%" }}
          value={text}
          onBlur={onBlur}
          onChange={onChangeText}
          controls={false}
        />
      </Col>
    </Row>
  );
};

const tableStyles = {
  width: "100%,",
  maxHeight: "600px",
  display: "flex",
  flexDirection: "column",
};
const headTableStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  height: "36px",
  background: "#277CBE",
  color: "#fff",
};
const infoStyles = {
  width: "100%",
  height: "36px",
  display: "flex",
  justifyContent: "space-between",
};
