/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, DatePicker } from 'antd';
import { useQuery, useWindowSize } from '../../utils/customHooks';
import {
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from '../../utils/constants';
import moment from 'moment';

function SplitDataHistory() {
  const query = useQuery();
  const windowSize = useWindowSize();

  const page = query.get('page') ? parseFloat(query.get('page')) : 1;
  const pageSize = query.get('size')
    ? parseFloat(query.get('size'))
    : DEFAULT_PAGE_SIZE;

  const statusQuery = query.get('status');
  const paymentChannelQuery = query.get('paymentChannel');
  const phonePackageQuery = query.get('phonePackage');
  const createdDateFromQuery = query.get('createdDateFrom');
  const createdDateToQuery = query.get('createdDateTo');

  const [status, setStatus] = useState(statusQuery);
  const [paymentChannel, setPaymentChannel] = useState(paymentChannelQuery);
  const [phonePackage, setPhonePackage] = useState(phonePackageQuery);
  const [createdDateFrom, setCreatedDateFrom] = useState(
    createdDateFromQuery
      ? moment(parseFloat(createdDateFromQuery))
      : moment().startOf('month').startOf('day')
  );
  const [createdDateTo, setCreatedDateTo] = useState(
    createdDateToQuery
      ? moment(parseFloat(createdDateToQuery))
      : moment().endOf('day')
  );

  const [dataList, setDataList] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getListData();
  }, []);

  useEffect(() => {
    getListStaff();
  }, []);

  const getListData = async () => {};

  const getListStaff = async () => {};

  const onSearch = () => {};

  const onPageChange = (curPage, size) => {};

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Ngày phân data',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (cell, record) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Người phân',
      dataIndex: 'createdStaff',
      key: 'createdStaff',
      render: (cell, record) => {
        return cell || '--';
      },
    },
    {
      title: 'Người nhận',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Số data đã phân',
      dataIndex: 'phonePackage',
      key: 'phonePackage',
    },
  ];

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT - 52;
  const renderBoxSearch = () => {
    return (
      <div className="box-search pdbt-20">
        <div className="box-search-item">
          <Input
            placeholder="Người phân"
            value={phonePackage}
            onChange={(e) => {
              setPhonePackage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSearch();
              }
            }}
          />
        </div>

        <div className="box-search-item">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            placeholder={'Người nhận: Sale leader'}
            value={status}
            onChange={(value) => setStatus(value)}
          ></Select>
        </div>

        <div className="box-search-item">
          <Select
            allowClear
            showSearch
            optionFilterProp="children"
            placeholder={'Người nhận: Sale man'}
            value={paymentChannel}
            onChange={(value) => setPaymentChannel(value)}
          ></Select>
        </div>

        <div className="box-search-item">
          <DatePicker
            placeholder={'Ngày tạo từ'}
            allowClear
            value={createdDateFrom}
            onChange={(date) => {
              setCreatedDateFrom(date ? date.startOf('day') : null);
            }}
            format={'DD/MM/YYYY'}
          />
        </div>

        <div className="box-search-item">
          <DatePicker
            placeholder={'Ngày tạo đến'}
            allowClear
            value={createdDateTo}
            onChange={(date) => {
              setCreatedDateTo(date ? date.endOf('day') : null);
            }}
            format={'DD/MM/YYYY'}
          />
        </div>

        <Button
          onClick={onSearch}
          type="primary"
          icon={<SearchOutlined />}
          loading={isLoading}
        >
          Tìm kiếm
        </Button>
      </div>
    );
  };

  return (
    <div>
      {renderBoxSearch()}
      <Table
        rowKey={'id'}
        dataSource={dataList}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: page,
          total: metadata.total,
          defaultPageSize: pageSize,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default SplitDataHistory;
