import { Button, Row } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { FC } from 'react';
import { COLORS } from 'src/utils/colors';

type Props = {
  style?: any;
  onSearch: () => void;
  onExtend: () => void;
  onReset: () => void;
  size: SizeType;
};

const FiltersButton: FC<Props> = ({
  style,
  size,
  onSearch,
  onExtend,
  onReset,
}) => {
  return (
    <Row style={style}>
      <Button
        onClick={onSearch}
        type="primary"
        size={size}
        style={{ marginLeft: 12 }}
      >
        Cập nhật
      </Button>

      <Button onClick={onExtend} size={size} className="btn-filter mgl-10">
        Thu gọn
      </Button>

      <Button
        onClick={onReset}
        type="primary"
        size={size}
        style={{
          marginLeft: 12,
          border: '0px',
          background: 'white',
          color: COLORS.primary,
        }}
      >
        Reset
      </Button>
    </Row>
  );
};
export default FiltersButton;
