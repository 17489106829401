import React, { useReducer, createContext, useEffect } from 'react';

import { reducer } from './appReducer';
import LocalStore from '../utils/localStorage';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import { APP_CONTEXT_ACTIONS } from './appAction';
import { useCareSoftHook } from 'src/services/careSoft/useCareSoftHook';
import userServices from 'src/services/users.service';
import reportsServices from 'src/services/reports.service';
import { RESPONSE_CODE } from 'src/utils/constants';

export type Action = {
  type: APP_CONTEXT_ACTIONS;
  payload: any;
};

export const initialAppState: AppStorage = {
  profile: undefined,
  loadingState: true,
  phonePackages: [],
  dataDividedRemain: 0,
  customerCareRemain: 0,
  dataNewRemain: 0,
  currentCollapse: 0,
  listStaff: [],
};

// Create a new context
export const AppContext = createContext<ContextType>({
  appState: initialAppState,
  dispatch: () => null,
  careSoftState: {} as any,
  careSoftDispatch: () => null,
});

// Create a provider component that wraps your app
export const AppProvider = ({ children }: any) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const [careSoftState, careSoftDispatch] = useCareSoftHook(
    appState.profile?.ipPhone
  );

  const getProfile = async () => {
    const loginSession = LocalStore.getInstance().read(
      LOCAL_STORAGE_VALUE.loginSession
    );
    const currentLoggedUser = LocalStore.getInstance().read(
      LOCAL_STORAGE_VALUE.currentLoggedUser
    );
    if (loginSession && currentLoggedUser?.firstLogin === 0) {
      const res = await userServices.getProfile();
      if (res?.status === 200) {
        const data = res?.data;
        dispatch({
          type: APP_CONTEXT_ACTIONS.UPDATE_PROFILE,
          payload: data,
        });
      }
      getListStaff();
    }
  };

  const getListStaff = async () => {
    const resp = await reportsServices.getListStaff();
    if (resp?.status === RESPONSE_CODE.SUCCESS) {
      dispatch({
        type: APP_CONTEXT_ACTIONS.SET_LIST_STAFF,
        payload: resp?.data,
      });
    }
  };

  const loadState = () => {
    const serializedState = LocalStore.getInstance().read(
      LOCAL_STORAGE_VALUE.appState
    );
    dispatch({
      type: APP_CONTEXT_ACTIONS.LOAD_STATE,
      payload: serializedState ?? initialAppState,
    });
  };

  // Load state from Local when the component mounts
  useEffect(() => {
    loadState();
    getProfile();
  }, []);

  // Save state to Local whenever it changes
  useEffect(() => {
    LocalStore.getInstance().save(
      LOCAL_STORAGE_VALUE.appState,
      JSON.stringify(appState)
    );
  }, [appState]);

  return (
    <AppContext.Provider
      value={{
        appState,
        dispatch,
        careSoftState,
        careSoftDispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Export the context
export default AppContext;
