import { Modal, message, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { TURN_UP } from 'src/constants';
import registerPackageServices from 'src/services/registerPackage.service';
import { SessionStorage } from 'src/utils/SessionStorage';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import service from 'src/services/topUp.service';
import { RESPONSE_CODE } from 'src/utils/constants';
import { ConfirmModalRef } from 'src/containers/DataManager/DataDividedDetail/components/Info/components/CustomersTransfer/ModalConfirm';
import searchServices from 'src/services/search.service';

export const dataDividedDetailService = {
  useInfoDataDivided(
    dataId: string,
    profile: DataDividedProfile | undefined,
    phoneNumber: string,
    onSubmitSuccess: () => void
  ): UseInfoDataDividedService {
    const TIME_COUNT_DOWN = 120;

    const [state, setState] = useState<InfoState>({
      otp: '',
      currentRequest: null,
      openCreateRequest: false,
      isShowRegisterPackage: false,
      loadingTurnUp: false,
      loadingTurnUpTKC: false,
      openTopUp: false,
      isOpenRegisterPackageOutSide: false,
      loading: false,
      timeCountDown: 0,
      topupCustomerTransfer: false,
      topupRecordTransferOutside: false,
      usingPackageCode: undefined,
      orderCode: null,
      loadingSearchVip: false,
    });

    const refConfirmTransaction = React.useRef<ConfirmModalRef>();

    const isEnableBtnRegisterAndTopup = ['L3.2', 'L8.1'].includes(
      profile?.currentLevelSale?.code ?? ''
    );

    const isDisableCRM = ['L8.1', 'L8.3'].includes(
      profile?.currentLevelSale?.code ?? ''
    );

    const onOpenChangeTopup = (newOpen: boolean) => {
      if (isEnableBtnRegisterAndTopup) {
        setState((cur) => ({
          ...cur,
          openTopUp: newOpen,
        }));
      }
    };

    const handleHideTopUp = () => {
      setState((cur) => ({ ...cur, openTopUp: false }));
    };

    const updateOtp = (value: string) => {
      setState((cur) => ({ ...cur, otp: value }));
    };

    const getCurrentRequest = async () => {
      const res = await registerPackageServices.getCurrentRequest();

      if (res?.status === 200) {
        const curRequest = res?.data?.filter(
          (item: any) => item.phoneNumber === phoneNumber
        );

        const request = curRequest?.[0];
        setState((cur) => ({
          ...cur,
          otp: request?.otp,
          currentRequest: request,
        }));

        if (request?.createdDate) {
          const now = moment(request?.currentTime);
          const end = moment(request?.createdDate);
          const seconds = moment.duration(now.diff(end)).asSeconds();
          const countDown = TIME_COUNT_DOWN - seconds;
          if (countDown < TIME_COUNT_DOWN && countDown > 0) {
            setState((cur) => ({
              ...cur,
              isShowRegisterPackage: true,
              timeCountDown: countDown,
            }));
          }
        }
      }
    };

    const hiddenModal = () => {
      setState((cur) => ({
        ...cur,
        isShowRegisterPackage: false,
      }));
      getCurrentRequest();
    };

    const onCreateRequest = async (packageCode: string, type: string) => {
      setState((cur) => ({ ...cur, loading: true }));
      const res = await registerPackageServices.postCreateRequest(
        dataId,
        packageCode,
        type
      );
      if (res?.status === 200) {
        notification.success({
          message: 'Tạo yêu cầu thành công',
          description:
            'Mã OTP đã được gửi tới số điện thoại của quý khách (Mã OTP có hiệu lực trong vòng 2 phút kể từ khi nhận mã)!',
          placement: 'topRight',
        });
        setState((cur) => ({
          ...cur,
          loading: false,
          isShowRegisterPackage: true,
          timeCountDown: TIME_COUNT_DOWN,
          openCreateRequest: true,
          orderCode: res?.data?.orderCode,
        }));

        getCurrentRequest();
        onSubmitSuccess();
      } else {
        setState((cur) => ({
          ...cur,
          openCreateRequest: true,
        }));
        notification.error({
          message: 'Tạo yêu cầu thất bại',
          description: res?.data?.message ?? 'Không tìm thấy gói cước!',
          placement: 'top',
        });
      }
      setState((cur) => ({ ...cur, loading: false }));
    };

    const onRegisterPackage = async () => {
      if (isNullOrEmpty(state.otp)) {
        message.error('Vui lòng nhập OTP');
        return;
      }
      setState((cur) => ({ ...cur, loading: true }));
      const packageName = state.currentRequest?.phonePackage ?? '';
      const res = await registerPackageServices.postRegisterPackages(
        dataId,
        packageName,
        state.otp,
        state.currentRequest?.channel ?? '',
        state.orderCode
      );
      if (res?.status === 200) {
        notification.success({
          message: 'Đăng ký thành công',
          description: 'Bạn đã đăng ký thành công!',
          placement: 'top',
        });
        setState((cur) => ({
          ...cur,
          loading: false,
          openCreateRequest: false,
          isShowRegisterPackage: false,
          currentRequest: null,
          otp: '',
        }));

        onSubmitSuccess();
      } else {
        setState((cur) => ({
          ...cur,
          loading: false,
          openCreateRequest: true,
        }));
        notification.error({
          message: 'Đăng ký thất bại',
          description: res?.data.message,
          placement: 'top',
        });
      }
    };

    const onSaveOtp = async () => {
      if (isNullOrEmpty(state.otp)) {
        notification.error({
          message: 'Vui lòng nhập OTP',
          placement: 'top',
        });
        return;
      }
      const requestId = state.currentRequest?.id || 0;
      if (isNullOrEmpty(requestId)) {
        notification.error({
          message: 'Cập nhật OTP thất bại!',
          placement: 'top',
        });
        return;
      }
      const res = await registerPackageServices.putUpdateOtp(
        requestId,
        state.otp
      );
      if (res?.status === 200) {
        notification.success({
          message: 'Cập nhật OTP thành công',
          placement: 'top',
        });
      } else {
        notification.error({
          message: 'Cập nhật OTP thất bại!',
          placement: 'top',
        });
      }
    };

    // FLOW SEARCH
    const handleSearch = async (type: string) => {
      let response: any;
      if (type === TURN_UP) {
        setState((cur) => ({
          ...cur,
          loadingTurnUp: true,
        }));
        response = await registerPackageServices.turnUp(dataId);
      } else {
        setState((cur) => ({
          ...cur,
          loadingTurnUpTKC: true,
        }));
        response = await registerPackageServices.turnUpTKC(dataId);
      }
      const { status, data } = response;

      if (status === 200) {
        notification.success({
          message: 'Thành công',
          description:
            'Đã gửi yêu cầu tra cứu thành công vui lòng đợi trong giây lát',
          maxCount: 1,
          placement: 'bottom',
        });
      } else {
        const { message } = data;
        notification.error({
          message: 'Tra cứu thất bại',
          description: message,
          maxCount: 5,
          placement: 'bottom',
        });
      }

      onSubmitSuccess();
      setState((cur) => ({
        ...cur,
        usingPackageCode: response?.data?.usingPackages?.[0]?.packCode,
        loadingTurnUp: type === TURN_UP ? false : cur.loadingTurnUp,
        loadingTurnUpTKC: type !== TURN_UP ? false : cur.loadingTurnUpTKC,
      }));
    };

    // FLOW REGISTER OUTSIDE
    const openModalRegisterPackage = () => {
      setState((cur) => ({
        ...cur,
        isOpenRegisterPackageOutSide: true,
      }));
    };

    const hideModalRegisterPackage = () => {
      setState((cur) => ({
        ...cur,
        isOpenRegisterPackageOutSide: false,
      }));
    };

    // FLOW REGISTER
    const onOpenChangeRegister = (newOpen: boolean) => {
      if (isEnableBtnRegisterAndTopup) {
        setState((cur) => ({
          ...cur,
          openCreateRequest: newOpen,
        }));
      }
    };

    const saveLogCall = async (value: boolean) => {
      await registerPackageServices.saveLogRegister(
        SessionStorage.currentCallId!,
        value ? 'reject' : 'accept'
      );

      setState((cur) => ({
        ...cur,
        openCreateRequest: false,
      }));

      if (value) {
        notification.success({
          message: 'Tạo yêu cầu huỷ gói cước thành công',
        });
      }
    };

    useEffect(() => {
      getCurrentRequest();
    }, []);

    const openTopupTransaction = (newOpen: boolean) => {
      setState((cur: InfoState) => ({
        ...cur,
        topupCustomerTransfer: newOpen,
      }));
    };

    const openTopupRecordOutside = (newOpen: boolean) => {
      setState((cur) => ({
        ...cur,
        topupRecordTransferOutside: newOpen,
      }));
    };

    const handleFinishTopupTransfer = async (params: any) => {
      setState((cur) => ({
        ...cur,
        loading: true,
      }));
      const resp = await service.transactionCreate(params);
      if (resp?.status === RESPONSE_CODE.SUCCESS) {
        setState((cur) => ({
          ...cur,
          loading: false,
          topupRecordTransferOutside: false,
        }));
        refConfirmTransaction?.current?.close();
        message.success('Ghi nhận thành công');
        onSubmitSuccess();
      } else {
        setState((cur) => ({
          ...cur,
          loading: false,
        }));
        message?.error(resp?.data);
      }
    };

    const openModalConfirmTransaction = (values: any) => {
      refConfirmTransaction?.current?.open(values);
      setState((cur) => ({
        ...cur,
        topupCustomerTransfer: false,
      }));
    };

    const handleSearchVip = async (phoneNumber: string) => {
      setState((cur) => ({
        ...cur,
        loadingSearchVip: true,
      }));
      const res = await searchServices.searchDataVip(phoneNumber);
      if (res?.status === RESPONSE_CODE.SUCCESS) {
        setState((cur) => ({
          ...cur,
          loadingSearchVip: false,
        }));
        message?.success(res?.data.message);
      } else {
        setState((cur) => ({
          ...cur,
          loadingSearchVip: false,
        }));
        if (res?.data.errorCode === 'is_user_vip') {
          Modal.confirm({
            title: `Bạn có muốn tiếp tục tương tác không ?`,
            icon: '',
            okText: 'Tiếp tục',
            cancelButtonProps: { style: { display: 'none' } },
          });
        } else {
          message?.error(res?.data.message);
        }
      }
    };

    return {
      state,
      isEnableBtnRegisterAndTopup,
      isDisableCRM,
      onOpenChangeRegister,
      onOpenChangeTopup,
      handleHideTopUp,
      updateOtp,
      hiddenModal,
      onCreateRequest,
      onRegisterPackage,
      onSaveOtp,
      handleSearch,
      openModalRegisterPackage,
      hideModalRegisterPackage,
      saveLogCall,
      openTopupTransaction,
      openTopupRecordOutside,
      handleFinishTopupTransfer,
      openModalConfirmTransaction,
      refConfirmTransaction,
      handleSearchVip,
    };
  },
};
