import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';

type Props = {
  onSearch: () => void;
  onExport: () => void;
  state: State;
};
const Actions: React.FC<Props> = (props) => {
  const { onSearch, onExport, state } = props;
  return (
    <Space>
      <Button
        onClick={onSearch}
        type="primary"
        icon={<SearchOutlined />}
        loading={state.isLoading}
      >
        Tìm kiếm
      </Button>
      <Button
        className="mgl-10 btn-export"
        onClick={onExport}
        type="default"
        icon={<DownloadOutlined />}
        loading={state.isLoadingExport}
      >
        Export
      </Button>
    </Space>
  );
};

export default Actions;
