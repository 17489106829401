import React, { useEffect, useState } from 'react';
import levelSaleService from 'src/services/levelSale.service';
import { LevelSaleItem } from './LevelSaleItem';
import { PlusOutlined } from '@ant-design/icons';
import ModalCreateLeveSale from './components/ModalCreateLevelSale';
import { TYPE_CREATE, TYPE_EDIT } from 'src/constants';
import { Button, Col, Modal, notification, Row } from 'antd';
import { TitleColumn } from 'src/components/table/TitleColumn';
import { COLORS } from 'src/utils/colors';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';

type State = {
  data: LevelSaleItem[];
  loading: boolean;
  isShowModal: boolean;
  typeModal: string;
  selectItem?: LevelSaleItem;
  isSaleParent: boolean;
};

function ConfigLevelSale() {
  const [state, setState] = useState<State>({
    data: [],
    loading: true,
    isShowModal: false,
    typeModal: TYPE_CREATE,
    selectItem: undefined,
    isSaleParent: false,
  });

  const fetchData = async () => {
    const response = await levelSaleService.getLevels();
    if (response?.status === 200) {
      setState((cur: State) => ({
        ...cur,
        data: response.data,
        loading: false,
        isShowModal: false,
      }));
    } else {
      setState((cur: State) => ({
        ...cur,
        loading: false,
        isShowModal: false,
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCreate = () => {
    setState((cur: State) => ({
      ...cur,
      isShowModal: true,
      typeModal: TYPE_CREATE,
      selectItem: undefined,
      isSaleParent: false,
    }));
  };

  const onHideModal = () => {
    setState((cur: State) => ({ ...cur, isShowModal: false }));
  };

  const onMessage = (
    isCreate: boolean,
    isSuccess: boolean,
    message?: string
  ) => {
    const messageType = isCreate ? 'Tạo mới' : 'Chỉnh sửa';
    if (isSuccess) {
      notification.success({
        message: `${messageType} level sale`,
        description: 'Thành công',
      });
    } else {
      notification.error({
        message: message ?? `${messageType} level sale`,
        description: 'Thất bại',
      });
    }
  };

  const onCreateLevelSale = async (
    params: CreateLevelSaleParams,
    isCreate: boolean,
    id?: number
  ) => {
    setState((cur) => ({ ...cur, loading: true }));
    if (isCreate) {
      const response = await levelSaleService.create(params);
      onMessage(isCreate, response.status === 200, response?.data?.message);
    } else {
      if (id) {
        const response = await levelSaleService.edit(id, params);
        onMessage(isCreate, response.status === 200, response?.data?.message);
      }
    }
    fetchData();
  };

  const onEdit = (item: LevelSaleItem, isParent: boolean) => {
    setState((cur) => ({
      ...cur,
      isShowModal: true,
      typeModal: TYPE_EDIT,
      selectItem: item,
      isSaleParent: isParent,
    }));
  };

  const onDelete = (item: LevelSaleItem) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: `Bạn có chắc chắn muốn xóa ${item.code} không?`,
      okText: 'Đồng ý',
      cancelText: 'Hủy',
      onOk: async () => {
        await levelSaleService.delete(item.id);
        fetchData();
      },
    });
  };

  return (
    <div>
      <Helmet>
        <title>Cấu hình Level Sale</title>
        <meta name="description" content={projectName} />
      </Helmet>
      {state.isShowModal && (
        <ModalCreateLeveSale
          type={state.typeModal}
          onHide={onHideModal}
          onSumit={onCreateLevelSale}
          sales={state.data}
          selectItem={state.selectItem}
          isSaleParent={state.isSaleParent}
        />
      )}
      <div style={headStyles}>
        <div style={formBtnStyles}>
          <Button
            onClick={onCreate}
            type="primary"
            style={btnStyles}
            icon={<PlusOutlined />}
          >
            Thêm mới
          </Button>
        </div>

        <Row style={headerStyles}>
          <Col span={2}>
            <TitleColumn title={'Level sale'} textStyles={textCenterLeft} />
          </Col>
          <Col style={textCenterLeft} span={7}>
            <TitleColumn title={'Định nghĩa'} textStyles={textCenterLeft} />
          </Col>
          <Col span={5}>
            <TitleColumn title={'Người tạo'} textStyles={textCenterLeft} />
          </Col>
          <Col span={6}>
            <TitleColumn title={'Ngày tạo'} textStyles={textCenterLeft} />
          </Col>
          <Col span={4}>
            <TitleColumn title={'Thao tác'} textStyles={textCenterLeft} />
          </Col>
        </Row>
      </div>
      <div style={{ height: '86%', overflow: 'auto' }}>
        {state.data.map((item: LevelSaleItem) => (
          <LevelSaleItem
            item={item}
            key={'level-sale' + item.id}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
      </div>
    </div>
  );
}

export default ConfigLevelSale;

const headStyles: React.CSSProperties = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const formBtnStyles: React.CSSProperties = {
  marginRight: '2px',
};

const btnStyles: React.CSSProperties = {
  background: COLORS.headerBackground,
  border: 'none',
  borderRadius: '5px',
  color: '#fff',
  float: 'right',
  marginRight: '16px',
};

const headerStyles: React.CSSProperties = {
  background: COLORS.headerBackground,
  marginTop: '16px',
  paddingTop: '10px',
  paddingBottom: '10px',
};

const textCenterLeft: React.CSSProperties = {
  textAlign: 'left',
  paddingLeft: '20px',
  fontSize: 14,
  fontWeight: '500',
};
