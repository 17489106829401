import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Button,
  InputNumber,
  Table,
  Modal,
  Input,
  notification,
  TreeSelect,
} from 'antd';
import dataManagerServices from '../../../services/dataManager.service';
import {
  isNullOrEmpty,
  modifyArrTreeSelect,
  updateQueryStringParameter,
} from '../../../utils/stringUtils';
import { useQuery, useWindowSize } from '../../../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import {
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from '../../../utils/constants';
import registerPackageServices from '../../../services/registerPackage.service';
import moment from 'moment';
import { isAdmin, isSaleLeader, isSaleMan, projectName } from 'src/utils';

import './style.scss';
import { inputStyle } from 'src/styles';
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';
import AppContext from 'src/context/AppContext';

function CreateData() {
  const navigate = useNavigate();
  const query = useQuery();
  const windowSize = useWindowSize();

  const { appState } = React.useContext(AppContext);

  const page = query.get('page') ? parseFloat(query.get('page')) : 1;
  const pageSize = query.get('size')
    ? parseFloat(query.get('size'))
    : DEFAULT_PAGE_SIZE;

  const msisdnQuery = query.get('msisdn')
    ? parseFloat(query.get('msisdn'))
    : '';

  const [msisdn, setMsisdn] = useState(msisdnQuery);
  const [dataList, setDataList] = useState([]);
  const [metadata, setMetadata] = useState({});

  const [dataRegister, setDataRegister] = useState({});
  const [dataRegisterError, setDataRegisterError] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const [listStaff, setListStaff] = useState([]);

  useEffect(() => {
    getListData();
  }, [page, pageSize, msisdnQuery]);

  const getListData = async () => {
    setIsLoading(true);
    let query = buildQueryString();
    const resp = await dataManagerServices.getListDataManual(query);
    const data = resp?.data;
    const headers = resp?.headers;
    if (resp?.status === 200) {
      setDataList(data);
      setMetadata({
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      });
    }
    setIsLoading(false);
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  const buildQueryString = (data = {}, isUrl, isClear) => {
    let queryString = '';
    let dataValue = {
      msisdn,
      page,
      pageSize,
      ...data,
    };

    if (isClear) {
      dataValue = {
        page,
        pageSize,
        ...data,
      };
    }

    if (dataValue.msisdn) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        dataValue.msisdn
      );
    }

    if (isUrl) {
      if (dataValue.page && dataValue.page > 1) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          dataValue.page
        );
      }

      if (dataValue.pageSize && dataValue.pageSize !== DEFAULT_PAGE_SIZE) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    } else {
      if (dataValue.page && dataValue.page > 0) {
        queryString = updateQueryStringParameter(
          queryString,
          'page',
          parseFloat(dataValue.page) - 1
        );
      }

      if (dataValue.pageSize) {
        queryString = updateQueryStringParameter(
          queryString,
          'size',
          dataValue.pageSize
        );
      }
    }

    return queryString;
  };

  const onSearch = () => {
    let query = buildQueryString({ page: 1 }, true);
    const currPath = window.location.search;
    if (query !== currPath) {
      navigate(`/data-create${query || ''}`);
    } else {
      getListData();
    }
  };

  const onPageChange = (curPage, size) => {
    let query = buildQueryString({ page: curPage, pageSize: size }, true);
    navigate(`/data-create${query || ''}`);
  };

  // const onValidateRegister = () => {
  //   // let dataError = {};
  //   let check = false;
  //   // if (!dataRegister?.msisdn) {
  //   //   dataError.msisdn = 'Vui lòng nhập số điện thoại';
  //   //   check = true;
  //   // } else if (!dataRegister?.saleMan) {
  //   //   dataError.saleMan = 'Vui lòng chọn saleMan';
  //   //   check = true;
  //   // } else {
  //   //   if (dataRegister?.msisdn.toString().length !== 9) {
  //   //     dataError.msisdn = 'Số điện thoại sai định dạng';
  //   //     check = true;
  //   //   } else {
  //   //     dataError.msisdn = '';
  //   //   }
  //   // }

  //   // setDataRegisterError(dataError);
  //   return check;
  // };

  const onSubmit = async () => {
    if (isDisable) {
      return;
    }
    setIsSubmit(true);

    let queryString = '';
    if (dataRegister.msisdn) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        dataRegister.msisdn
      );
    }
    if (dataRegister.packageCode) {
      queryString = updateQueryStringParameter(
        queryString,
        'packageCode',
        dataRegister.packageCode
      );
    }
    if (dataRegister.saleMan && !isSaleMan) {
      queryString = updateQueryStringParameter(
        queryString,
        'saleMan',
        dataRegister.saleMan
      );
    } else {
      queryString = updateQueryStringParameter(
        queryString,
        'saleMan',
        appState.profile?.username
      );
    }
    const resp = await dataManagerServices.createDataManual(queryString);
    const data = resp?.data;

    if (resp?.status === 200) {
      notification.success({
        message: 'Thêm mới data',
        description: 'Thành công',
      });
      onSearch();
      setDataRegister({});
      setDataRegisterError(null);
    } else {
      setDataRegisterError(data?.message);
      Modal.error({
        title: 'Thêm mới data thất bại',
        content: data?.message,
        okText: 'Đóng',
      });
    }
    setIsSubmit(false);
  };

  useEffect(() => {
    getListStaff();
  }, []);

  const getListStaff = async () => {
    const resp = await registerPackageServices.getListStaffTree();
    const data = resp?.data;
    if (resp?.status === 200) {
      let newArr = modifyArrTreeSelect(data);
      setListStaff(newArr);
    }
  };

  const onChangePhoneNumber = (e) => {
    setDataRegister({ ...dataRegister, msisdn: e });
  };

  React.useEffect(() => {
    if (!isNullOrEmpty(dataRegister?.msisdn)) {
      delayedSearch();
      return delayedSearch.cancel;
    }
  }, [dataRegister?.msisdn]);

  const delayedSearch = React.useCallback(
    debounce(() => {
      if (dataRegister?.msisdn?.toString()?.length !== 9) {
        setDataRegisterError({
          errorCode: 'phone_is_existed',
          message: 'Số điện thoại sai định dạng',
        });
      } else {
        onValidateRegister();
      }
    }, 300),
    [dataRegister?.msisdn]
  );

  const onValidateRegister = debounce(async () => {
    let params = {
      msisdn: dataRegister?.msisdn || '',
    };
    const resp = await dataManagerServices.phoneCheck(params);
    setDataRegisterError(resp?.data);
  });

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return (page - 1) * pageSize + index + 1;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'msisdn',
      key: 'msisdn',
    },
    {
      title: 'Loại thuê bao',
      dataIndex: 'sourceSys',
      key: 'sourceSys',
    },
    {
      title: 'Gói cước đang sử dụng',
      dataIndex: 'packActive',
      key: 'packActive',
    },
    {
      title: 'Saleman',
      dataIndex: 'assignedStaff',
      key: 'assignedStaff',
    },
    {
      title: 'Người tạo',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
  ];

  const isDisable =
    dataRegisterError?.errorCode === 'phone_is_existed' ||
    (!dataRegister?.saleMan && !isSaleMan) ||
    !dataRegister?.msisdn;

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;

  return (
    <div style={{ marginTop: 6 }}>
      <Helmet>
        <title>Thêm mới Data</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <div className="dpl-flex align-items-center justify-content-space-between">
        <div className="box-register-package" style={{ display: 'block' }}>
          <div className="flex">
            <div className="box-register-package-item">
              <InputNumber
                placeholder="Nhập số điện thoại"
                controls={false}
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  borderRadius: 0,
                }}
                value={dataRegister?.msisdn}
                onChange={onChangePhoneNumber}
              />
            </div>

            <div className="box-register-package-item">
              <Input
                placeholder="Gói cước đang sử dụng"
                value={dataRegister?.packageCode}
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  borderRadius: 0,
                }}
                onChange={(e) => {
                  setDataRegister({
                    ...dataRegister,
                    packageCode: e.target.value,
                  });
                }}
              />
            </div>

            {!isSaleMan && (
              <div className="box-register-package-item">
                <TreeSelect
                  value={dataRegister?.saleMan || null}
                  className="treeSelect"
                  showSearch
                  dropdownStyle={{
                    maxHeight: 300,
                    overflow: 'auto',
                    minWidth: 300,
                  }}
                  placeholder="Saleman"
                  dropdownMatchSelectWidth={false}
                  allowClear
                  treeDefaultExpandAll
                  treeData={listStaff}
                  onChange={(staff) => {
                    const curStaff = listStaff.find((x) => x.value === staff);
                    if (!curStaff) {
                      setDataRegister({ ...dataRegister, saleMan: staff });
                    }
                  }}
                />
              </div>
            )}

            {(isAdmin || isSaleLeader || isSaleMan) && (
              <Button
                loading={isSubmit}
                onClick={onSubmit}
                type="primary"
                disabled={isDisable}
              >
                Thêm data
              </Button>
            )}
          </div>
          {dataRegisterError?.message && (
            <div className="txt-error">{dataRegisterError?.message}</div>
          )}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '24%',
            marginRight: 16,
          }}
        >
          <InputNumber
            style={{ ...inputStyle, width: '100%' }}
            prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
            placeholder="Nhập Số điện thoại..."
            controls={false}
            value={msisdn}
            onChange={(e) => {
              setMsisdn(e);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSearch();
              }
            }}
          />
        </div>
      </div>

      <Table
        rowKey={'id'}
        dataSource={dataList}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: page,
          total: metadata.total,
          defaultPageSize: pageSize,
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ y: tableHeight }}
      />
    </div>
  );
}

export default CreateData;
