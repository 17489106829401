import { projectName } from 'src/utils';
import { Helmet } from 'react-helmet';
import { Button, InputNumber, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { inputStyleDivided } from 'src/styles';
import searchServices from '../../services/search.service';
import { useState } from 'react';
import { RESPONSE_CODE } from 'src/utils/constants';

const SearchARPU = () => {
  const [msisdn, setMsisdn] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(undefined);

  const onSearch = async () => {
    setLoading(true);
    const response = await searchServices.searchARPU(msisdn);
    if (response?.status === RESPONSE_CODE.SUCCESS) {
      setData(response?.data);
      message.success('Tra cứu thành công!!');
    } else {
      setData(undefined);
      message.error(response?.data);
    }
    setLoading(false);
  };

  return (
    <div>
      <Helmet>
        <title>Tra cứu thông tin</title>
        <meta name="description" content={projectName} />
      </Helmet>

      <div className="dpl-flex align-items-center pd-8 pdl-15 pdr-15">
        <InputNumber
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder="Nhập số điện thoại không chứa đầu 0 (Ví dụ: 931102968)"
          controls={false}
          style={{
            ...inputStyleDivided,
            width: '24%',
            marginLeft: 0,
            marginBottom: '0px !important',
          }}
          value={msisdn}
          onChange={(e) => {
            setMsisdn(e);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSearch();
            }
          }}
        />
        <Button
          className="mgl-10"
          onClick={onSearch}
          type="primary"
          icon={<SearchOutlined />}
          loading={loading}
        >
          Tra cứu
        </Button>
      </div>

      {data?.arpu && (
        <div className="pl-4 pt-2">
          <p>ARPU: {data?.arpu}</p>
          <p>Chi tiết: {data?.detail}</p>
        </div>
      )}
    </div>
  );
};

export default SearchARPU;
