import React from 'react';
import { DatePicker, Select, Space } from 'antd';
import moment, { Moment } from 'moment';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { datePickerStyle, filterBoxStyle } from 'src/styles';
import { State } from '../..';
import Actions from '../Actions';
import { DATE_FORMAT } from 'src/utils/constants';

type Props = {
  state: State;
  defaultSelectedDate: Moment;
  defaultSelectedPackage: any;
  handleChangePackage: (value: string[]) => void;
  handleChangeAssignedDateFrom: (value: Moment | null) => void;
  onChangeStaff: (values: string[]) => void;
  onSearch: () => void;
  onExport: () => void;
};

const Filters: React.FC<Props> = ({
  state,
  defaultSelectedDate,
  onChangeStaff,
  handleChangeAssignedDateFrom,
  handleChangePackage,
  onSearch,
  onExport,
  defaultSelectedPackage,
}) => {
  return (
    <Space>
      <Select
        allowClear
        showSearch
        mode="multiple"
        style={filterBoxStyle}
        placeholder={'Gói đang sử dụng'}
        onChange={handleChangePackage}
        defaultValue={defaultSelectedPackage}
      >
        {state?.listPackage?.map((item: any) => (
          <Select.Option key={item.id} value={item.code}>
            {item.code}
          </Select.Option>
        ))}
      </Select>

      <SelectSaleMan
        style={filterBoxStyle}
        treeData={state.staff}
        value={state.selectedStaff}
        placeholder={'Chọn sale man'}
        onChange={onChangeStaff}
      />

      <DatePicker
        allowClear
        style={datePickerStyle}
        placeholder={'Ngày chia data'}
        onChange={handleChangeAssignedDateFrom}
        format={DATE_FORMAT}
        defaultValue={moment(defaultSelectedDate)}
      />
      <Actions onSearch={onSearch} onExport={onExport} state={state} />
    </Space>
  );
};

export default Filters;
