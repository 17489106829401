import {
  Button,
  Col,
  DatePicker,
  Input,
  notification,
  Row,
  Select,
  Spin,
  TimePicker,
} from 'antd';
import React, { memo, useEffect, useRef, useState } from 'react';
import dataManagerServices from 'src/services/dataManager.service';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import useDataCaring from 'src/hooks/useDataCaring';
import { DATE_FORMAT } from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';

type Props = {
  customerStages: SaleBaseData[];
  profile?: DataDividedProfile;
  onSubmitSuccess: () => void;
  dataId?: number;
};

type State = {
  loading: boolean;
  isDisabled: boolean;
  reasons: ReasonItem[];
  note: string;
  levelSaleId?: number;
  previousLevelSaleId?: number;
  reasonId?: number;
  day?: string;
  hour?: string;
  errorDay?: string;
  errorHour?: string;
  levelSale?: string;
  levelSaleCode?: string;
};

const UpdateLevelSale: React.FC<Props> = (props: Props) => {
  const { customerStages, profile, onSubmitSuccess, dataId } = props;
  const { getDataCaring } = useDataCaring();
  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false);

  const timePickerRef = useRef<any>(null);
  const windowSize = useWindowSize();

  const bodySubmitDefault = {
    levelSaleId: undefined,
    previousLevelSaleId: undefined,
    reasonId: undefined,
    note: undefined,
    appointmentTime: undefined,
  };

  const bodySubmit = useRef<UpdateLevelSaleDividedBody>(bodySubmitDefault);

  const [state, setState] = useState<State>({
    loading: false,
    isDisabled: true,
    reasons: [],
    note: '',
    levelSaleId: undefined,
    previousLevelSaleId: undefined,
    reasonId: undefined,
    day: '',
    hour: '',
    errorDay: '',
    errorHour: '',
    levelSaleCode: '',
  });

  const disabledTime = () => {
    const currentMoment = moment();
    const isCurrentDay = state?.day === currentMoment.format('YYYY-MM-DD');

    if (isCurrentDay) {
      const currentHour = currentMoment.hour();
      const currentMinute = currentMoment.minute();
      const disabledHours = () => [...(Array(currentHour).keys() as any)];
      const disabledMinutes = (hour: any) =>
        hour === currentHour ? [...(Array(currentMinute).keys() as any)] : [];

      return {
        disabledHours,
        disabledMinutes,
      };
    }
    return {};
  };

  useEffect(() => {
    setState((cur) => ({
      ...cur,
      day: profile?.appointmentTime
        ? moment(profile?.appointmentTime, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          )
        : undefined,
      hour: profile?.appointmentTime
        ? moment(profile?.appointmentTime, 'YYYY-MM-DD HH:mm:ss').format(
            'HH:mm'
          )
        : undefined,
    }));
  }, [profile?.appointmentTime]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    const min = moment().startOf('day');

    return current && current < min;
  };

  useEffect(() => {
    if (profile?.currentLevelSale?.id) {
      const value = profile?.currentLevelSale?.id;
      setState((cur) => ({ ...cur, loading: true, reasons: [] }));
      bodySubmit.current.levelSaleId = Number(value);
      getReasons(value.toString());
    }
  }, [profile?.currentLevelSale?.id]);

  const getReasons = async (id: string) => {
    const response = await dataManagerServices.getReasonsLevelSale(id);
    setState((cur) => ({
      ...cur,
      loading: false,
      reasons: response?.data ?? [],
    }));
  };

  const setDisableBtnUpdate = (disable: boolean) => {
    setState((cur) => ({
      ...cur,
      isDisabled: !!disable,
    }));
  };

  const handleChange = (options: any) => {
    console.log('options', options);
    bodySubmit.current.reasonId = undefined;
    if (options?.value) {
      bodySubmit.current.levelSaleId = Number(options?.value);
      getReasons(options?.value.toString());
      setDisableBtnUpdate(false);
      setState((cur) => ({
        ...cur,
        loading: true,
        reasons: [],
        levelSaleId: options?.value,
        levelSaleCode: options?.code,
        reasonId: undefined,
      }));
    } else {
      bodySubmit.current.levelSaleId = undefined;
      bodySubmit.current.appointmentTime = undefined;
      setState((cur) => ({
        ...cur,
        loading: false,
        reasons: [],
        errorDay: '',
        errorHour: '',
        day: undefined,
        hour: undefined,
        levelSaleId: undefined,
        levelSaleCode: undefined,
      }));
    }
  };

  const onSelectReason = (reasonId: number) => {
    bodySubmit.current.reasonId = reasonId;
    setState((cur) => ({
      ...cur,
      reasonId: reasonId,
    }));
    setDisableBtnUpdate(reasonId === profile?.levelSaleReason?.id);
  };

  const onChangeText = (e: any) => {
    bodySubmit.current.note = e.target.value ?? undefined;
    setState((cur) => ({ ...cur, note: e.target.value }));
    setDisableBtnUpdate(false);
  };

  const onChangeDay = (e: any) => {
    if (
      moment(e?._d)?.format('YYYY-MM-DD') ===
      moment(profile?.appointmentTime)?.format('YYYY-MM-DD')
    ) {
      setState((cur) => ({
        ...cur,
        isDisabled: true,
      }));
      return;
    }
    setState((cur) => ({
      ...cur,
      day: e?._d ? moment(e?._d)?.format('YYYY-MM-DD') : undefined,
      errorDay: '',
      isDisabled: false,
    }));
    setTimePickerOpen(true);
    timePickerRef?.current?.focus();
  };

  useEffect(() => {
    if (!state?.day && !state?.hour) {
      setState((cur) => ({
        ...cur,
        errorDay: '',
        errorHour: '',
      }));
    }
  }, [state?.day, state?.hour]);

  const onChangeHour = (e: any) => {
    if (
      moment(e?._d)?.format('HH:mm') ===
      moment(profile?.appointmentTime)?.format('HH:mm')
    ) {
      setState((cur) => ({
        ...cur,
        isDisabled: true,
      }));
      setTimePickerOpen(false);
      return;
    }
    setState((cur) => ({
      ...cur,
      hour: e?._d ? moment(e?._d)?.format('HH:mm') : undefined,
      errorHour: '',
      isDisabled: false,
    }));
    setTimePickerOpen(false);
  };

  const onBlurDatePicker = () => {
    if (isNullOrEmpty(state?.hour) && !isNullOrEmpty(state?.day)) {
      timePickerRef?.current?.focus();
      setTimePickerOpen(true);
    }
  };

  const updateDetailLevelSale = async (body: UpdateLevelSaleDividedBody) => {
    if (dataId) {
      setState((cur) => ({ ...cur, loading: true }));
      const response = await dataManagerServices.updateDetailLevelSale(
        dataId,
        body
      );
      if (response?.status === 200) {
        notification.success({ message: 'Cập nhật Level sale thành công' });
        getDataCaring();
        bodySubmit.current.note = undefined;
        setState((cur) => ({
          ...cur,
          loading: false,
          note: '',
          isDisabled: true,
        }));
        onSubmitSuccess();
      } else {
        notification.error({ message: response?.data?.message });
        setState((cur) => ({ ...cur, loading: false }));
      }
    }
  };

  const validateAppointmentTime = () => {
    if (state?.day && state?.hour) {
      bodySubmit.current.appointmentTime = state?.day + ` ${state?.hour}:00`;
      return true;
    } else {
      if (!state?.day && state?.hour) {
        setState((cur) => ({
          ...cur,
          errorDay: 'Vui lòng chọn ngày',
        }));
        return false;
      }
      if (state?.day && !state?.hour) {
        setState((cur) => ({
          ...cur,
          errorHour: 'Vui lòng chọn giờ',
        }));
        return false;
      }
      if (!state?.day && !state?.hour) {
        bodySubmit.current.appointmentTime = undefined;
        return true;
      }
    }
  };

  const handleSubmit = () => {
    if (state?.levelSaleCode === 'L3.2' || state?.levelSaleId === 126) {
      const result = validateAppointmentTime();
      console.log('result', state?.day + ` ${state?.hour}`);
      if (!result) {
        return;
      }
    }
    if (state?.day && state?.hour) {
      bodySubmit.current.appointmentTime = state?.day + ` ${state?.hour}:00`;
    }
    if (!bodySubmit.current.levelSaleId) {
      notification.error({ message: 'Vui lòng chọn Trạng thái chăm sóc' });
      return;
    }

    // case level sale hiện tại giống level sale ban đầu
    if (bodySubmit.current.levelSaleId === profile?.currentLevelSale?.id) {
      // case đổi lý do so với lý do ban đầu
      if (
        bodySubmit.current.reasonId &&
        bodySubmit.current.reasonId !== profile?.levelSaleReason?.id
      ) {
        updateDetailLevelSale(bodySubmit.current);
        return;
      }
      if (
        profile?.appointmentTime &&
        profile?.appointmentTime !== state?.day + ` ${state?.hour}:00`
      ) {
        updateDetailLevelSale(bodySubmit.current);
        return;
      }
      if (isNullOrEmpty(bodySubmit.current.note)) {
        notification.error({ message: 'Vui lòng nhập ghi chú' });
      } else {
        updateDetailLevelSale(bodySubmit.current);
      }
    } else {
      if (state.reasons?.length > 0) {
        if (bodySubmit.current.reasonId) {
          updateDetailLevelSale(bodySubmit.current);
        } else {
          notification.error({ message: 'Vui lòng chọn lý do' });
        }
      } else {
        updateDetailLevelSale(bodySubmit.current);
      }
    }
  };

  const isPaddingTop = (code: string): boolean => {
    return ['L1.1', 'L2.2', 'L3.2', 'L8.1'].includes(code);
  };

  useEffect(() => {
    if (profile) {
      setState((cur) => ({
        ...cur,
        levelSaleId: profile?.currentLevelSale?.id,
        reasonId: profile?.levelSaleReason?.id,
      }));

      bodySubmit.current.reasonId = profile?.levelSaleReason?.id;
      bodySubmit.current.levelSaleId = profile?.currentLevelSale?.id;
    }
  }, [profile]);

  return (
    <Spin spinning={state.loading}>
      <div className="block__header">
        <h2>CẬP NHẬT LEVEL SALE</h2>
      </div>
      <div
        className="block"
        style={{
          height: windowSize.height / 3,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <div className="interaction-history__main block__main">
          <Row gutter={24} className="mb-2">
            <Col span={7}>
              <p style={{ fontWeight: 600 }}>{`Trạng thái chăm sóc (*)`}</p>
            </Col>
            <Col span={17}>
              {customerStages.length > 0 && (
                <Select
                  allowClear
                  showSearch
                  defaultValue={
                    profile?.currentLevelSale?.id
                      ? profile?.currentLevelSale?.id
                      : undefined
                  }
                  optionFilterProp="children"
                  placeholder="Chọn trạng thái chăm sóc"
                  style={{ width: '100%' }}
                  notFoundContent="Không có dữ liệu"
                  onChange={(value, option: any) => {
                    handleChange(option);
                  }}
                  dropdownStyle={{ overflowY: 'hidden' }}
                  listHeight={500}
                  value={bodySubmit.current.levelSaleId}
                >
                  {customerStages?.map((item: SaleBaseData) => (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      code={item.code}
                      style={{ marginTop: isPaddingTop(item.code) ? 12 : 0 }}
                      disabled={item.code === 'L8.1'}
                    >
                      {`${item.code} - ${item.definition}`}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Col>
          </Row>
          {profile && (
            <Row gutter={24} className="mb-2">
              <Col span={7}>
                <p style={{ fontWeight: 600 }}>{`Lí do (*)`}</p>
              </Col>
              <Col span={17}>
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder="Chọn lí do"
                  style={{ width: '100%' }}
                  notFoundContent="Không có dữ liệu"
                  filterOption={(input, option) =>
                    (option?.label ?? '').includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  defaultValue={profile?.levelSaleReason?.id}
                  onChange={onSelectReason}
                  value={bodySubmit.current.reasonId}
                  options={state.reasons.map((item) => ({
                    label: item.content,
                    value: item.id,
                  }))}
                />
              </Col>
            </Row>
          )}
          {(state?.levelSaleCode === 'L3.2' || state?.levelSaleId === 126) && (
            <Row gutter={24} className="mb-2">
              <Col span={7}>
                <p className="font-semibold">Thời gian hẹn</p>
              </Col>
              <Col span={17}>
                <Row gutter={[0, 10]}>
                  <div>
                    <DatePicker
                      format={DATE_FORMAT}
                      style={{ marginRight: 10 }}
                      placeholder="Chọn ngày"
                      onChange={onChangeDay}
                      disabledDate={disabledDate}
                      defaultValue={
                        profile?.appointmentTime
                          ? moment(
                              profile?.appointmentTime,
                              'YYYY-MM-DD HH:mm:ss'
                            )
                          : undefined
                      }
                      onBlur={onBlurDatePicker}
                    />
                    <div className="mt-1 text-red-600">{state?.errorDay}</div>
                  </div>
                  <div>
                    <TimePicker
                      disabledTime={disabledTime}
                      open={timePickerOpen}
                      onClick={setTimePickerOpen?.bind(null, true)}
                      ref={timePickerRef}
                      showSecond={false}
                      showNow={true}
                      format={'HH:mm'}
                      placeholder="Chọn giờ"
                      onChange={onChangeHour}
                      defaultValue={
                        profile?.appointmentTime
                          ? moment(
                              profile?.appointmentTime,
                              'YYYY-MM-DD HH:mm:ss'
                            )
                          : undefined
                      }
                      minuteStep={5}
                      onBlur={setTimePickerOpen?.bind(null, false)}
                    />
                    <div className="mt-1 text-red-600">{state?.errorHour}</div>
                  </div>
                </Row>
              </Col>
            </Row>
          )}
          <Row gutter={24} className="mb-2">
            <Col span={7}>
              <p style={{ fontWeight: 600 }}>Ghi chú</p>
            </Col>
            <Col span={17}>
              <Input.TextArea
                placeholder="Nhập ghi chú "
                style={{ height: 98 }}
                value={state.note}
                onChange={onChangeText}
                maxLength={500}
              />
            </Col>
          </Row>
        </div>
        <div className="flex justify-center">
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={state.isDisabled}
          >
            Cập nhật
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default memo(UpdateLevelSale);
