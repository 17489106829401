import {
  LoadingOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Upload, message, Avatar, Button } from 'antd';
import React, { useState } from 'react';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import type { UploadChangeParam } from 'antd/es/upload';
import userServices from 'src/services/users.service';
import { getLinkAvatar } from 'src/utils';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import { RESPONSE_CODE } from 'src/utils/constants';
import AppContext from 'src/context/AppContext';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';

const UserProfile: React.FC = () => {
  const { appState, dispatch }: ContextType = React.useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('Bạn chí có thể tải lên file định dạng .JPG, .PNG, .JPEG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      return;
    }
  };

  const handlePostImage = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const response = await userServices.uploadImg(file);
      if (response?.status === RESPONSE_CODE.SUCCESS) {
        dispatch({
          type: APP_CONTEXT_ACTIONS.UPDATE_PROFILE,
          payload: {
            ...appState?.profile,
            avatar: response?.data,
          },
        });
        message.success('Cập nhật Avatar thành công!');
        onSuccess('Ok');
      } else {
        message.error(response?.data?.message ?? 'Có lỗi xảy ra!!!');
        onError({ err: response?.data?.message });
      }
    } catch (err: any) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err: error });
      message.error(err?.response?.data?.message ?? 'Có lỗi xảy ra!!!');
    }
  };

  return (
    <div className="p-4 flex flex-col">
      <p className="text-bold text-lg">Trang cá nhân</p>
      <div className="flex flex-col justify-center w-48 mt-4 ml-4">
        {isNullOrEmpty(appState?.profile?.avatar) ? (
          <Avatar size={150} icon={<UserOutlined />} />
        ) : (
          <Avatar src={getLinkAvatar(appState?.profile?.avatar)} size={150} />
        )}
        <Upload
          name="avatar"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          customRequest={handlePostImage}
        >
          <Button
            icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
            className="mt-2"
          >
            Thay đổi Avatar
          </Button>
        </Upload>

        <p className="font-bold mt-3">Name: {appState?.profile?.fullName}</p>
        <p className="font-bold mt-1">Role: {appState?.profile?.roles?.[0]}</p>
      </div>
    </div>
  );
};

export default UserProfile;
