import React, { useEffect, useState } from 'react';
import { Space, Table, message } from 'antd';
import saveAs from 'file-saver';
import moment, { Moment } from 'moment';

import { modifyArrTreeSelect } from 'src/utils/stringUtils';
import reportsServices from 'src/services/reports.service';
import registerPackageServices from 'src/services/registerPackage.service';
import Filters from './components/Filters';
import levelSaleService from 'src/services/levelSale.service';
import { convertToListChildLevelSale, projectId, projectName } from 'src/utils';
import { ItemColumn, TabColumn } from '../ReportsRatioTotal/components';
import { useQuery } from 'src/utils/customHooks';
import SwitchNumberRatio from 'src/components/SwitchNumberRatio';

import { filterStyles } from 'src/styles';
import { FirstTab } from '../ReportsRatioTotal/components/FirstTab';
import { Helmet } from 'react-helmet';
import './style.scss';

export type State = {
  isLoading: boolean;
  isLoadingExport: boolean;
  staff: any[];
  reportStream: any[];
  filters: any;
  levelsSale: LevelSaleItem[];
  isSwitchNumber: boolean;
  isSwitchRatio: boolean;
};

const ReportsRatioByDay: React.FC = () => {
  const query = useQuery();
  const queryAssignedDateFrom = query.get('assignedDateFrom');
  const queryCurrentPackages = query.get('currentPackages');

  const [state, setState] = useState<State>({
    isLoading: false,
    isLoadingExport: false,
    staff: [],
    reportStream: [],
    filters: {
      assignedDateFrom: queryAssignedDateFrom
        ? moment(queryAssignedDateFrom).startOf('day').format('X') * 1000
        : Date.now(),
      currentPackages: queryCurrentPackages?.includes(',')
        ? queryCurrentPackages.split(',')
        : [queryCurrentPackages],
    },
    levelsSale: [],
    isSwitchNumber: true,
    isSwitchRatio: true,
  });

  const onSearch = () => {
    setState((cur: State) => ({
      ...cur,
      isLoading: true,
    }));
    getDataReportStream();
  };

  const onExport = () => {
    setState((cur: State) => ({
      ...cur,
      isLoadingExport: true,
    }));
    exportFile();
  };

  const exportFile = async () => {
    const resp = await reportsServices.exportReportStream(state.filters);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Bo_ti_le_luong_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    } else {
      const reader = new FileReader();
      reader.onload = function (event: any) {
        message.error(event?.target?.result ?? 'Có lỗi xảy ra!!');
      };
      reader.readAsText(data);
    }
    setState((cur: State) => ({
      ...cur,
      isLoadingExport: false,
    }));
  };

  const getDataFilters = async () => {
    const res = await Promise.all([
      reportsServices.getListStaff(),
      registerPackageServices.getListPackage(),
      levelSaleService.getLevels(),
    ]);
    setState((cur: State) => ({
      ...cur,
      isLoading: false,
      staff: modifyArrTreeSelect(res?.[0]?.data),
      listPackage: res?.[1]?.data,
      levelsSale: convertToListChildLevelSale(res?.[2]?.data),
    }));
  };

  const getDataReportStream = async () => {
    const res = await reportsServices.getReportStream(state.filters);
    if (res?.status === 200) {
      const data = res.data;
      setState((cur: State) => ({
        ...cur,
        isLoading: false,
        reportStream: data,
      }));
    }
  };

  const handleChangePackage = (values: string[]) => {
    setState((cur: State) => ({
      ...cur,
      filters: {
        ...cur.filters,
        currentPackages: values.toString(),
      },
    }));
  };
  const handleChangeAssignedDateFrom = (value?: Moment | null) => {
    setState((cur: State) => ({
      ...cur,
      filters: {
        ...cur.filters,
        assignedDateFrom: value
          ? moment(value).startOf('date').format('X') * 1000
          : undefined,
      },
    }));
  };

  const convertListSaleMan = (arr: string[]): string[] => {
    let newArr: string[] = [];
    arr.forEach((item) => {
      if (item.includes('team_sale_')) {
        state.staff
          .find(
            (staff: SaleManInfo & { value: string; title: string }) =>
              staff.value === item
          )
          ?.children?.forEach((child: any) => {
            newArr.push(child.value);
          });
      } else {
        newArr.push(item);
      }
    });

    return newArr;
  };

  const onChangeStaff = (values: string[]) => {
    setState((cur: State) => ({
      ...cur,
      filters: {
        ...cur.filters,
        staffs: convertListSaleMan(values).toString(),
      },
    }));
  };

  const onSwitchNumber = (checked: boolean) => {
    if (!checked && !state.isSwitchRatio) return;
    setState((cur) => ({
      ...cur,
      isSwitchNumber: checked,
    }));
  };

  const onSwitchRatio = (checked: boolean) => {
    if (!checked && !state.isSwitchNumber) return;
    setState((cur) => ({
      ...cur,
      isSwitchRatio: checked,
    }));
  };

  useEffect(() => {
    getDataFilters();
    getDataReportStream();
    
  }, []);

  const columns: any = [
    {
      title: () => <FirstTab />,
      dataIndex: 'day',
      key: 'day',
      width: '5%',
      render: (cell: any) => cell,
    },
    {
      title: () => <TabColumn title={'L0'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l0Total',
      key: 'l0Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn
          index={index}
          total={cell}
          percent={''}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L1'} levels={state.levelsSale} />,
      dataIndex: 'l1Total',
      key: 'l1Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn
          index={index}
          total={cell}
          percent={''}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L1.2'} levels={state.levelsSale} />,
      dataIndex: 'l1_2Total',
      key: 'l1_2Total',
      width: '5%',
      render: (
        _: string,
        { l1_2Total, l1_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_2Total}
          percent={`${l1_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L1.3'} levels={state.levelsSale} />,
      dataIndex: 'l1_3Total',
      key: 'l1_3Total',
      width: '5%',
      render: (
        _: string,
        { l1_3Total, l1_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_3Total}
          percent={`${l1_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2'} levels={state.levelsSale} />,
      dataIndex: 'l2Total',
      key: 'l2Total',
      width: '5%',
      className: 'bg-gray',
      render: (_: string, { l2Total, l2TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l2Total}
          percent={`${l2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2.2'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_2Total',
      key: 'l2_2Total',
      width: '5%',
      render: (
        _: string,
        { l2_2Total, l2_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_2Total}
          percent={`${l2_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_3Total',
      key: 'l2_3Total',
      width: '5%',
      render: (
        _: string,
        { l2_3Total, l2_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_3Total}
          percent={`${l2_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3'} levels={state.levelsSale} />,
      dataIndex: 'l3Total',
      key: 'l3Total',
      width: '5%',
      render: (_: string, { l3Total, l3TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l3Total}
          percent={`${l3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3.2'} levels={state.levelsSale} />,
      dataIndex: 'l3_2Total',
      key: 'l3_2Total',
      width: '5%',
      render: (
        _: string,
        { l3_2Total, l3_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_2Total}
          percent={`${l3_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3.3'} levels={state.levelsSale} />,
      dataIndex: 'l3_3Total',
      key: 'l3_3Total',
      width: '5%',
      render: (
        _: string,
        { l3_3Total, l3_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_3Total}
          percent={`${l3_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8'} levels={state.levelsSale} />,
      dataIndex: 'l8Total',
      key: 'l8Total',
      className: 'bg-gray',
      width: '5%',
      render: (_: string, { l8Total, l8TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l8Total}
          percent={`${l8TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8.1'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_1Total',
      key: 'l8_1Total',
      width: '5%',
      render: (
        _: string,
        { l8_1Total, l8_1TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_1Total}
          percent={`${l8_1TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_3Total',
      key: 'l8_3Total',
      width: '5%',
      render: (
        _: string,
        { l8_3Total, l8_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_3Total}
          percent={`${l8_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
  ];

  return (
    <div id="stream-reports">
      <Helmet>
        <title>Bộ tỉ lệ luồng</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <Space size={16} style={filterStyles}>
        <Filters
          state={state}
          defaultSelectedDate={state.filters.assignedDateFrom}
          defaultSelectedPackage={state.filters.currentPackages}
          handleChangePackage={handleChangePackage}
          handleChangeAssignedDateFrom={handleChangeAssignedDateFrom}
          onChangeStaff={onChangeStaff}
          onSearch={onSearch}
          onExport={onExport}
        />
        <SwitchNumberRatio
          isSwitchNumber={state.isSwitchNumber}
          isSwitchRatio={state.isSwitchRatio}
          onSwitchNumber={onSwitchNumber}
          onSwitchRatio={onSwitchRatio}
        />
      </Space>

      <Table
        rowKey="day"
        className={'table-reports-total'}
        columns={columns}
        pagination={false}
        locale={{ emptyText: 'Không có kết quả phù hợp' }}
        dataSource={state.reportStream}
      />
    </div>
  );
};

export default ReportsRatioByDay;
