import { useUseProduct } from 'src/hooks/Product';
import { Filter } from './Filter';
import { Table } from 'antd';
import getColumnsConfig from './ColumnsTable';
import { useWindowSize } from 'src/utils/customHooks';
import { GLOBAL_HEIGHT, PAGE_SIZE_LIST } from 'src/utils/constants';
import { ModalCreatePackage } from './CreatePackage';
import { ModalDetailsPackage } from './DetailsPackage';

export const ListProduct = () => {
  const {
    state,
    openModalCreatePackage,
    onPageChange,
    handleDetails,
    createModalRef,
    detailsModalRef,
    handleEdit,
    createPackage,
    updatePackage,
    onChangeStatuspackage,
    handleChangePackage,
  } = useUseProduct();
  const columns = getColumnsConfig(
    handleDetails,
    Number(state?.filter?.page) + 1,
    Number(state?.filter?.size)
  );
  const windowSize = useWindowSize();
  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 5;
  
  return (
    <div>
      <Filter
        onCreatePackge={openModalCreatePackage}
        onChangeStatus={onChangeStatuspackage}
        handleChangePackage={handleChangePackage}
        packages={state?.listPackage}
      />
      <ModalCreatePackage
        ref={createModalRef}
        isEdit={state?.isEdit}
        createPackage={createPackage}
        updatePackage={updatePackage}
      />
      <ModalDetailsPackage ref={detailsModalRef} hanleEdit={handleEdit} />
      <Table
        rowKey={'id'}
        className="ml-3 mr-3"
        dataSource={state?.listPackage}
        columns={columns}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          current: Number(state?.filter?.page) + 1,
          total: Number(state?.filter?.total),
          defaultPageSize: Number(state?.filter?.size),
          pageSizeOptions: PAGE_SIZE_LIST,
          onChange: onPageChange,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{ x: 1000, y: tableHeight }}
      />
    </div>
  );
};
