import APIService from '../utils/apiServices';

class SearchServices extends APIService {
  async search(query) {
    return await this.request(
      'GET',
      `search-user-info/search${query || ''}`,
      {}
    );
  }

  searchARPU(msisdn) {
    return this.request('GET', `search-user-info/search-arpu?msisdn=${msisdn}`);
  }

  async b9Search(query) {
    return await this.request(
      'GET',
      `search-user-info/search-b9${query || ''}`,
      {}
    );
  }

  async accountInfoSearch(query) {
    return await this.request(
      'GET',
      `search-user-info/search-account-info${query || ''}`,
      {}
    );
  }
  async searchDataVip(sđt) {
    return await this.request(
      'GET',
      `search-user-info/is-data-vip?msisdn=${sđt}`,
      {}
    );
  }
}
const service = new SearchServices();
export default service;
