import { notification } from 'antd';
import { Reducer, useEffect, useReducer } from 'react';
import { SessionStorage } from 'src/utils/SessionStorage';
import { isNullOrEmpty, mapCallErrorCode } from 'src/utils/stringUtils';
import callCenterService from '../callCenter.service';
import careSoftServices from '../caresoft.service';
import LocalStoreInstance from 'src/utils/localStorage';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import * as Sentry from '@sentry/react';

export const CARE_SOFT_ACTION = {
  initComplete: 'initComplete',
  makeACall: 'makeACall',
  calling: 'calling',
  endcall: 'endcall',
  callout: 'callout',
  calloutError: 'calloutError',
  holdcall: 'holdcall',
  mute: 'mute',
  updateHoldCall: 'updateHoldCall',
  callRinging: 'callRinging',
  onAcceptCall: 'onAcceptCall',
  acceptCall: 'acceptCall',
  showMissedModal: 'showMissedModal',
};

type Action = any;

export const initialState: CareSoftState = {
  initSuccess: false,
  isLoading: false,
  callStatus: 'ending',
  callError: undefined,
  callId: undefined,
  holdCall: false,
  mute: false,
  phoneCallRinging: undefined,
  isCallingFromCustomer: false,
  isShowMissedModal: false,
  missedCalls: [],
};

export function useCareSoftHook(
  ipPhone?: string
): [state: any, dispatch: React.Dispatch<any>] {
  const setLocalStorageIsCalling = () => {
    LocalStoreInstance.getInstance().save(
      LOCAL_STORAGE_VALUE.callStatus,
      'calling'
    );
  };
  const setLocalStorageIsEnding = () => {
    const callStatus = LocalStoreInstance.getInstance().read(
      LOCAL_STORAGE_VALUE.callStatus
    );
    if (callStatus === 'calling') {
      LocalStoreInstance.getInstance().save(
        LOCAL_STORAGE_VALUE.callStatus,
        'ending'
      );
    }
  };

  window.addEventListener('beforeunload', function () {
    setLocalStorageIsEnding();
  });

  const reducer: Reducer<CareSoftState, Action> = (
    state: CareSoftState,
    action: any
  ) => {
    console.log('useCareSoftHook', action.type);
    switch (action.type) {
      case CARE_SOFT_ACTION.initComplete: {
        return {
          ...state,
          initSuccess: true,
        };
      }
      case CARE_SOFT_ACTION.calling: {
        setLocalStorageIsCalling();
        callCenterService.countSaleActiveCall();
        return {
          ...state,
          callStatus: 'calling',
        };
      }
      case CARE_SOFT_ACTION.makeACall: {
        careSoftServices.makeACall(action.phone);
        return {
          ...state,
          isLoading: true,
          callId: undefined,
        };
      }
      case CARE_SOFT_ACTION.callout: {
        return {
          ...state,
          isLoading: false,
          callStatus: 'holding',
        };
      }
      case CARE_SOFT_ACTION.endcall: {
        setLocalStorageIsEnding();
        callCenterService.countSaleInActiveCall();
        return {
          ...state,
          phoneCallRinging: undefined,
          isCallingFromCustomer: false,
          holdCall: false,
          mute: false,
          callStatus: 'ending',
        };
      }
      case CARE_SOFT_ACTION.calloutError: {
        const { errorCode, sipCode } = action;
        const errMessage = mapCallErrorCode(action.errorCode, action.sipCode);
        notification.warning({
          message: errMessage,
        });
        return {
          ...state,
          callStatus: 'ending',
          phoneCallRinging: undefined,
          callId: undefined,
          isLoading: false,
          callError: {
            code: errorCode,
            sipcode: sipCode,
            errMessage,
          },
        };
      }
      case CARE_SOFT_ACTION.holdcall: {
        careSoftServices.holdCall();
        return state;
      }
      case CARE_SOFT_ACTION.updateHoldCall: {
        return {
          ...state,
          holdCall: action.status,
        };
      }
      case CARE_SOFT_ACTION.mute: {
        careSoftServices.muteCall();
        return state;
      }
      case CARE_SOFT_ACTION.callRinging: {
        return {
          ...state,
          phoneCallRinging: action.phone,
          callId: action.callId,
        };
      }
      case CARE_SOFT_ACTION.onAcceptCall: {
        careSoftServices.onAcceptCall();
        callCenterService.countSaleActiveCall();
        return {
          ...state,
          phoneCallRinging: undefined,
        };
      }
      case CARE_SOFT_ACTION.acceptCall: {
        return {
          ...state,
          callStatus: action.isCallingFromCustomer ? 'calling' : 'holding',
          isCallingFromCustomer: action.isCallingFromCustomer,
        };
      }
      case CARE_SOFT_ACTION.showMissedModal: {
        return {
          ...state,
          isShowMissedModal: action.isShowMissedModal,
          missedCalls: action?.missedCalls ?? [],
        };
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const connectCareSoft = (ipPhone: string) => {
    careSoftServices.init(ipPhone);
  };

  // Khởi tạo hoàn thành
  const csInitComplete = () => {
    dispatch({ type: CARE_SOFT_ACTION.initComplete });
  };

  // Khởi tạo bị lỗi
  const csInitError = (event: any) => {
    console.log('csInitError ===' + event?.detail?.error);
  };

  // Bắt đầu gọi tới khách hàng
  const csCurrentCall = (event: any) => {
    const callId = event?.detail?.callId;
    if (callId) {
      SessionStorage.currentCallId = callId;
    }
    dispatch({ type: CARE_SOFT_ACTION.callout });
  };

  // Cuộc gọi tới khách hàng bị lỗi
  const showCalloutError = (event: any) => {
    const { errorCode, sipCode } = event?.detail;
    dispatch({ type: CARE_SOFT_ACTION.calloutError, errorCode, sipCode });
  };

  // cuộc gọi ra được khách hàng trả lời
  const csCustomerAccept = () => {
    Sentry.setExtra('csCustomerAccept', window?.csVoice);
    console.log('csCustomerAccept ===' + window?.csVoice);
    dispatch({ type: CARE_SOFT_ACTION.calling });
  };

  // kết thúc cuộc gọi ra/vào
  const csEndCall = async () => {
    console.log('csEndCall', SessionStorage.currentCallId);

    Sentry.withScope(function (scope) {
      scope.setExtra('endCall', window?.csVoice);
      scope.setLevel('fatal');
      Sentry.captureMessage('csEndCall');
    });

    // Sentry.captureException('csEndCall  ')
    if (SessionStorage.currentCallId) {
      setTimeout(async () => {
        // await callCenterService.createCallHistory(
        //   SessionStorage.currentCallId as string
        // );
        await getMissedCalls();
        dispatch({ type: CARE_SOFT_ACTION.endcall });
      }, 1000);
    }
  };

  // Cập nhật trạng thái hold/unhold
  const csHoldCall = (event: any) => {
    console.log('csHoldCall', event?.detail?.status);
    dispatch({
      type: CARE_SOFT_ACTION.updateHoldCall,
      status: event?.detail?.status,
    });
  };

  // đổ chuông trình duyệt của agent khi gọi vào
  // đổ chuông tới khách hàng khi gọi ra
  const csCallRinging = (event: any) => {
    const callId = window?.csVoice?.callInfo?.callId;
    SessionStorage.currentCallId = callId;
    console.log('csCallRinging', callId);
    const isCallToCustomer = window?.csVoice?.callInfo?.callDirection === 2;
    dispatch({
      type: CARE_SOFT_ACTION.callRinging,
      phone: isCallToCustomer ? undefined : event?.detail?.phone,
      callId: callId,
    });
  };

  const getMissedCalls = async () => {
    console.log('getMissedCalls', state);
    const response = await callCenterService.missedCalls();
    const calls = response.data;
    dispatch({
      type: CARE_SOFT_ACTION.showMissedModal,
      isShowMissedModal: calls.length > 0,
      missedCalls: calls,
    });
  };

  // cuộc gọi vào được agent trả lời
  const csAcceptCall = () => {
    Sentry.setExtra('csAcceptCall', window?.csVoice);
    const isCallingFromCustomer =
      window?.csVoice?.callInfo?.callDirection === 1;
    dispatch({
      type: CARE_SOFT_ACTION.acceptCall,
      isCallingFromCustomer,
    });
  };

  useEffect(() => {
    if (!isNullOrEmpty(ipPhone)) {
      connectCareSoft(ipPhone as string);
    }
  }, [ipPhone]);

  useEffect(() => {
    window.addEventListener('unload', careSoftServices.disconnect);
    window.addEventListener('initComplete', csInitComplete);
    window.addEventListener('initError', csInitError);
    window.addEventListener('callout', csCurrentCall);
    window.addEventListener('calloutError', showCalloutError);
    window.addEventListener('calloutAccept', csCustomerAccept);
    window.addEventListener('endcall', csEndCall);
    window.addEventListener('holdcall', csHoldCall);
    window.addEventListener('callRinging', csCallRinging);
    window.addEventListener('acceptCall', csAcceptCall);
    return () => {
      setLocalStorageIsEnding();
      window.removeEventListener('unload', careSoftServices.disconnect);
      window.removeEventListener('initComplete', csInitComplete);
      window.removeEventListener('initError', csInitError);
      window.removeEventListener('callout', csCurrentCall);
      window.removeEventListener('calloutError', showCalloutError);
      window.removeEventListener('calloutAccept', csCustomerAccept);
      window.removeEventListener('endcall', csEndCall);
      window.removeEventListener('holdcall', csHoldCall);
      window.removeEventListener('callRinging', csCallRinging);
      window.removeEventListener('acceptCall', csAcceptCall);
    };
  }, []);

  return [state, dispatch];
}
