import { Pagination, Space, Spin, Table } from 'antd';
import { saveAs } from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import SwitchNumberRatio from 'src/components/SwitchNumberRatio';
import levelSaleService from 'src/services/levelSale.service';
import registerPackageServices from 'src/services/registerPackage.service';
import reportsServices from 'src/services/reports.service';
import { filterStyles, paginationStyles } from 'src/styles';
import { convertToListChildLevelSale, projectName } from 'src/utils';
import {
  DATE_FORMAT,
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
  ROUTERS,
} from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { isNullOrEmpty, modifyArrTreeSelect } from 'src/utils/stringUtils';
import { convertTimeStamp } from 'src/utils/time';
import { getValuesNodeTree } from 'src/utils/treeSelect';
import { ItemColumn, TabColumn } from './components';
import Actions from './components/Actions';
import Filters from './components/Filters';
import { FirstTab } from './components/FirstTab';
import './style.scss';

type FilterParams = {
  staffs: any;
  currentPackages: any;
  assignedDateFrom: number;
  assignedDateTo: number;
  page: number;
  size: number;
};

export type State = {
  loading: boolean;
  staff: any;
  selectedStaff: any;
  listPackage: any;
  isLoadingReportsTotal: boolean;
  isLoadingExport: boolean;
  reportsTotal: any[];
  filters: FilterParams;
  levelsSale: LevelSaleItem[];
  metadata?: MetadataInterface;
  isSwitchNumber: boolean;
  isSwitchRatio: boolean;
};

const RevenueReportsTotal: React.FC = () => {
  const defaultParams: FilterParams = {
    staffs: null,
    currentPackages: null,
    assignedDateFrom: convertTimeStamp(
      moment().startOf('month').startOf('day')
    ),
    assignedDateTo: convertTimeStamp(moment().endOf('day')),
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  };

  const windowSize = useWindowSize();

  const [state, setState] = useState<State>({
    loading: true,
    staff: [],
    selectedStaff: undefined,
    reportsTotal: [],
    listPackage: [],
    isLoadingReportsTotal: true,
    isLoadingExport: false,
    filters: defaultParams,
    levelsSale: [],
    metadata: undefined,
    isSwitchNumber: true,
    isSwitchRatio: true,
  });

  const columns: any = [
    {
      title: <FirstTab />,
      dataIndex: 'day',
      key: 'day',
      width: '5%',
      render: (cell: any, _: any, index: number) =>
        index ? (
          moment(cell).format(DATE_FORMAT)
        ) : (
          <p style={{ fontSize: 15, marginBottom: 0 }}>Tổng</p>
        ),
    },
    {
      title: <TabColumn title={'L0'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l0Total',
      key: 'l0Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn index={index} total={cell} percent={''} />
      ),
    },
    {
      title: <TabColumn title={'L1'} levels={state.levelsSale} />,
      dataIndex: 'l1Total',
      key: 'l1Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn index={index} total={cell} percent={''} />
      ),
    },
    {
      title: <TabColumn title={'L1.2'} levels={state.levelsSale} />,
      dataIndex: 'l1_2Total',
      key: 'l1_2Total',
      width: '5%',
      render: (
        _: string,
        { l1_2Total, l1_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_2Total}
          percent={`${l1_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L1.3'} levels={state.levelsSale} />,
      dataIndex: 'l1_3Total',
      key: 'l1_3Total',
      width: '5%',
      render: (
        _: string,
        { l1_3Total, l1_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_3Total}
          percent={`${l1_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L2'} levels={state.levelsSale} />,
      dataIndex: 'l2Total',
      key: 'l2Total',
      width: '5%',
      className: 'bg-gray',
      render: (_: string, { l2Total, l2TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l2Total}
          percent={`${l2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L2.2'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_2Total',
      key: 'l2_2Total',
      width: '5%',
      render: (
        _: string,
        { l2_2Total, l2_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_2Total}
          percent={`${l2_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L2.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_3Total',
      key: 'l2_3Total',
      width: '5%',
      render: (
        _: string,
        { l2_3Total, l2_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_3Total}
          percent={`${l2_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L3'} levels={state.levelsSale} />,
      dataIndex: 'l3Total',
      key: 'l3Total',
      width: '5%',
      render: (_: string, { l3Total, l3TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l3Total}
          percent={`${l3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L3.2'} levels={state.levelsSale} />,
      dataIndex: 'l3_2Total',
      key: 'l3_2Total',
      width: '5%',
      render: (
        _: string,
        { l3_2Total, l3_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_2Total}
          percent={`${l3_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L3.3'} levels={state.levelsSale} />,
      dataIndex: 'l3_3Total',
      key: 'l3_3Total',
      width: '5%',
      render: (
        _: string,
        { l3_3Total, l3_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_3Total}
          percent={`${l3_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L8'} levels={state.levelsSale} />,
      dataIndex: 'l8Total',
      key: 'l8Total',
      className: 'bg-gray',
      width: '5%',
      render: (_: string, { l8Total, l8TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l8Total}
          percent={`${l8TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L8.1'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_1Total',
      key: 'l8_1Total',
      width: '5%',
      render: (
        _: string,
        { l8_1Total, l8_1TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_1Total}
          percent={`${l8_1TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: <TabColumn title={'L8.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_3Total',
      key: 'l8_3Total',
      width: '5%',
      render: (
        _: string,
        { l8_3Total, l8_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_3Total}
          percent={`${l8_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
  ];

  const fetchDataFilters = async () => {
    const response = await Promise.all([
      reportsServices.getListStaff(),
      registerPackageServices.getListPackage(),
      levelSaleService.getLevels(),
    ]);

    setState((cur: State) => ({
      ...cur,
      loading: false,
      staff: modifyArrTreeSelect(response?.[0]?.data),
      listPackage: response?.[1]?.data,
      levelsSale: convertToListChildLevelSale(response?.[2]?.data),
    }));
  };

  const handleChangePackage = (values: string[]) => {
    const currentPackages = values.toString();
    setState((cur) => ({
      ...cur,
      filters: { ...cur.filters, currentPackages },
    }));
  };

  const handleChangeAssignedDate = (dates: any) => {
    const startDate = dates?.[0]?.startOf('day')?.format();
    const endDate = dates?.[1]?.endOf('day')?.format();
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        assignedDateFrom: startDate
          ? convertTimeStamp(moment(startDate))
          : startDate,
        assignedDateTo: endDate ? convertTimeStamp(moment(endDate)) : endDate,
      },
    }));
  };

  const handleChangeStaff = (values: any) => {
    const staffs = getValuesNodeTree(state?.staff, values);
    setState((cur) => ({
      ...cur,
      selectedStaff: values,
      filters: {
        ...cur.filters,
        staffs: staffs.toString(),
      },
    }));
  };

  const handleSearch = () => {
    setState((cur) => ({
      ...cur,
      loading: true,
      isLoadingReportsTotal: true,
      filters: {
        ...cur.filters,
      },
    }));
  };

  const handleExport = async () => {
    setState((cur) => ({
      ...cur,
      isLoadingExport: true,
    }));
    const resp = await reportsServices.exportReportsTotal(state.filters);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `CRM_Bo_ti_le_tong_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
    setState((cur) => ({
      ...cur,
      isLoadingExport: false,
    }));
  };

  const onSearch = async () => {
    const response = await Promise.all([
      reportsServices.getReportsTotal(state.filters),
      reportsServices.getReportsTotalAll(state.filters),
    ]);
    const reportsTotalData = response?.[0]?.data;
    const reportsTotalAllData = response?.[1]?.data;
    const reportsTotal = [
      {
        ...reportsTotalAllData,
        l0Total: reportsTotalAllData?.l0TotalForSum ?? 0,
        l1Total: reportsTotalAllData?.l1TotalForSum ?? 0,
        l1_2Total: reportsTotalAllData?.l1_2TotalForSum ?? 0,
        l1_2TotalPercent: reportsTotalAllData?.l1_2TotalPercentForSum ?? 0,
        l1_3Total: reportsTotalAllData?.l1_3TotalForSum ?? 0,
        l1_3TotalPercent: reportsTotalAllData?.l1_3TotalPercentForSum ?? 0,
        l2Total: reportsTotalAllData?.l2TotalForSum ?? 0,
        l2TotalPercent: reportsTotalAllData?.l2TotalPercentForSum ?? 0,
        l2_2Total: reportsTotalAllData?.l2_2TotalForSum ?? 0,
        l2_2TotalPercent: reportsTotalAllData?.l2_2TotalPercentForSum ?? 0,
        l2_3Total: reportsTotalAllData?.l2_3TotalForSum ?? 0,
        l2_3TotalPercent: reportsTotalAllData?.l2_3TotalPercentForSum ?? 0,
        l3Total: reportsTotalAllData?.l3TotalForSum ?? 0,
        l3TotalPercent: reportsTotalAllData?.l3TotalPercentForSum ?? 0,
        l3_2Total: reportsTotalAllData?.l3_2TotalForSum ?? 0,
        l3_2TotalPercent: reportsTotalAllData?.l3_2TotalPercentForSum ?? 0,
        l3_3Total: reportsTotalAllData?.l3_3TotalForSum ?? 0,
        l3_3TotalPercent: reportsTotalAllData?.l3_3TotalPercentForSum ?? 0,
        l8Total: reportsTotalAllData?.l8TotalForSum ?? 0,
        l8TotalPercent: reportsTotalAllData?.l8TotalPercentForSum ?? 0,
        l8_1Total: reportsTotalAllData?.l8_1TotalForSum ?? 0,
        l8_1TotalPercent: reportsTotalAllData?.l8_1TotalPercentForSum ?? 0,
        l8_3Total: reportsTotalAllData?.l8_3TotalForSum ?? 0,
        l8_3TotalPercent: reportsTotalAllData?.l8_3TotalPercentForSum ?? 0,
      },
      ...reportsTotalData,
    ];

    const { headers } = response?.[0];
    setState((cur) => ({
      ...cur,
      reportsTotal,
      loading: false,
      isLoadingReportsTotal: false,
      metadata: {
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      },
    }));
  };

  const onSwitchNumber = (checked: boolean) => {
    if (!checked && !state.isSwitchRatio) return;
    setState((cur) => ({
      ...cur,
      isSwitchNumber: checked,
    }));
  };

  const onSwitchRatio = (checked: boolean) => {
    if (!checked && !state.isSwitchNumber) return;
    setState((cur) => ({
      ...cur,
      isSwitchRatio: checked,
    }));
  };

  useEffect(() => {
    fetchDataFilters();
  }, []);

  useEffect(() => {
    if (state.isLoadingReportsTotal) {
      onSearch();
    }
    
  }, [state.isLoadingReportsTotal]);

  const onShowSizeChange = (page: number, pageSize: number) => {
    const newPage = page - 1 > 0 ? page - 1 : 0;
    const newSize = pageSize;
    setState((cur: State) => ({
      ...cur,
      isLoadingReportsTotal: true,
      filters: {
        ...cur.filters,
        page: newPage,
        size: newSize,
      },
    }));
  };

  const onRowClick = (record: any) => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onClick: (event: any) => {
        if (!isNullOrEmpty(record.day)) {
          const path = `${ROUTERS.ReportsRatioByDay.path}?assignedDateFrom=${
            record.day
          }${
            state.filters.currentPackages
              ? `&currentPackages=${state.filters.currentPackages}`
              : ''
          }`;
          window.open(path);
        }
      },
    };
  };

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 10;

  return (
    <div id="revenue-reports-total">
      <Helmet>
        <title>Bộ tỉ lệ tổng</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <Spin
        spinning={state.isLoadingReportsTotal || state.loading}
        tip={'Loading...'}
      >
        <Space size={16} style={filterStyles}>
          <Filters
            state={state}
            handleChangePackage={handleChangePackage}
            handleChangeAssignedDate={handleChangeAssignedDate}
            handleChangeStaff={handleChangeStaff}
          />
          <Actions
            isLoadingExport={state?.isLoadingExport}
            onSearch={handleSearch}
            handleExport={handleExport}
          />
          <SwitchNumberRatio
            isSwitchNumber={state.isSwitchNumber}
            isSwitchRatio={state.isSwitchRatio}
            onSwitchNumber={onSwitchNumber}
            onSwitchRatio={onSwitchRatio}
          />
        </Space>
        <Table
          className={'table-reports-total'}
          rowKey="day"
          columns={columns}
          pagination={false}
          dataSource={state.reportsTotal}
          scroll={{ y: tableHeight }}
          onRow={onRowClick}
        />
        <Pagination
          showSizeChanger
          size={'small'}
          style={paginationStyles}
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          total={state.metadata?.total}
          current={state.filters.page + 1}
          defaultPageSize={state.filters.size}
          pageSizeOptions={PAGE_SIZE_LIST}
          showTotal={(total) => `Tổng số bản ghi ${total}`}
        />
      </Spin>
    </div>
  );
};

export default RevenueReportsTotal;
