import { Spin, Table, notification } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import saveAs from 'file-saver';
import moment from 'moment';
import _ from 'lodash';

import { DEFAULT_PAGE_SIZE, PAGE_SIZE_LIST } from 'src/utils/constants';
import { convertListSaleMan, modifyArrTreeSelect } from 'src/utils/stringUtils';
import dataManagerServices from 'src/services/dataManager.service';
import { useWindowSize } from 'src/utils/customHooks';
import registerPackageServices from 'src/services/registerPackage.service';
import reportsServices from 'src/services/reports.service';
import {
  convertCareStatusToTreeData,
  convertReasonsToTreeData,
} from 'src/utils/treeSelect';
import {
  isAdmin,
  isSaleLeader,
  isSaleManager,
  projectId,
  projectName,
} from 'src/utils';

import ManagerDividedDataModal from '../ManagerDividedDataModal';
import LeaderDividedDataModal from '../LeaderDividedDataModal';
import DataLx3Filters from './DataLx3Filters';
import { getColumnDataLx3 } from './ColumnDataLx3';

type Props = {};

type State = {
  loading: boolean;
  data: DataLx3[];
  packages: any;
  treeTakeCare: any;
  pagination: DataLx3Params;
  filters: DataLx3Body;
  metadata?: MetadataInterface;
  staffs: any;
  selectedStaffs: any;
  extendSearch: boolean;
  treeReason: TREE_REASON[];
  defaultTreeReason: string[];
  total: number;
  takeCareSelected: any;
  loadingAutoSearch: boolean;
  autoSearchRunning: boolean;
  actionStaff?: string;
  configPhonePackagesAutoSearch: { label: string; value: string }[];
};

const defaultFilters = {
  assignedDateFrom: undefined,
  assignedDateTo: undefined,
  msisdn: undefined,
  packActive: undefined,
  expiredDateFrom: undefined,
  expiredDateTo: undefined,
  assignedStaffs: undefined,
  customerStages: undefined,
  currentRegisteredPackage: undefined,
  suggestedPackage: undefined,
  assignedStatus: 'auto_recall',
  sourceSys: undefined,
};

const DataLx3: FC<Props> = () => {
  const paginationDefault = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  };

  const [listStaff, setListStaff] = useState<any[]>([]);
  const [UIState, setUIState] = useState<State>({
    loading: true,
    loadingAutoSearch: false,
    autoSearchRunning: false,
    data: [],
    packages: [],
    treeTakeCare: [],
    staffs: [],
    selectedStaffs: undefined,
    pagination: paginationDefault,
    filters: defaultFilters,
    metadata: undefined,
    extendSearch: false,
    treeReason: [],
    defaultTreeReason: [],
    takeCareSelected: [],
    configPhonePackagesAutoSearch: [],
    actionStaff: '',
    total: 0,
  });
  const [isShowModal, setIsShowModal] = useState(false);

  const getListStaff = async () => {
    const response = await registerPackageServices.getListStaffTree();
    const { data = [] } = response;
    const modifiedData = data
      .filter((item: any) => item.active !== 0)
      .map((value: any) => {
        return {
          ...value,
          saleManList: value?.saleManList?.filter((x: any) => x.active !== 0),
        };
      });

    if (response?.status === 200) {
      let newArr = modifyArrTreeSelect(modifiedData);
      setListStaff(newArr);
    }
  };

  const fetchData = async () => {
    const { pagination, filters } = UIState;
    const body = {
      ...filters,
      packActive: filters.packActive?.toString(),
    };
    const params = {
      ...pagination,
      assignedStatus: 'auto_recall',
    };
    const response = await dataManagerServices.getDataLx3(params, body);

    if (response?.status === 200) {
      const { data, headers } = response;
      setUIState((cur) => ({
        ...cur,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
        data,
        loading: false,
        total: parseFloat(headers['x-total-count']),
      }));
    } else {
      setUIState((cur) => ({
        ...cur,
        loading: false,
      }));
    }
  };

  const fetchDataFilters = async () => {
    const response = await Promise.all([
      dataManagerServices.getFilterStateTakeCareOf(),
      registerPackageServices.getListPackage(),
      reportsServices.getListStaff(),
      dataManagerServices.getListReason(),
    ]);

    const convertData = convertCareStatusToTreeData(
      response[0]?.data?.processing
    );

    setUIState((cur) => ({
      ...cur,
      treeTakeCare: convertData,
      packages: response[1]?.data,
      staffs: modifyArrTreeSelect(response[2]?.data),
      treeReason: convertReasonsToTreeData(response[3].data.parentLevelSales),
    }));
  };

  const onSearch = () => {
    setUIState((cur) => ({
      ...cur,
      loading: true,
    }));
  };

  const onExport = async () => {
    const { pagination, filters } = UIState;

    const params = {
      ...filters,
      packActive: filters.packActive?.toString(),
    };

    const resp = await dataManagerServices.exportDataLx3(pagination, params);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Data_LX3_Export_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
  };

  const onExtend = () => {
    setUIState((cur) => ({
      ...cur,
      extendSearch: !cur.extendSearch,
    }));
  };

  const onPageChange = (mPage: number, mSize: number) => {
    setUIState((cur) => ({
      ...cur,
      pagination: {
        page: mPage ? mPage - 1 : 0,
        size: mSize,
      },
      loading: true,
    }));
  };

  const onChangeExpiredDate = (values: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        expiredDateFrom: values
          ? values[0]?.startOf('day')?.valueOf()
          : undefined,
        expiredDateTo: values ? values[1]?.endOf('day')?.valueOf() : undefined,
      },
    }));
  };

  const onChangePhone = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        msisdn: value ?? undefined,
      },
    }));
  };

  const onChangePackage = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        packActive: value ?? undefined,
      },
    }));
  };

  const onChangeStaff = (value: string[]) => {
    setUIState((cur) => ({
      ...cur,
      staffSelected: value,
      filters: {
        ...cur.filters,
        assignedStaffs: convertListSaleMan(value, UIState.staffs),
      },
    }));
  };

  const onChangeTakeCare = (value: string[]) => {
    setUIState((cur) => ({
      ...cur,
      takeCareSelected: value,
      filters: {
        ...cur.filters,
        customerStages: _.flatten(value.map((item: string) => item.split(','))),
      },
    }));
  };

  const onChangeSourceSys = (value: string) => {
    setUIState((cur) => ({
      ...cur,
      staffSelected: value,
      filters: {
        ...cur.filters,
        sourceSys: value ?? undefined,
      },
    }));
  };

  const onChangeReason = (value: string[]) => {
    const convertData = value.map((item: string) => {
      return item.split('-')[1].split(',');
    });

    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        reasons: _.flatten(convertData),
      },
      defaultTreeReason: value,
    }));
  };

  const onPressEnter = (e: any) => {
    if (e.keyCode === 13) {
      fetchData();
    }
  };

  const onChangeAllocated = (values: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        allocatedDateFrom: values
          ? values[0].startOf('day').valueOf()
          : undefined,
        allocatedDateTo: values ? values[1].endOf('day').valueOf() : undefined,
      },
    }));
  };

  const onShareData = () => {
    if (!!UIState.metadata && UIState.metadata?.total > 0) {
      setIsShowModal(true);
    } else {
      notification.error({
        message: 'Chưa có data để chia',
        description: 'Thất bại',
      });
    }
  };

  const onReset = () => {
    setUIState((cur) => ({
      ...cur,
      filters: defaultFilters,
      defaultTreeReason: [],
      loading: true,
    }));
  };

  const getListDataAutoSearch = async () => {
    const resp = await dataManagerServices.getListDataAutoSearch(
      UIState.filters
    );
    if (resp?.status === 200) {
      fetchData();
      setUIState((cur: State) => ({
        ...cur,
        loadingAutoSearch: false,
      }));
    } else {
      setUIState((cur: State) => ({ ...cur, loadingAutoSearch: false }));
    }
  };

  const checkAutoSearchRunning = async () => {
    const resp = await dataManagerServices.checkAutoSearchRunning();
    if (resp?.status === 200) {
      const { autoSearchRunning, actionStaff } = resp?.data;
      setUIState((cur: State) => ({
        ...cur,
        autoSearchRunning,
        actionStaff,
      }));
    }
  };

  const onChangeCurrentRegisteredPackage = (e: any) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        currentRegisteredPackage: e.target.value?.trim() ?? undefined,
      },
    }));
  };

  const onChangeSuggestedPackage = (values: string[]) => {
    setUIState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        suggestedPackage: values ?? [],
      },
    }));
  };

  const getConfigPhonePackagesAutoSearch = async () => {
    const resp = await dataManagerServices.getConfigPhonePackagesAutoSearch();
    if (resp?.status === 200) {
      const configPhonePackagesAutoSearch = resp?.data?.map((item: string) => ({
        label: item,
        value: item,
      }));

      setUIState((cur) => ({
        ...cur,
        configPhonePackagesAutoSearch,
      }));
    }
  };

  const handleAutoSearch = () => {
    setUIState((cur: State) => ({
      ...cur,
      loadingAutoSearch: true,
      autoSearchRunning: true,
    }));

    setUIState((prevState) => ({ ...prevState, page: 0 }));
    getListDataAutoSearch();
    checkAutoSearchRunning();
  };

  useEffect(() => {
    if (UIState.loading) {
      fetchData();
    }
  }, [UIState.loading]);

  useEffect(() => {
    getListStaff();
    fetchDataFilters();
    getConfigPhonePackagesAutoSearch();
    checkAutoSearchRunning();
  }, []);

  const windowSize = useWindowSize();
  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  return (
    <Spin spinning={UIState.loading} tip={'Loading...'}>
      <Helmet>
        <title>Data thu hồi</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <DataLx3Filters
        defaultData={UIState.filters}
        size={size}
        treeTakeCare={UIState.treeTakeCare}
        packages={UIState.packages}
        staffs={UIState.staffs}
        takeCareSelected={UIState.takeCareSelected}
        selectedStaffs={UIState.selectedStaffs}
        extendSearch={UIState.extendSearch}
        isLoadingExport={false}
        dataReason={UIState.treeReason}
        autoSearchRunning={UIState.autoSearchRunning}
        actionStaff={UIState.actionStaff}
        loadingAutoSearch={UIState.loadingAutoSearch}
        configPhonePackagesAutoSearch={UIState?.configPhonePackagesAutoSearch}
        onChangeExpiredDate={onChangeExpiredDate}
        onChangePhone={onChangePhone}
        onChangePackage={onChangePackage}
        onChangeStaff={onChangeStaff}
        onChangeSourceSys={onChangeSourceSys}
        onChangeTakeCare={onChangeTakeCare}
        onKeyDown={onPressEnter}
        onSearch={onSearch}
        onExport={onExport}
        onExtend={onExtend}
        onReset={onReset}
        onChangeReason={onChangeReason}
        defaultTreeReason={UIState.defaultTreeReason}
        onChangeAllocated={onChangeAllocated}
        onShareData={onShareData}
        onAutoSearch={handleAutoSearch}
        onChangeCurrentRegisteredPackage={onChangeCurrentRegisteredPackage}
        onChangeSuggestedPackage={onChangeSuggestedPackage}
      />

      <Table
        rowKey={'id'}
        columns={getColumnDataLx3(onSearch, listStaff) as any}
        dataSource={UIState.data}
        className="myTable"
        pagination={{
          size: 'small',
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZE_LIST,
          defaultPageSize: UIState.pagination.size,
          style: { marginRight: 12 },
          onChange: onPageChange,
          total: UIState.metadata?.total,
          showTotal: (total) => (
            <div>Tổng số bản ghi {total.toLocaleString()}</div>
          ),
        }}
        scroll={{
          y: UIState.extendSearch
            ? windowSize?.width < 1700 && windowSize?.height < 1000
              ? '58vh'
              : '60vh'
            : windowSize?.width < 1700 && windowSize?.height < 1000
            ? '78vh'
            : '83vh',
          x: 2000,
        }}
      />

      {(isAdmin || isSaleManager) && isShowModal && (
        <ManagerDividedDataModal
          setOpenProps={setIsShowModal}
          metadataProps={UIState.metadata}
          filters={UIState.filters}
          onSuccess={onSearch}
        />
      )}
      {isSaleLeader && isShowModal && (
        <LeaderDividedDataModal
          setOpenProps={setIsShowModal}
          metadataProps={UIState.metadata}
          filters={UIState.filters}
          onSuccess={onSearch}
        />
      )}
    </Spin>
  );
};

export default DataLx3;
