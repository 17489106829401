import { deleteParamsNotUsing, getQueryString } from 'src/utils/stringUtils';
import APIService from '../utils/apiServices';

class ReportsServices extends APIService {
  async getListDataDaily(query) {
    return await this.request(
      'GET',
      `revenue-reports/by-day${query || ''}`,
      {}
    );
  }

  async getListDataSaleman(query) {
    return await this.request(
      'GET',
      `revenue-reports/by-sale${query || ''}`,
      {}
    );
  }

  async getTotal(query) {
    return await this.request('GET', `revenue-reports/all${query || ''}`, {});
  }

  async exportFileDaily(query) {
    return await this.request(
      'GET',
      `revenue-reports/by-day/export${query || ''}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  exportFileSaleman(query) {
    return this.request(
      'GET',
      `revenue-reports/by-sale/export${query || ''}`,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async getListStaff(query?: string) {
    return await this.request(
      'GET',
      `categories/users-for-search${query || ''}`,
      {}
    );
  }

  async getReportsTotal(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`total-reports/total-ratio/search`, params);
    return await this.request('GET', url);
  }

  async getReportStream(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`total-reports/total-processing/search`, params);
    return await this.request('GET', url);
  }

  async exportReportStream(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`total-reports/total-processing/export`, params);
    return await this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  async getReportsTotalAll(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`total-reports/total-ratio/search/all`, params);
    return await this.request('GET', url);
  }

  async exportReportsTotal(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(`total-reports/total-ratio/export`, params);
    return await this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }

  searchTakeCareByDay(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      'total-reports/total-take-care-by-day/search',
      params
    );
    return this.request('GET', url);
  }

  exportTakeCareByDay(params: any) {
    deleteParamsNotUsing(params);
    const url = getQueryString(
      'total-reports/total-take-care-by-day/export',
      params
    );
    return this.request(
      'GET',
      url,
      {},
      {
        responseType: 'blob',
      }
    );
  }
}
const reportsServices = new ReportsServices();
export default reportsServices;
