/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import { Button, InputNumber, Select, Table, Modal } from 'antd';
import registerPackageServices from '../../services/registerPackage.service';
import { updateQueryStringParameter } from '../../utils/stringUtils';
import { useWindowSize } from '../../utils/customHooks';
import moment from 'moment';
import _ from 'lodash';
import { GLOBAL_HEIGHT } from '../../utils/constants';

function ExtendPackages() {
  const windowSize = useWindowSize();

  const [dataList, setDataList] = useState([]);
  const [dataListOrigin, setDataListOrigin] = useState([]);

  const [dataRegister, setDataRegister] = useState({});
  const [dataRegisterError, setDataRegisterError] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isItemSearch, setIsItemSearch] = useState('');
  const [listPackage, setListPackage] = useState([]);

  useEffect(() => {
    getListData();
    getListPackage();
  }, []);

  const getListData = async () => {
    setIsLoading(true);
    // let query = buildQueryString()
    const resp = await registerPackageServices.extendPackagesList();
    const data = resp?.data;
    if (resp?.status === 200) {
      setDataList(data);
      setDataListOrigin(_.cloneDeep(data));
    }
    setIsLoading(false);
  };

  const getListPackage = async () => {
    const resp = await registerPackageServices.getListPackage();
    const data = resp?.data;
    if (resp?.status === 200) {
      setListPackage(data);
    }
  };

  const onValidateRegister = () => {
    let dataError = {};
    let check = false;
    if (!dataRegister?.msisdn) {
      dataError.msisdn = 'Số điện thoại sai định dạng';
      check = true;
    } else {
      if (dataRegister?.msisdn.toString().length !== 9) {
        dataError.msisdn = 'Số điện thoại sai định dạng';
        check = true;
      } else {
        dataError.msisdn = '';
      }
    }

    if (!dataRegister?.packageCode) {
      dataError.packageCode = 'Gói cước không hợp lệ';
      check = true;
    } else {
      dataError.packageCode = '';
    }

    setDataRegisterError(dataError);
    return check;
  };

  const onExtendPackage = async () => {
    if (onValidateRegister()) {
      return;
    }

    let queryString = '';
    if (dataRegister.msisdn) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        dataRegister.msisdn
      );
    }
    if (dataRegister.packageCode) {
      queryString = updateQueryStringParameter(
        queryString,
        'packageCode',
        dataRegister.packageCode
      );
    }

    setIsSubmit(true);

    const resp = await registerPackageServices.extendPackages(queryString);
    const data = resp?.data;

    if (resp?.status === 200) {
      setDataRegister({});
      getListData();
      Modal.success({
        title: 'Tạo yêu cầu gia hạn thành công',
        content: data['_1075Response']
          ? data['_1075Response']?.message
          : data?.message,
      });
    } else {
      if (data?.errorCode === 'phone_invalid') {
        Modal.error({
          title: (
            <div>
              Yêu cầu gia hạn không được thực hiện do SĐT{' '}
              <span className="bold">{dataRegister.msisdn}</span> không tồn tại
              trong hệ thống
            </div>
          ),
          okText: 'Đã hiểu',
        });
      } else if (data?.errorCode === 'extend_request_existed') {
        Modal.error({
          title: <div dangerouslySetInnerHTML={{ __html: data.message }} />,
          okText: 'Đã hiểu',
        });
      } else {
        Modal.error({
          title: 'Yêu cầu gia hạn thất bại',
          content: data['_1075Response']
            ? data['_1075Response']?.message
            : data?.message,
          okText: 'Đóng',
        });
      }
    }
    setIsSubmit(false);
  };

  const onExtendPackageSearch = async (item) => {
    if (isItemSearch === item?.id) {
      return;
    }

    setIsItemSearch(item.id);
    const resp = await registerPackageServices.extendPackagesSearch(item.id);
    const data = resp?.data;
    if (resp?.status === 200) {
      Modal.success({
        title:
          data?.errorCode === 'search_1075_successful'
            ? 'Trạng thái gia hạn'
            : 'Đăng ký thành công',
        content: data['_1075Response']
          ? data['_1075Response']?.message
          : data?.message,
        okText: 'Đóng',
      });
      getListData();
    } else {
      getListData();
      Modal.error({
        title: 'Đăng ký thất bại',
        content: data['_1075Response']
          ? data['_1075Response']?.message
          : data?.message,
        okText: 'Đóng',
      });
    }
    setIsItemSearch('');
  };

  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      render: (cell, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '15%',
      render: (cell) => {
        return cell ? moment(cell).format('DD/MM/YYYY | HH:mm:ss') : '---';
      },
    },
    {
      title: 'Số điện thoại đăng ký',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: '15%',
    },
    {
      title: 'Gói cước',
      dataIndex: 'phonePackage',
      key: 'phonePackage',
      width: '10%',
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (cell, record) => {
        return (
          <div>
            <Button
              loading={isItemSearch === record?.id}
              className="btn-primary-ghost"
              icon={<SendOutlined />}
              onClick={() => {
                onExtendPackageSearch(record);
              }}
            >
              Tra cứu kết quả
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'searchResponse',
      key: 'searchResponse',
      render: (cell, record) => {
        return (
          <div>
            {!!record?.searchTime && (
              <div className="bold">
                {moment(record?.searchTime).format('DD/MM/YYYY | HH:mm:ss')}
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: cell }} />
          </div>
        );
      },
    },
  ];

  let tableHeight =
    windowSize?.height -
    GLOBAL_HEIGHT -
    52 -
    (dataRegisterError?.msisdn || dataRegisterError?.packageCode ? 92 : 72);

  return (
    <div>
      <div>
        <div className="box-register-package">
          <div className="box-register-package-item">
            <InputNumber
              placeholder="Nhập số điện thoại"
              value={dataRegister?.msisdn}
              onChange={(e) => {
                setDataRegister({ ...dataRegister, msisdn: e });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onExtendPackage();
                }
              }}
            />
            {dataRegisterError?.msisdn && (
              <div className="txt-error">{dataRegisterError?.msisdn}</div>
            )}
          </div>

          <div className="box-register-package-item">
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder={'Gói cước gia hạn'}
              value={dataRegister?.packageCode}
              onChange={(value) =>
                setDataRegister({ ...dataRegister, packageCode: value })
              }
            >
              {listPackage.map((item, index) => (
                <Select.Option key={index} value={item.code}>
                  {item.code}
                </Select.Option>
              ))}
            </Select>
            {dataRegisterError?.packageCode && (
              <div className="txt-error">{dataRegisterError?.packageCode}</div>
            )}
          </div>

          <Button
            loading={isSubmit}
            icon={<PlusOutlined />}
            onClick={onExtendPackage}
            type="primary"
          >
            Tạo yêu cầu
          </Button>
        </div>

        <Table
          loading={isLoading}
          rowKey={'id'}
          dataSource={dataList}
          columns={columns}
          pagination={{
            hideOnSinglePage: true,
            pageSize: dataList.length,
          }}
          scroll={{ y: tableHeight }}
        />
      </div>
    </div>
  );
}

export default ExtendPackages;
