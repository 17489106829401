import { Button, Col, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { STATUS_SUCCESS, TURN_UP } from 'src/constants';
import dataManagerServices from 'src/services/dataManager.service';
import HistoryPacks from './HistoryPacks';
import SuggestPacks from './SuggestPacks';
import moment from 'moment';
import LocalStoreInstance from 'src/utils/localStorage';
import { SearchOutlined } from '@ant-design/icons';
import { formatPrice } from 'src/utils/formatter';
import { convertSuggestedPackages, isNullOrEmpty } from 'src/utils/stringUtils';
import { useWindowSize } from 'src/utils/customHooks';
import './style.scss';

type Props = {
  searchedSubscriberInfo?: boolean;
  subscriberInfoWithSearching?: any;
  subscriberInfoWithoutSearching?: any;
  dataId: number;
  onSubmitSuccess: () => void;
  profile?: any;
  handleSearch: (type: string) => void;
  usingPackageCode?: string;
  loadingTurnUp: boolean;
  loadingSearchVip: boolean;
  handleSearchVip?: (phoneNumber: string) => void;
};

type State = {
  loading: boolean;
  data: SearchB9Interface | null;
};

const SubscriberProfile: React.FC<Props> = ({
  searchedSubscriberInfo,
  subscriberInfoWithSearching,
  subscriberInfoWithoutSearching,
  dataId,
  onSubmitSuccess,
  handleSearch,
  profile,
  loadingTurnUp,
  usingPackageCode,
  loadingSearchVip,
  handleSearchVip,
}) => {
  const {
    phoneNumber,
    sourceSys,
    packActive,
    packActivationDate,
    expiredDate,
    address,
    amount,
    fullName,
    chargePrice,
  } =
    (searchedSubscriberInfo
      ? subscriberInfoWithSearching
      : subscriberInfoWithoutSearching) || {};
  const windowSize = useWindowSize();

  const [stateSearchB9, setStateSearchB9] = useState<State>({
    loading: false,
    data: null,
  });

  const subscriberWithoutSearchingProfiles = [
    { id: 1, label: 'Số điện thoại', content: phoneNumber },
    { id: 2, label: 'Loại thuê bao', content: sourceSys },
    { id: 3, label: 'Gói đang dùng', content: packActive },
    { id: 4, label: 'Ngày gia hạn', content: packActivationDate },
    { id: 5, label: 'Ngày hết hạn', content: expiredDate },
    {
      id: 6,
      label: 'Giá gói đã mua',
      content: !isNullOrEmpty(chargePrice)
        ? `${formatPrice(chargePrice)} VNĐ`
        : null,
    },
  ];

  const subscriberWithSearchingProfiles = [
    { id: 1, label: 'Số thuê bao', content: phoneNumber, sourceSys },
    {
      id: 2,
      label: 'Tài khoản chính',
      content: (amount ?? 0).toLocaleString() + 'đ',
      isBold: true,
    },
    {
      id: 3,
      label: 'Gói cước đang sử dụng',
      content: packActive,
      isBold: true,
    },
    {
      id: 4,
      label: 'Chủ thuê bao',
      content: fullName,
    },
    { id: 5, label: 'Địa chỉ', content: address },
    {
      id: 6,
      label: 'Ngày gia hạn',
      content: subscriberInfoWithSearching?.packActivationDate,
      isBold: true,
    },
    {
      id: 7,
      label: 'Ngày hết hạn',
      content: subscriberInfoWithSearching?.expiredDate,
      isBold: true,
    },
  ];

  const handleSearchB9 = async () => {
    setStateSearchB9((prev: any) => ({ ...prev, loading: true }));
    const resp = await dataManagerServices.searchB9(dataId.toString());
    const { status, data } = resp || {};
    if (status === STATUS_SUCCESS) {
      setStateSearchB9((prev: any) => ({ ...prev, data, loading: false }));
    } else {
      const { message } = data || {};
      notification.error({
        message: 'Tra cứu thuê bao thất bại',
        description: message,
        maxCount: 1,
      });
      setStateSearchB9((prev: any) => ({ ...prev, loading: false }));
    }
    onSubmitSuccess();
  };

  const renderItem = (item: any) => (
    <Row gutter={24} key={item.id}>
      <Col span={8}>
        <p key={item.id} style={{ fontWeight: 600 }}>
          {item.label}:
        </p>
      </Col>
      <Col span={16}>
        <p
          key={item.id}
          style={{ fontWeight: item?.isBold ? 'bold' : undefined }}
        >
          {searchedSubscriberInfo
            ? `${item.content} ${item.id === 1 ? `(${item.sourceSys})` : ''}`
            : item.content}
        </p>
      </Col>
    </Row>
  );

  useEffect(() => {
    if (expiredDate && packActivationDate) {
      const infoDates = {
        expiredDate,
        packActivationDate,
      };
      LocalStoreInstance.getInstance().save(
        'infoDates',
        JSON.stringify(infoDates)
      );
    } else {
      localStorage.removeItem('infoDates');
    }
  }, [packActivationDate, expiredDate]);

  return (
    <>
      <div className="block__header">
        <h2>THÔNG TIN THUÊ BAO</h2>
      </div>
      <div
        className="bg-[#fff] p-2 overflow-x-hidden overflow-y-scroll pb-10"
        style={{
          height: windowSize.height / 2,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <div className="flex-row flex justify-between">
          <div className="text-title-css mgbt-20">Thông tin thuê bao</div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            {subscriberInfoWithSearching?.updatedTime && (
              <p>
                Cập nhật:{' '}
                {moment(subscriberInfoWithSearching?.updatedTime).format(
                  'DD/MM/YYYY HH:mm:ss'
                )}
              </p>
            )}
            {/* <div style={{ marginTop: '-10px' }}>
              <Button
                loading={stateSearchB9?.loading}
                onClick={handleSearchB9}
                icon={<SearchOutlined />}
                type="primary"
              >
                Tra cứu thông tin
              </Button>
            </div> */}
            <div style={{ marginTop: '-10px' }}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                onClick={handleSearchVip?.bind(null, phoneNumber)}
                loading={loadingSearchVip}
              >
                Kiểm tra Data VIP
              </Button>
            </div>
          </div>
        </div>
        <div className="ml-3 mr-3">
          {(searchedSubscriberInfo
            ? subscriberWithSearchingProfiles
            : subscriberWithoutSearchingProfiles
          ).map(renderItem)}
          {searchedSubscriberInfo && (
            <div>
              <SuggestPacks
                suggestPacks={subscriberInfoWithSearching?.suggestedPackage}
              />
              <HistoryPacks
                historyPacks={subscriberInfoWithSearching?.historyPacks}
              />
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between">
          <div className="mb-4 flex">
            <p className="text-title-css mr-1">Kết quả tra cứu đối tượng</p>
            {profile?.latestSearchBaseInfo?.actionTime && (
              <p className="italic">
                (Kết quả lần tra cứu gần nhất:{' '}
                {profile?.latestSearchBaseInfo?.actionTime})
              </p>
            )}
          </div>

          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => handleSearch(TURN_UP)}
            loading={loadingTurnUp}
          >
            Tra cứu đối tượng
          </Button>
        </div>

        {Object.keys(profile?.latestSearchBaseInfo || {})?.length !== 0 ? (
          <div className="ml-3 mr-3">
            <Row gutter={24}>
              <Col span={8}>
                <p style={{ fontWeight: 'bold' }}>Gói cước đang sử dụng:</p>
              </Col>
              <Col span={16}>
                <p>
                  {usingPackageCode
                    ? usingPackageCode
                    : packActive?.toString() ?? 'Không tìm thấy gói cước'}
                </p>
              </Col>
            </Row>

            <Row gutter={24} className="pb-10">
              <Col span={8}>
                <p className="font-bold">Gói cước có thể đăng kí:</p>
              </Col>
              <Col span={16}>
                {profile?.latestSearchBaseInfo?.suggestedPackagesNew?.length !==
                0 ? (
                  convertSuggestedPackages(
                    profile?.latestSearchBaseInfo?.suggestedPackagesNew
                  ).map((item, index) => (
                    <div key={index}>
                      <span>
                        <strong>{item.name}</strong>: {item?.package}
                      </span>
                    </div>
                  ))
                ) : (
                  <strong>Không tìm thấy gói cước có thể đăng ký</strong>
                )}
              </Col>
            </Row>
          </div>
        ) : (
          <div>Thuê bao chưa có dữ liệu tra cứu</div>
        )}
      </div>
    </>
  );
};

export default SubscriberProfile;
