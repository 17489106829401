import { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Select,
  notification,
  DatePicker,
  FormInstance,
  Input,
} from 'antd';

import moment from 'moment';
import registerPackageServices from 'src/services/registerPackage.service';
import {
  DATE_FORMAT,
  REGISTER_PACKAGE_PAYMENT_CHANNEL,
} from 'src/utils/constants';
import { modifyArrTreeSelect } from 'src/utils/stringUtils';
import React from 'react';
import AppContext from 'src/context/AppContext';

type Props = {
  handleCancel: () => void;
  dataId?: number;
  assignedStaff?: string;
  onSubmitSuccess: () => void;
};

type State = {
  staffs: any;
  saleMan: any;
  saleManError: string;
  loading: boolean;
};

const FORM_FIELDS = {
  paymentChannel: 'paymentChannel',
  phonePackage: 'phonePackage',
  registeredDate: 'registeredDate',
};

function ModalAddNewTransaction(props: Props) {
  const { phonePackages } = useContext(AppContext).appState;

  const formRef = React.useRef<FormInstance<any> | null>(null);
  const { handleCancel, onSubmitSuccess } = props;

  const [selectedChannel, setSelectedChannel] = useState<any>('9084 DAG');
  const [state, setState] = useState<State>({
    staffs: [],
    saleMan: null,
    saleManError: '',
    loading: true,
  });

  const fetchData = async () => {
    const response = await registerPackageServices.getListStaffTree();
    setState((cur) => ({
      ...cur,
      loading: false,
      staffs: modifyArrTreeSelect(response.data),
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onFinish = (values: any) => {
    const body = { ...values, saleMan: props.assignedStaff };
    onSubmit(body);
  };

  const onSubmit = async (body: any) => {
    const params = {
      ...body,
      registeredDate: Number(moment(body.registeredDate).format('X')) * 1000,
    };
    const resp = await registerPackageServices.createOutsideRequest(
      props.dataId,
      params
    );
    if (resp?.status === 200) {
      notification.success({
        message: 'Thêm mới giao dịch',
        description: 'Thành công',
      });
      handleCancel();
      onSubmitSuccess();
    } else {
      notification.error({
        message: resp?.data?.message,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeChannel = (value: any) => {
    setSelectedChannel(value);
    if (value === FORM_FIELDS.paymentChannel) {
      formRef.current?.setFieldValue(FORM_FIELDS.phonePackage, undefined);
    }
  };

  return (
    <Modal
      title={'Thêm mới giao dịch'}
      open={true}
      centered
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Hủy bỏ
        </Button>,
        <Button form="myForm" key="submit" htmlType="submit" type="primary">
          Thêm mới
        </Button>,
      ]}
      width={700}
    >
      <Form
        ref={formRef}
        id="myForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        initialValues={{ paymentChannel: '9084 DAG' }}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Kênh"
              name={FORM_FIELDS.paymentChannel}
              rules={[
                { required: true, message: 'Vui lòng chọn kênh đăng ký!' },
              ]}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                placeholder={'Chọn kênh đăng ký'}
                options={REGISTER_PACKAGE_PAYMENT_CHANNEL}
                onChange={onChangeChannel}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className="ant-col ant-form-item-label">
              <label
                htmlFor="msisdn"
                className="ant-form-item-required"
                title="Số điện thoại"
              >
                Saleman
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <Input value={props.assignedStaff} disabled />
            </div>
            {!!state.saleManError && (
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <div className="ant-form-item-explain ant-form-item-explain-connected">
                  <div role="alert" className="ant-form-item-explain-error">
                    {state.saleManError}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            {selectedChannel && (
              <Form.Item
                label="Gói cước"
                name={FORM_FIELDS.phonePackage}
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn gói cước đăng ký!',
                  },
                ]}
              >
                <Select
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  placeholder={'Chọn gói cước đăng ký'}
                  options={phonePackages.map((item: any) => ({
                    label: item.code,
                    value: item.code,
                  }))}
                />
              </Form.Item>
            )}
          </Col>

          <Col span={12}>
            <Form.Item
              label="Ngày đăng ký thành công"
              name={FORM_FIELDS.registeredDate}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn ngày đăng ký!',
                },
              ]}
            >
              <DatePicker
                allowClear
                placeholder={'Chọn ngày đăng ký'}
                format={DATE_FORMAT}
                style={{ width: '100%' }}
                disabledDate={(currentDate) =>
                  currentDate?.valueOf() > Date.now()
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export { ModalAddNewTransaction };
