import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';
// import './style.scss';
import DataCaring from './forSale/dataCaring';

function Home() {
  // const { appState, dispatch } = React.useContext(AppContext);

  return (
    <div
      className="overflow-y-scroll h-full"
      style={{
        background: '#f2f2f2',
        overflowX: 'hidden',
      }}
    >
      <Helmet>
        <title>Trang chủ</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <DataCaring />
    </div>
  );
}

export default Home;
