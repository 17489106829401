import { FC } from 'react';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { useWindowSize } from 'src/utils/customHooks';
import { ReactComponent as IconShareData } from 'src/assets/icons/icShareData.svg';

import {
  DATA_SHARE,
  DATA_SOURCE,
  DATA_STATUS_SEARCH,
  DATE_FORMAT,
  SOURCES_SYS_LIST,
} from 'src/utils/constants';
import './style.scss';
import moment from 'moment';
import {
  contentBox,
  datePickerStyle,
  filterBoxStyle,
  inputStyle,
  txtTitle,
} from 'src/styles';
import FiltersButton from 'src/components/FiltersButton';
import { isAdminOrSaleManager, isSaleLeader } from 'src/utils';

type Props = {
  style?: any;
  isLoadingExport: boolean;
  autoSearchRunning: boolean;
  actionStaff: any;
  loadingAutoSearch: boolean;
  loadingReset: boolean;
  configPhonePackagesAutoSearch: any;
  extendSearch: boolean;
  filters: SearchListDataParams;
  onChangePhone: (phone: string | null) => void;
  onPressEnter: (e: any) => void;
  onExtend: () => void;
  onExport: () => void;
  onSearch: () => void;
  onReset: () => void;
  onShareData: () => void;
  onAutoSearch: () => void;
  onChangeSourceSys: (value: string) => void;
  onChangeSource: (value: string) => void;
  onChangeCurrentRegisteredPackage: (e: any) => void;
  onChangePackActive: (e: any) => void;
  onChangeShareData: (share: string) => void;
  onChangeStatusData: (status: string) => void;
  onChangeSuggestedPackage: (values: string[]) => void;
  onChangeAutoSearchDate: (values: any, formatString: [string, string]) => void;
  onChangeExpiredDate: (values: any, formatString: [string, string]) => void;
  onChangeActivationDate: (values: any, formatString: [string, string]) => void;
  onChangeDateImport: (values: any, formatString: [string, string]) => void;
  onChangeDividedDate: (values: any, formatString: [string, string]) => void;
};

const DataManagerFilter: FC<Props> = (props) => {
  const windowSize = useWindowSize();
  const size = windowSize?.width > 1700 ? 'middle' : 'small';

  const className = ['disable-arrow1', 'disable-arrow3', 'disable-arrow4'];

  const dateRanges = {
    'Ngày hiện tại': [moment(), moment()],
    'Tháng hiện tại': [moment().startOf('month'), moment().endOf('month')],
  };

  const renderFilters = () => {
    if (isSaleLeader) {
      return (
        <Row>
          <Col span={6}>
            <div style={contentBox}>
              <p style={txtTitle}>Gói đang dùng</p>
              <Input
                size={size}
                style={inputStyle}
                onChange={props.onChangePackActive}
                onKeyDown={props.onPressEnter}
                value={props.filters.packActive}
              />
            </div>

            <div style={contentBox}>
              <p style={txtTitle}>Loại thuê bao</p>
              <Select
                allowClear
                style={filterBoxStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={SOURCES_SYS_LIST[0].value}
                onChange={props.onChangeSourceSys}
                options={SOURCES_SYS_LIST}
              />
            </div>
          </Col>

          <Col span={6}>
            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Trạng thái</p>
              <Select
                allowClear
                style={filterBoxStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={DATA_SHARE[0].value}
                onChange={props.onChangeShareData}
                options={DATA_SHARE}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Trạng thái tra cứu</p>
              <Select
                allowClear
                style={filterBoxStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={DATA_STATUS_SEARCH[0].value}
                onChange={props.onChangeStatusData}
                options={DATA_STATUS_SEARCH}
              />
            </div>
            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Nguồn data</p>
              <Select
                size={size}
                style={filterBoxStyle}
                placeholder={'Tất cả'}
                allowClear
                onChange={props.onChangeSource}
                options={DATA_SOURCE}
              />
            </div>
          </Col>

          <Col span={6}>
            <div style={{ ...contentBox, justifyContent: 'flex-end' }}>
              <p style={{ ...txtTitle, width: '5vw' }}>Ngày gia hạn</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeActivationDate}
                format={DATE_FORMAT}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'flex-end' }}>
              <p style={{ ...txtTitle, width: '5vw' }}>Ngày hết hạn</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeExpiredDate}
                format={DATE_FORMAT}
              />
            </div>
          </Col>

          <Col span={6}>
            {isSaleLeader && (
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={txtTitle}>Ngày chia data</p>
                <DatePicker.RangePicker
                  allowClear
                  size={size}
                  style={datePickerStyle}
                  placeholder={['Từ', 'Đến']}
                  ranges={dateRanges as any}
                  onChange={props.onChangeDividedDate}
                  format={DATE_FORMAT}
                  dropdownClassName={className.join(' ')}
                />
              </div>
            )}
            {!isSaleLeader && (
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={txtTitle}>Ngày cập nhật</p>
                <DatePicker.RangePicker
                  allowClear
                  size={size}
                  style={datePickerStyle}
                  placeholder={['Từ', 'Đến']}
                  ranges={dateRanges as any}
                  onChange={props.onChangeAutoSearchDate}
                  format={DATE_FORMAT}
                  dropdownClassName={className.join(' ')}
                />
              </div>
            )}

            {!isSaleLeader && (
              <div style={{ ...contentBox, justifyContent: 'center' }}>
                <p style={txtTitle}>Ngày Import</p>
                <DatePicker.RangePicker
                  allowClear
                  size={size}
                  style={datePickerStyle}
                  placeholder={['Từ', 'Đến']}
                  ranges={dateRanges as any}
                  onChange={props.onChangeDateImport}
                  format={DATE_FORMAT}
                />
              </div>
            )}
          </Col>
        </Row>
      );
    }

    if (isAdminOrSaleManager) {
      return (
        <Row>
          <Col span={6}>
            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>Gói đang dùng</p>
              <Input
                size={size}
                style={inputStyle}
                onChange={props.onChangePackActive}
                onKeyDown={props.onPressEnter}
                value={props.filters.packActive}
              />
            </div>

            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>
                Gói đang dùng (cập nhật)
              </p>
              <Input
                size={size}
                style={inputStyle}
                onChange={props.onChangeCurrentRegisteredPackage}
                onKeyDown={props.onPressEnter}
              />
            </div>

            <div style={contentBox}>
              <p style={{ ...txtTitle, width: '10vw' }}>Gói cước phù hợp</p>
              <Select
                allowClear
                size={size}
                style={filterBoxStyle}
                mode="multiple"
                maxTagCount="responsive"
                onChange={props.onChangeSuggestedPackage}
                options={props.configPhonePackagesAutoSearch}
              />
            </div>
          </Col>

          <Col span={6}>
            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Trạng thái</p>
              <Select
                allowClear
                style={filterBoxStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={DATA_SHARE[0].value}
                onChange={props.onChangeShareData}
                options={DATA_SHARE}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Trạng thái tra cứu</p>
              <Select
                allowClear
                style={filterBoxStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={DATA_STATUS_SEARCH[0].value}
                onChange={props.onChangeStatusData}
                options={DATA_STATUS_SEARCH}
              />
            </div>
            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Nguồn data</p>
              <Select
                size={size}
                style={filterBoxStyle}
                placeholder={'Tất cả'}
                allowClear
                onChange={props.onChangeSource}
                options={DATA_SOURCE}
              />
            </div>
          </Col>

          <Col span={6}>
            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Ngày cập nhật</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeAutoSearchDate}
                format={DATE_FORMAT}
                dropdownClassName={className.join(' ')}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Ngày Import</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeDateImport}
                format={DATE_FORMAT}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'center' }}>
              <p style={txtTitle}>Loại thuê bao</p>
              <Select
                allowClear
                style={datePickerStyle}
                size={size}
                placeholder={'Tất cả'}
                defaultValue={SOURCES_SYS_LIST[0].value}
                onChange={props.onChangeSourceSys}
                options={SOURCES_SYS_LIST}
              />
            </div>
          </Col>

          <Col span={6}>
            <div style={{ ...contentBox, justifyContent: 'flex-end' }}>
              <p style={{ ...txtTitle, width: '5vw' }}>Ngày gia hạn</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeActivationDate}
                format={DATE_FORMAT}
              />
            </div>

            <div style={{ ...contentBox, justifyContent: 'flex-end' }}>
              <p style={{ ...txtTitle, width: '5vw' }}>Ngày hết hạn</p>
              <DatePicker.RangePicker
                allowClear
                size={size}
                style={datePickerStyle}
                placeholder={['Từ', 'Đến']}
                ranges={dateRanges as any}
                onChange={props.onChangeExpiredDate}
                format={DATE_FORMAT}
              />
            </div>
          </Col>
        </Row>
      );
    }
  };

  if (props.loadingReset) return <div style={{ height: 200 }} />;

  return (
    <div style={props.style}>
      <div
        className={`dpl-flex align-items-center pd-8 pdl-15 pdr-15 ${
          props.extendSearch ? 'box-showdow-bottom' : ''
        }`}
      >
        <InputNumber
          size={size}
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder={'Nhập Số điện thoại...'}
          controls={false}
          style={{ ...inputStyle, width: '24%', marginLeft: 0 }}
          onChange={props.onChangePhone}
          onKeyDown={props.onPressEnter}
        />
        {!props.extendSearch && (
          <>
            <Button
              onClick={props.onExtend}
              type="primary"
              size={size}
              style={{ marginLeft: 12 }}
            >
              Bộ lọc
            </Button>
            <span
              style={{
                color: 'rgba(0, 0, 0, 0.5)',
                marginLeft: 10,
              }}
            >
              Lọc theo trường: Trạng thái:{' '}
              <span style={{ fontWeight: 'bold' }}>Chưa chia</span>
            </span>
          </>
        )}

        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          {isAdminOrSaleManager && (
            <Tooltip
              title={
                props.autoSearchRunning && props.actionStaff
                  ? `${props.actionStaff} đang thực hiện tra cứu tự động`
                  : ''
              }
            >
              <Button
                className="mgr-10"
                onClick={props.onAutoSearch}
                disabled={props.autoSearchRunning}
                size={size}
                style={{
                  color: props.autoSearchRunning
                    ? 'hsl(0deg 0% 0% / 50%)'
                    : 'hsl(215deg 100% 54%)',
                  border: '1px solid hsl(215deg 100% 54%)',
                }}
                loading={props.autoSearchRunning}
                icon={<SearchOutlined />}
              >
                Tra cứu tự động
              </Button>
            </Tooltip>
          )}

          <Button
            size={size}
            className="btn-filter mgr-10"
            onClick={props.onShareData}
            icon={<IconShareData style={{ width: 25, height: 15 }} />}
            type="default"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Chia data
          </Button>

          <Button
            type="default"
            size={size}
            className="btn-export"
            onClick={props.onExport}
            icon={<DownloadOutlined />}
            loading={props.isLoadingExport}
          >
            Export
          </Button>
        </div>
      </div>

      <div
        className={
          props.extendSearch
            ? 'data-manager-filters box-showdow-bottom'
            : 'hide-box'
        }
      >
        {props.extendSearch && renderFilters()}
        {props.extendSearch && (
          <FiltersButton
            size={size}
            onSearch={props.onSearch}
            onExtend={props.onExtend}
            onReset={props.onReset}
          />
        )}
      </div>
    </div>
  );
};

export default DataManagerFilter;
