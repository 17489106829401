import { Space, Spin, Table } from 'antd';
import saveAs from 'file-saver';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SwitchNumberRatio from 'src/components/SwitchNumberRatio';
import levelSaleService from 'src/services/levelSale.service';
import registerPackageServices from 'src/services/registerPackage.service';
import reportsServices from 'src/services/reports.service';
import { filterStyles } from 'src/styles';
import { convertToListChildLevelSale } from 'src/utils';
import {
  DEFAULT_PAGE_SIZE,
  GLOBAL_HEIGHT,
  PAGE_SIZE_LIST,
} from 'src/utils/constants';
import { useWindowSize } from 'src/utils/customHooks';
import { modifyArrTreeSelect } from 'src/utils/stringUtils';
import { convertTimeStamp } from 'src/utils/time';
import { getValuesNodeTree } from 'src/utils/treeSelect';
import { ItemColumn, TabColumn } from '../ReportsRatioTotal/components';
import Actions from '../ReportsRatioTotal/components/Actions';
import Filters from '../ReportsRatioTotal/components/Filters';
import { FirstTab } from '../ReportsRatioTotal/components/FirstTab';

type TakeCareByDayFilterParams = {
  staffs: any;
  currentPackages: any;
  assignedDateFrom: number;
  assignedDateTo: number;
};

export type State = {
  loading: boolean;
  staff: any;
  selectedStaff: any;
  listPackage: any;
  isLoadingExport: boolean;
  reportsTotal: any[];
  filters: TakeCareByDayFilterParams;
  levelsSale: LevelSaleItem[];
  metadata?: MetadataInterface;
  isSwitchNumber: boolean;
  isSwitchRatio: boolean;
};

const TakeCareByDay: React.FC = () => {
  const defaultParams: TakeCareByDayFilterParams = {
    staffs: null,
    currentPackages: null,
    assignedDateFrom: convertTimeStamp(
      moment().startOf('month').startOf('day')
    ),
    assignedDateTo: convertTimeStamp(moment().endOf('day')),
  };

  const windowSize = useWindowSize();

  const [state, setState] = useState<State>({
    loading: false,
    staff: [],
    selectedStaff: undefined,
    reportsTotal: [],
    listPackage: [],
    isLoadingExport: false,
    filters: defaultParams,
    levelsSale: [],
    metadata: undefined,
    isSwitchNumber: true,
    isSwitchRatio: true,
  });

  const columns: any = [
    {
      title: () => <FirstTab />,
      dataIndex: 'day',
      key: 'day',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <>
          {index ? (
            <>{moment(cell).format('DD/MM/YYYY')}</>
          ) : (
            <p style={{ fontSize: 15 }}>{cell}</p>
          )}
        </>
      ),
    },
    {
      title: () => <TabColumn title={'L0'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l0Total',
      key: 'l0Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn index={index} total={cell} percent={''} />
      ),
    },
    {
      title: () => <TabColumn title={'L1'} levels={state.levelsSale} />,
      dataIndex: 'l1Total',
      key: 'l1Total',
      width: '5%',
      render: (cell: any, _: any, index: number) => (
        <ItemColumn index={index} total={cell} percent={''} />
      ),
    },
    {
      title: () => <TabColumn title={'L1.2'} levels={state.levelsSale} />,
      dataIndex: 'l1_2Total',
      key: 'l1_2Total',
      width: '5%',
      render: (
        _: string,
        { l1_2Total, l1_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_2Total}
          percent={`${l1_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L1.3'} levels={state.levelsSale} />,
      dataIndex: 'l1_3Total',
      key: 'l1_3Total',
      width: '5%',
      render: (
        _: string,
        { l1_3Total, l1_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l1_3Total}
          percent={`${l1_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2'} levels={state.levelsSale} />,
      dataIndex: 'l2Total',
      key: 'l2Total',
      width: '5%',
      className: 'bg-gray',
      render: (_: string, { l2Total, l2TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l2Total}
          percent={`${l2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2.2'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_2Total',
      key: 'l2_2Total',
      width: '5%',
      render: (
        _: string,
        { l2_2Total, l2_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_2Total}
          percent={`${l2_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L2.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l2_3Total',
      key: 'l2_3Total',
      width: '5%',
      render: (
        _: string,
        { l2_3Total, l2_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l2_3Total}
          percent={`${l2_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3'} levels={state.levelsSale} />,
      dataIndex: 'l3Total',
      key: 'l3Total',
      width: '5%',
      render: (_: string, { l3Total, l3TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l3Total}
          percent={`${l3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3.2'} levels={state.levelsSale} />,
      dataIndex: 'l3_2Total',
      key: 'l3_2Total',
      width: '5%',
      render: (
        _: string,
        { l3_2Total, l3_2TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_2Total}
          percent={`${l3_2TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L3.3'} levels={state.levelsSale} />,
      dataIndex: 'l3_3Total',
      key: 'l3_3Total',
      width: '5%',
      render: (
        _: string,
        { l3_3Total, l3_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l3_3Total}
          percent={`${l3_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8'} levels={state.levelsSale} />,
      dataIndex: 'l8Total',
      key: 'l8Total',
      className: 'bg-gray',
      width: '5%',
      render: (_: string, { l8Total, l8TotalPercent }: any, index: number) => (
        <ItemColumn
          index={index}
          total={l8Total}
          percent={`${l8TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8.1'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_1Total',
      key: 'l8_1Total',
      width: '5%',
      render: (
        _: string,
        { l8_1Total, l8_1TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_1Total}
          percent={`${l8_1TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
    {
      title: () => <TabColumn title={'L8.3'} levels={state.levelsSale} />,
      className: 'bg-gray',
      dataIndex: 'l8_3Total',
      key: 'l8_3Total',
      width: '5%',
      render: (
        _: string,
        { l8_3Total, l8_3TotalPercent }: any,
        index: number
      ) => (
        <ItemColumn
          index={index}
          total={l8_3Total}
          percent={`${l8_3TotalPercent}%`}
          isShowNumber={state.isSwitchNumber}
          isShowRatio={state.isSwitchRatio}
        />
      ),
    },
  ];

  const fetchDataFilters = async () => {
    const response = await Promise.all([
      reportsServices.getListStaff(),
      registerPackageServices.getListPackage(),
      levelSaleService.getLevels(),
    ]);

    setState((cur: State) => ({
      ...cur,
      loading: true,
      staff: modifyArrTreeSelect(response?.[0]?.data),
      listPackage: response?.[1]?.data,
      levelsSale: convertToListChildLevelSale(response?.[2]?.data),
    }));
  };

  const handleChangePackage = (values: string[]) => {
    const currentPackages = values.toString();
    setState((cur) => ({
      ...cur,
      filters: { ...cur.filters, currentPackages },
    }));
  };

  const handleChangeAssignedDate = (dates: any) => {
    const startDate = dates?.[0]?.startOf('day')?.format();
    const endDate = dates?.[1]?.endOf('day')?.format();
    setState((cur) => ({
      ...cur,
      filters: {
        ...cur.filters,
        assignedDateFrom: startDate
          ? convertTimeStamp(moment(startDate))
          : startDate,
        assignedDateTo: endDate ? convertTimeStamp(moment(endDate)) : endDate,
      },
    }));
  };

  const handleChangeStaff = (values: any) => {
    const staffs = getValuesNodeTree(state?.staff, values);
    setState((cur) => ({
      ...cur,
      selectedStaff: values,
      filters: {
        ...cur.filters,
        staffs: staffs.toString(),
      },
    }));
  };

  const handleSearch = () => {
    setState((cur) => ({
      ...cur,
      loading: true,
      filters: {
        ...cur.filters,
      },
    }));
  };

  const handleExport = async () => {
    setState((cur) => ({
      ...cur,
      isLoadingExport: true,
    }));
    const resp = await reportsServices.exportTakeCareByDay(state.filters);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `CRM_Cham_soc_data_ngay_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
    setState((cur) => ({
      ...cur,
      isLoadingExport: false,
    }));
  };

  const onSearch = async () => {
    const response = await reportsServices.searchTakeCareByDay(state.filters);
    if (response?.status !== 200) {
      setState((cur) => ({
        ...cur,
        loading: false,
      }));
      return;
    }
    const { total, data } = response.data;
    const reportsTotal = [
      {
        day: 'Tổng',
        l0Total: total?.l0TotalForSum ?? 0,
        l1Total: total?.l1TotalForSum ?? 0,
        l1_2Total: total?.l1_2TotalForSum ?? 0,
        l1_2TotalPercent: total?.l1_2TotalPercentForSum ?? 0,
        l1_3Total: total?.l1_3TotalForSum ?? 0,
        l1_3TotalPercent: total?.l1_3TotalPercentForSum ?? 0,
        l2Total: total?.l2TotalForSum ?? 0,
        l2TotalPercent: total?.l2TotalPercentForSum ?? 0,
        l2_2Total: total?.l2_2TotalForSum ?? 0,
        l2_2TotalPercent: total?.l2_2TotalPercentForSum ?? 0,
        l2_3Total: total?.l2_3TotalForSum ?? 0,
        l2_3TotalPercent: total?.l2_3TotalPercentForSum ?? 0,
        l3Total: total?.l3TotalForSum ?? 0,
        l3TotalPercent: total?.l3TotalPercentForSum ?? 0,
        l3_2Total: total?.l3_2TotalForSum ?? 0,
        l3_2TotalPercent: total?.l3_2TotalPercentForSum ?? 0,
        l3_3Total: total?.l3_3TotalForSum ?? 0,
        l3_3TotalPercent: total?.l3_3TotalPercentForSum ?? 0,
        l8Total: total?.l8TotalForSum ?? 0,
        l8TotalPercent: total?.l8TotalPercentForSum ?? 0,
        l8_1Total: total?.l8_1TotalForSum ?? 0,
        l8_1TotalPercent: total?.l8_1TotalPercentForSum ?? 0,
        l8_3Total: total?.l8_3TotalForSum ?? 0,
        l8_3TotalPercent: total?.l8_3TotalPercentForSum ?? 0,
      },
      ...data,
    ];

    const { headers } = response;
    setState((cur) => ({
      ...cur,
      reportsTotal,
      loading: false,
      metadata: {
        pageCount: headers['x-page-count'],
        page: headers['x-page-number'],
        size: headers['x-page-size'],
        total: headers['x-total-count'],
      },
    }));
  };

  const onSwitchNumber = (checked: boolean) => {
    if (!checked && !state.isSwitchRatio) return;
    setState((cur) => ({
      ...cur,
      isSwitchNumber: checked,
    }));
  };

  const onSwitchRatio = (checked: boolean) => {
    if (!checked && !state.isSwitchNumber) return;
    setState((cur) => ({
      ...cur,
      isSwitchRatio: checked,
    }));
  };

  useEffect(() => {
    fetchDataFilters();
  }, []);

  useEffect(() => {
    if (state.loading) {
      onSearch();
    }
  }, [state.loading]);

  let tableHeight = windowSize?.height - GLOBAL_HEIGHT + 10;

  return (
    <div id="revenue-reports-total">
      <Spin spinning={state.loading} tip={'Loading...'}>
        <Space size={16} style={filterStyles}>
          <Filters
            state={state as any}
            handleChangePackage={handleChangePackage}
            handleChangeAssignedDate={handleChangeAssignedDate}
            handleChangeStaff={handleChangeStaff}
            allowClearRangePicker={false}
          />
          <Actions
            isLoadingExport={state?.isLoadingExport}
            onSearch={handleSearch}
            handleExport={handleExport}
          />
          <SwitchNumberRatio
            isSwitchNumber={state.isSwitchNumber}
            isSwitchRatio={state.isSwitchRatio}
            onSwitchNumber={onSwitchNumber}
            onSwitchRatio={onSwitchRatio}
          />
        </Space>
        <Table
          className={'table-reports-total'}
          rowKey="day"
          columns={columns}
          pagination={{
            size: 'small',
            showSizeChanger: true,
            defaultPageSize: DEFAULT_PAGE_SIZE,
            pageSizeOptions: PAGE_SIZE_LIST,
            showTotal: (total) => (
              <div>Tổng số bản ghi {total.toLocaleString()}</div>
            ),
          }}
          dataSource={state.reportsTotal}
          scroll={{ y: tableHeight }}
        />
      </Spin>
    </div>
  );
};

export default TakeCareByDay;
