import { CloseOutlined } from '@ant-design/icons';
import React, { FC, useContext } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { ReactComponent as IconMissedCall } from 'src/assets/dataDivided/missedCall.svg';
import callCenterService from 'src/services/callCenter.service';
import { CARE_SOFT_ACTION } from 'src/services/careSoft/useCareSoftHook';
import { ROUTERS } from 'src/utils/constants';

import './styles.scss';
import AppContext from 'src/context/AppContext';

const NotyMissedCall: FC = () => {
  const navigate = useNavigate();

  const { careSoftState, careSoftDispatch }: ContextType =
    useContext(AppContext);

  const onClose = async () => {
    const ids = careSoftState.missedCalls.map((item) => item.callId);
    await callCenterService.checkedMissedCalls(ids);
    careSoftDispatch({
      type: CARE_SOFT_ACTION.showMissedModal,
      isShowMissedModal: false,
    });
  };

  const onSeeMore = () => {
    navigate(ROUTERS.CallCenter.path);
  };

  if (!careSoftState.isShowMissedModal) return null;
  const totalMissedCall = careSoftState.missedCalls.length;

  return (
    <div id={'noty-missed-call'}>
      <div className={'header'}>
        <p className={'mrbt-10'}>
          Bạn có <span className={'text-bold'}>{totalMissedCall}</span> cuộc gọi
          nhỡ
        </p>
        <CloseOutlined onClick={onClose} className={'icon-close'} />
      </div>
      {careSoftState.missedCalls
        .slice(0, 5)
        .map((item: CallInfo, index: number) => (
          <ItemMissedCall item={item} key={index + item.msisdn} />
        ))}
      {totalMissedCall > 5 && (
        <a className={'text-underline'} onClick={onSeeMore}>
          Xem thêm
        </a>
      )}
    </div>
  );
};

export default NotyMissedCall;

type ItemProps = {
  item: CallInfo;
};

const ItemMissedCall: FC<ItemProps> = ({ item }) => {
  const navigate = useNavigate();

  const onClick = async () => {
    await callCenterService.checkedMissedCalls([item.callId]);
    const path = generatePath(ROUTERS.DataDividedDetail.path, {
      phone: item.msisdn,
    });
    navigate(path);
  };

  return (
    <div className={'item-missed-call'} onClick={onClick}>
      <IconMissedCall fill={'#EE3E3E'} className={'icon-missed'} />
      <div>
        <p className={'text color-blue'}>{item.msisdn}</p>
        <p className={'text'}>{item.missedTime}</p>
      </div>
    </div>
  );
};
