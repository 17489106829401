import { Button, Col, Form, Input, Modal, Row, Select, Typography } from 'antd';
import React, { Ref, useImperativeHandle } from 'react';
import { PACKAGE_STATUS } from 'src/utils/constants';
import { formatPrice } from 'src/utils/formatter';
import { isNullOrEmpty, validateNumberPrice } from 'src/utils/stringUtils';

type Props = {
  isEdit?: boolean;
  updatePackage: (params: ListPackage, id: number) => void;
  createPackage: (params: ListPackage) => void;
};
const CreatePackage = (
  { isEdit, updatePackage, createPackage }: Props,
  ref: Ref<any>
) => {
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [dataDetails, setDataDetails] = React.useState<
    ListPackage | undefined
  >();

  const handleClose = () => {
    form.resetFields();
    setModalOpen(false);
  };

  const handleFillData = (data?: ListPackage | undefined) => {
    if (isEdit) {
      form?.setFieldsValue({
        code: data?.code,
        status: data?.status,
        price: formatPrice(Number(data?.price)),
        shareValueMBF: formatPrice(Number(data?.shaveValueMBF) || 0),
        cycle: data?.cycle,
        promotionInformation: data?.promotionInformation,
        registrationConditions: data?.registrationConditions,
      });
    } else {
      form.resetFields();
    }
  };

  React.useEffect(() => {
    handleFillData(dataDetails);
  }, [dataDetails, isEdit]);

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (data?: ListPackage | undefined) => {
          setDataDetails(data);
          handleFillData(data);
          setModalOpen(true);
        },
        close: handleClose,
      };
    },
    [isEdit]
  );

  const onFinish = (values: ListPackage) => {
    const params = {
      ...values,
      price: (values?.price as string)?.replace(/\D/g, ''),
      shareValueMBF: (values?.shareValueMBF as string)?.replace(/\D/g, ''),
      promotionInformation: values?.promotionInformation?.trimStart(),
      registrationConditions: values?.registrationConditions?.trimStart(),
      code: values?.code?.trimStart(),
    };
    if (isEdit) {
      updatePackage(params, dataDetails?.id || 0);
    } else {
      createPackage(params);
    }
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={handleClose}
      centered
      footer={
        <div className="mb-3">
          <Button className="mr-3" onClick={handleClose}>
            Huỷ bỏ
          </Button>
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            {isEdit ? `Sửa` : 'Thêm mới'}
          </Button>
        </div>
      }
      width={800}
    >
      <Form form={form} layout="vertical" id="myForm" onFinish={onFinish}>
        <div className="flex items-center justify-center">
          <Typography className="font-semibold" style={{ fontSize: 20 }}>
            {isEdit ? `THÔNG TIN GÓI CƯỚC` : 'THÊM MỚI GÓI CƯỚC'}
          </Typography>
        </div>
        <Row gutter={20} className="mt-5">
          <Col span={8}>
            <Form.Item
              label={'Tên gói cước'}
              name={'code'}
              required
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập tên gói cước!',
                },
                {
                  pattern: /^[^\s!@#$%^&*()_+={}\[\]:;<>,.?~\\-]+$/,
                  message: 'Không được nhập khoảng trắng hoặc ký tự đặc biệt!',
                },
              ]}
            >
              <Input placeholder="Tên gói cước" style={{ borderRadius: 5 }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={'Trạng thái'}
              name={'status'}
              required
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn trạng thái!',
                },
              ]}
            >
              <Select
                placeholder="Trạng thái"
                options={PACKAGE_STATUS}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20} className="mt-5">
          <Col span={8}>
            <Form.Item
              label={'Doanh thu'}
              name={'price'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Doanh thu !',
                  validator: validateNumberPrice,
                },
              ]}
            >
              <Input
                placeholder="Nhập doanh thu"
                style={{ borderRadius: 5 }}
                onChange={(e: { target: { value: string } }) => {
                  form.setFieldsValue({
                    price: e.target?.value
                      ?.replace(/\D/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={'Hoa hồng'}
              name={'shareValueMBF'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Hoa hồng !',
                  validator: validateNumberPrice,
                },
              ]}
            >
              <Input
                placeholder="Nhập hoa hồng"
                style={{ borderRadius: 5 }}
                onChange={(e: { target: { value: string } }) => {
                  form.setFieldsValue({
                    shareValueMBF: e.target?.value
                      ?.replace(/\D/g, '')
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={'Chu kỳ'}
              name={'cycle'}
              required
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập Chu kỳ !',
                  validator: validateNumberPrice,
                },
              ]}
            >
              <Input placeholder="Nhập chu kỳ" style={{ borderRadius: 5 }} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={
            <Typography className="font-semibold">Thông tin ưu đãi</Typography>
          }
          name={'promotionInformation'}
          rules={[
            {
              pattern: /^[\s\S]*\S[\s\S]*$/,
              message: 'Không được nhập toàn bộ là dấu cách!',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Nhập thông tin ưu đãi"
            style={{ height: 120, borderRadius: 5 }}
          />
        </Form.Item>
        <Form.Item
          label={
            <Typography className="font-semibold">Điều kiện đăng ký</Typography>
          }
          name={'registrationConditions'}
          style={{ marginBottom: -10 }}
          rules={[
            {
              pattern: /^[\s\S]*\S[\s\S]*$/,
              message: 'Không được nhập toàn bộ là dấu cách!',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Nhập điều kiện đăng ký"
            style={{ height: 120, borderRadius: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export const ModalCreatePackage = React.forwardRef(CreatePackage);
