export const scriptCustom = `
$(window).bind('beforeunload', function (e) {
  if (1 == 1) {
      return 'abc';
  }
});

$(window).bind('unload', function () {
  csUnregister();
  if (csVoice.enableVoice) {
      reConfigDeviceType();
  }
});

// kết thúc cuộc gọi ra/vào
function csEndCall() {
  console.log("Call is ended"); 
  $('#transferCall').attr('disabled', 'disabled');
  $('#transferCallAcd').attr('disabled', 'disabled');
  window.dispatchEvent(new CustomEvent("endcall"));
  $(window).off('beforeunload');
}

// đổ chuông trình duyệt của agent khi gọi ra
function csCallRingingAgent() {
  console.log("Has a new call to agent");
}

// đổ chuông trình duyệt của agent khi gọi vào
// đổ chuông tới khách hàng khi gọi ra
function csCallRinging(phone) {
  console.log("Has a new call to customer: " + phone);
  window.dispatchEvent(new CustomEvent("callRinging", {detail: {phone}}));
}

// cuộc gọi vào được agent trả lời
function csAcceptCall() {
  console.log("Call is Accepted");
  $('#transferCall').removeAttr('disabled');
  $('#transferCallAcd').removeAttr('disabled');
  window.dispatchEvent(new CustomEvent("acceptCall"));
}

// cuộc gọi ra được khách hàng trả lời
function csCustomerAccept() {
  console.log("csCustomerAccept");
  window.dispatchEvent(new CustomEvent("calloutAccept"));
}

function csMuteCall() {
  console.log("Call is muted");
}

function csUnMuteCall() {
  console.log("Call is unmuted")
}

function csHoldCall() {
  console.log("Call is holded");
  window.dispatchEvent(new CustomEvent("holdcall", {detail: {status: true}}));
}

function csUnHoldCall() {
  console.log("Call is unholded");
  window.dispatchEvent(new CustomEvent("holdcall", {detail: {status: false}}));
}

function showCalloutInfo(number) {
  console.log("callout to " + number);
}

function showCalloutError(errorCode, sipCode) {
  console.log("callout error " + errorCode + " - " + sipCode);
  window.dispatchEvent(new CustomEvent("calloutError", {detail: {errorCode, sipCode}}));
}

function csShowEnableVoice(enableVoice) {
  if (enableVoice) {
      $('#enable').attr('disabled', 'disabled');
  } else {
      $('#enable').removeAttr('disabled');
  }
}

function csShowDeviceType(type) {
  console.log("csShowDeviceType");
}

function csShowCallStatus(status) {
  console.log("csShowCallStatus");
}

function csInitComplete() {
  // bật quyền được gọi cho tab hiện tại
  // if (!csVoice.enableVoice) {
  //     csEnableCall();
  // }
  if (csVoice.callStatus === "Offline") {
      changeCallStatus();
  }
  console.log("csInitComplete", csVoice);
  window.dispatchEvent(new CustomEvent("initComplete", {detail: {status: false}}));
}

function csCurrentCallId(callId) {
  console.log("csCurrentCallId: " + callId);
  window.dispatchEvent(new CustomEvent("callout", {detail: {callId}}));
}

function csInitError(error) {
  console.log("csInitError: " + error);
  window.dispatchEvent(new CustomEvent("initError", {detail: {error}}));
}

function csListTransferAgent(listTransferAgent) {
  console.log(listTransferAgent);
}
function csTransferCallError(error, tranferedAgentInfo) {
  console.log('Transfer call failed,' + error);
}
function csTransferCallSuccess(tranferedAgentInfo) {
  console.log('transfer call success');
}
function csNewCallTransferRequest(transferCall) {
  console.log('new call transfer', transferCall);
  $('#transferResponseOK').removeAttr('disabled');
  $('#transferResponseReject').removeAttr('disabled');
}

function csTransferCallResponse(status) {
  $('#transferResponseOK').attr('disabled', 'disabled');
  $('#transferResponseReject').attr('disabled', 'disabled');
  console.log('csTransferCallResponse', status);
}
`;
