import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, notification } from 'antd';
import { Helmet } from 'react-helmet';
import { saveAs } from 'file-saver';
import moment from 'moment';

import {
  convertDateToTimeStamp,
  isAdmin,
  isSaleLeader,
  isSaleManager,
  projectId,
  projectName,
} from 'src/utils';
import dataManagerServices from 'src/services/dataManager.service';
import { isNullOrEmpty, modifyArrTreeSelect } from 'src/utils/stringUtils';
import { useWindowSize } from 'src/utils/customHooks';
import registerPackageServices from 'src/services/registerPackage.service';
import { paginationStyles } from 'src/styles';
import {
  DATA_SHARE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_LIST,
} from 'src/utils/constants';

import ManagerDividedDataModal from './ManagerDividedDataModal';
import LeaderDividedDataModal from './LeaderDividedDataModal';
import DataManagerFilter from './DataManagerFilter';
import { getColumnDataManager } from './ColumnDataManager';
import './style.scss';

const defaultParams = {
  msisdn: undefined,
  sourceSys: undefined,
  source: undefined,
  packActive: undefined,
  extendNum: undefined,
  packageActivationDate: undefined,
  expiredDate: undefined,
  importDateFrom: undefined,
  importDateTo: undefined,
  assignedDateFrom: undefined,
  assignedDateTo: undefined,
  assignedStatus: isSaleLeader ? DATA_SHARE[1].value : DATA_SHARE[0].value,
  page: 0,
  size: DEFAULT_PAGE_SIZE,
  currentRegisteredPackage: undefined,
  suggestedPackage: undefined,
  autoSearchedDateFrom: undefined,
  autoSearchedDateTo: undefined,
};

type State = {
  data: DataItem[];
  metadata?: MetadataInterface;
  loading: boolean;
  loadingAutoSearch: boolean;
  loadingReset: boolean;
  autoSearchRunning: boolean;
  actionStaff?: string;
  configPhonePackagesAutoSearch: { label: string; value: string }[];
};

function DataManager() {
  const [filters, setFilters] = useState<SearchListDataParams>(defaultParams);

  const windowSize = useWindowSize();
  const [state, setState] = useState<State>({
    data: [],
    metadata: undefined,
    loading: true,
    loadingAutoSearch: false,
    loadingReset: false,
    autoSearchRunning: false,
    configPhonePackagesAutoSearch: [],
    actionStaff: '',
  });

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [extendSearch, setExtendSearch] = useState<boolean>(false);
  const [listStaff, setListStaff] = useState<any[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);

  const getListStaff = async () => {
    const resp = await registerPackageServices.getListStaffTree();
    const data = resp?.data;
    const modifiedData = data
      .filter((item: any) => item.active !== 0)
      .map((value: any) => {
        return {
          ...value,
          saleManList: value?.saleManList?.filter((x: any) => x.active !== 0),
        };
      });

    if (resp?.status === 200) {
      let newArr = modifyArrTreeSelect(modifiedData);
      setListStaff(newArr);
    }
  };

  const getListData = async () => {
    const resp = await dataManagerServices.getListData(filters);
    const { data, headers } = resp;
    if (resp?.status === 200) {
      setState((cur: State) => ({
        ...cur,
        data,
        loading: false,
        loadingReset: false,
        metadata: {
          pageCount: headers['x-page-count'],
          page: headers['x-page-number'],
          size: headers['x-page-size'],
          total: headers['x-total-count'],
        },
      }));
    } else {
      setState((cur: State) => ({
        ...cur,
        loading: false,
        loadingReset: false,
      }));
    }
  };

  const getListDataAutoSearch = async () => {
    const resp = await dataManagerServices.getListDataAutoSearch(filters);
    if (resp?.status === 200) {
      getListData();
      setState((cur: State) => ({
        ...cur,
        loadingAutoSearch: false,
      }));
    } else {
      setState((cur: State) => ({ ...cur, loadingAutoSearch: false }));
    }
  };

  const getConfigPhonePackagesAutoSearch = async () => {
    const resp = await dataManagerServices.getConfigPhonePackagesAutoSearch();
    if (resp?.status === 200) {
      const configPhonePackagesAutoSearch = resp?.data?.map((item: string) => ({
        label: item,
        value: item,
      }));
      setState((cur: State) => ({
        ...cur,
        configPhonePackagesAutoSearch,
      }));
    }
  };

  const checkAutoSearchRunning = async () => {
    const resp = await dataManagerServices.checkAutoSearchRunning();
    if (resp?.status === 200) {
      const { autoSearchRunning, actionStaff } = resp?.data;
      setState((cur: State) => ({
        ...cur,
        autoSearchRunning,
        actionStaff,
      }));
    }
  };

  const onSearch = () => {
    setState((cur: State) => ({ ...cur, loading: true }));
    setFilters((prevState) => ({ ...prevState, page: 0 }));
    getListData();
  };

  const onReset = () => {
    setFilters(defaultParams);
    setState((cur: State) => ({ ...cur, loading: true, loadingReset: true }));
    getListData();
  };

  const handleAutoSearch = () => {
    setState((cur: State) => ({
      ...cur,
      loadingAutoSearch: true,
      autoSearchRunning: true,
    }));
    // trước khi filter >> trường “trạng thái data” không chọn “Chưa chia”  hoặc chọn khác loại
    const isAssignedStatusNew =
      defaultParams.assignedStatus === DATA_SHARE[0].value;
    // trong danh sách đã filter không có data ở trạng thái “chưa chia”

    if (!isAssignedStatusNew || isNullOrEmpty(state.data)) {
      const description = isAssignedStatusNew
        ? 'Tra cứu TĐ chỉ dành cho trạng thái data chưa chia'
        : 'Chưa có data để tra cứu tự động';
      notification.error({
        message: 'Tra cứu thất bại',
        description,
        maxCount: 1,
      });
      return setState((cur: State) => ({ ...cur, loadingAutoSearch: false }));
    }

    setFilters((prevState) => ({ ...prevState, page: 0 }));
    getListDataAutoSearch();
    checkAutoSearchRunning();
  };

  const onExport = async () => {
    setIsLoadingExport(true);
    const resp = await dataManagerServices.exportFile(filters);
    const data = resp?.data;
    if (resp?.status === 200) {
      const fileName = `${projectId}_Data_Export_${moment().format(
        'YYYYMMDD'
      )}_${moment().unix()}.xlsx`;
      saveAs(data, fileName);
    }
    setIsLoadingExport(false);
  };

  const onExtend = () => {
    setExtendSearch(!extendSearch);
  };

  const onShowSizeChange = (page: number, pageSize: number) => {
    setFilters((prevState) => ({
      ...prevState,
      page: page - 1 > 0 ? page - 1 : 0,
      size: pageSize,
    }));
    setState((cur: State) => ({ ...cur, loading: true }));
    getListData();
  };

  const onChangeShareData = (share: string) => {
    setFilters((prevState) => ({
      ...prevState,
      assignedStatus: share ?? undefined,
    }));
  };

  const onChangeStatusData = (status: string) => {
    setFilters((prevState) => ({
      ...prevState,
      isAutoSearched: status !== '-1' ? status : undefined,
    }));
  };

  const onChangeDateImport = (values: any) => {
    setFilters((prevState) => ({
      ...prevState,
      importDateFrom: values
        ? convertDateToTimeStamp(values[0], true)
        : undefined,
      importDateTo: values
        ? convertDateToTimeStamp(values[1], false)
        : undefined,
    }));
  };

  const onChangeExpiredDate = (values: any) => {
    setFilters((prevState) => ({
      ...prevState,
      expiredDateFrom: values
        ? convertDateToTimeStamp(values[0], true)
        : undefined,
      expiredDateTo: values
        ? convertDateToTimeStamp(values[1], false)
        : undefined,
    }));
  };

  const onChangeAutoSearchDate = (values: any) => {
    setFilters((prevState) => ({
      ...prevState,
      autoSearchedDateFrom: values
        ? convertDateToTimeStamp(values[0], true)
        : undefined,
      autoSearchedDateTo: values
        ? convertDateToTimeStamp(values[1], false)
        : undefined,
    }));
  };

  const onChangeDividedDate = (values: any) => {
    setFilters((prevState) => ({
      ...prevState,
      assignedDateFrom: values
        ? convertDateToTimeStamp(values[0], true)
        : undefined,
      assignedDateTo: values
        ? convertDateToTimeStamp(values[1], false)
        : undefined,
    }));
  };

  const onChangeActivationDate = (
    values: any,
  ) => {
    setFilters((prevState) => ({
      ...prevState,
      packageActivationDateFrom: values
        ? convertDateToTimeStamp(values[0], true)
        : undefined,
      packageActivationDateTo: values
        ? convertDateToTimeStamp(values[1], false)
        : undefined,
    }));
  };

  const showModal = () => {
    if (!!state.metadata && state.metadata?.total > 0) {
      setIsShowModal(true);
    } else {
      notification.error({
        message: 'Chưa có data để chia',
        description: 'Thất bại',
      });
    }
  };

  const onChangePhone = (phone: string | null) => {
    setFilters((prevState) => ({
      ...prevState,
      msisdn: phone ?? undefined,
    }));
  };

  const onPressEnter = (e: any) => {
    if (e.keyCode === 13) {
      onSearch();
      setExtendSearch(false);
    }
  };

  const onChangeSourceSys = (value: string) => {
    console.log(value);
    setFilters((prevState) => ({
      ...prevState,
      sourceSys: value ?? undefined,
    }));
  };

  const onChangeSuggestedPackage = (values: string[]) => {
    setFilters((prevState) => ({
      ...prevState,
      suggestedPackage: values.toString() ?? undefined,
    }));
  };

  const onChangePackActive = (e: any) => {
    setFilters((prevState) => ({
      ...prevState,
      packActive: e.target.value?.trim() ?? undefined,
    }));
  };

  const onChangeCurrentRegisteredPackage = (e: any) => {
    setFilters((prevState) => ({
      ...prevState,
      currentRegisteredPackage: e.target.value?.trim() ?? undefined,
    }));
  };

  const onChangeSource = (value: string) => {
    setFilters((prevState) => ({
      ...prevState,
      source: value ?? undefined,
    }));
  };

  useEffect(() => {
    getListData();
    getListStaff();
    getConfigPhonePackagesAutoSearch();
    checkAutoSearchRunning();
  }, []);

  const tableScroll = {
    y: windowSize?.width < 1700 && windowSize?.height < 1000 ? '52vh' : '59vh',
    x: 2000,
  };

  if (!extendSearch) {
    tableScroll.y =
      windowSize?.width < 1700 && windowSize?.height < 1000 ? '78vh' : '82vh';
  }

  return (
    <Spin spinning={state.loading}>
      <Helmet>
        <title>Data chưa chia</title>
        <meta name="description" content={projectName} />
      </Helmet>
      <div id="data-manager" style={{ flex: 1 }}>
        {(isAdmin || isSaleManager) && isShowModal && (
          <ManagerDividedDataModal
            setOpenProps={setIsShowModal}
            metadataProps={state.metadata}
            filters={filters}
            onSuccess={onSearch}
          />
        )}
        {isSaleLeader && isShowModal && (
          <LeaderDividedDataModal
            setOpenProps={setIsShowModal}
            metadataProps={state.metadata}
            filters={filters}
            onSuccess={onSearch}
          />
        )}
        <DataManagerFilter
          filters={filters}
          isLoadingExport={isLoadingExport}
          autoSearchRunning={state.autoSearchRunning}
          actionStaff={state.actionStaff}
          loadingAutoSearch={state.loadingAutoSearch}
          loadingReset={state.loadingReset}
          configPhonePackagesAutoSearch={state?.configPhonePackagesAutoSearch}
          extendSearch={extendSearch}
          onChangePhone={onChangePhone}
          onPressEnter={onPressEnter}
          onShareData={showModal}
          onAutoSearch={handleAutoSearch}
          onChangeCurrentRegisteredPackage={onChangeCurrentRegisteredPackage}
          onChangePackActive={onChangePackActive}
          onChangeShareData={onChangeShareData}
          onChangeStatusData={onChangeStatusData}
          onChangeSuggestedPackage={onChangeSuggestedPackage}
          onChangeSourceSys={onChangeSourceSys}
          onChangeSource={onChangeSource}
          onChangeAutoSearchDate={onChangeAutoSearchDate}
          onChangeExpiredDate={onChangeExpiredDate}
          onChangeActivationDate={onChangeActivationDate}
          onChangeDateImport={onChangeDateImport}
          onChangeDividedDate={onChangeDividedDate}
          onExtend={onExtend}
          onReset={onReset}
          onSearch={onSearch}
          onExport={onExport}
        />
        <Table
          rowKey={'id'}
          dataSource={state.data}
          columns={getColumnDataManager(onSearch, listStaff)}
          pagination={false}
          scroll={tableScroll}
        />

        <Pagination
          size={'small'}
          showSizeChanger
          style={paginationStyles}
          onChange={onShowSizeChange}
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={filters.page ?? 0 + 1}
          total={state.metadata?.total}
          defaultPageSize={filters.size}
          pageSizeOptions={PAGE_SIZE_LIST}
          showTotal={(total) => `Tổng số bản ghi ${total}`}
        />
      </div>
    </Spin>
  );
}

export default DataManager;
