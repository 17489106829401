import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React from 'react';
import { isUserHaveOneOfPermissions } from 'src/utils/stringUtils';

type Props = {
  isLoadingExport: boolean;
  onSearch: () => void;
  handleExport: () => void;
};
const Actions: React.FC<Props> = (props) => {
  const { isLoadingExport, onSearch, handleExport } = props;
  const isExport = isUserHaveOneOfPermissions([
    'ROLE_ADMIN',
    'ROLE_ACCOUNTANT',
    'ROLE_SALE_LEADER',
    'ROLE_SALE_MANAGER',
  ]);

  return (
    <Space size={8}>
      <Button onClick={onSearch} type="primary" icon={<SearchOutlined />}>
        Tìm kiếm
      </Button>
      {isExport && (
        <Button
          className="btn-export"
          onClick={handleExport}
          type="default"
          icon={<DownloadOutlined />}
          loading={isLoadingExport}
        >
          Export
        </Button>
      )}
    </Space>
  );
};

export default Actions;
