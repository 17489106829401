import React, { useEffect, useState, useRef } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  Select,
  notification,
} from 'antd';

import {
  DEPARTMENT,
  REGEX_PASSWORD,
  REGEX_USERNAME,
  ROLES_LIST,
  ROLES_LIST_MANAGER,
} from '../../utils/constants';
import { isHavePermission } from 'src/utils/stringUtils';
import userServices from 'src/services/users.service';

type Props = {
  managers: any;
  dataDetail: any;
  modalStatus: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

function ModalCreate(props: Props) {
  const formRef = useRef();
  const { modalStatus, handleOk, handleCancel, dataDetail, managers } = props;
  const [isManagerDisabled, setIsManagerDisabled] = useState(true);
  const isSaleManager = isHavePermission('ROLE_SALE_MANAGER');
  const ipPhoneData = useRef(dataDetail.ipPhone);
  const [valueSelect, setValueSelect] = useState(dataDetail.active);
  const [rolesData, setRolesData] = useState(dataDetail.roles[0]);

  useEffect(() => {
    if (dataDetail) {
      if (formRef?.current) {
        formRef?.current?.setFieldsValue({
          ...dataDetail,
          active: valueSelect,
          ipPhone: valueSelect === 1 ? ipPhoneData.current : '',
          password: dataDetail?.id ? '1234123a@' : dataDetail?.password,
        });
      }
    }
  }, [dataDetail, valueSelect]);

  useEffect(() => {
    if (rolesData === 'ROLE_SALE_MAN') {
      setIsManagerDisabled(false);
    } else {
      setIsManagerDisabled(true);
    }
  }, [rolesData]);

  const handleOnChangeRoles = (value: any) => {
    setRolesData(value);
  };

  const onFormLayoutChange = (values: any) => {
    if (values.hasOwnProperty('roles')) {
      if (
        Array.isArray(values['roles']) &&
        values['roles'].find((x) => x === 'ROLE_SALE_MAN')
      ) {
        setIsManagerDisabled(false);
      } else {
        setIsManagerDisabled(true);
        if (formRef?.current) {
          formRef?.current?.setFieldValue('manager', '');
        }
      }
    }
  };

  const onFinish = async (values: any) => {
    let resp: any = {};

    const convertValues = {
      ...values,
      roles: typeof values.roles === 'object' ? values.roles : [values.roles],
    };

    if (dataDetail?.id) {
      const body = { ...convertValues };
      delete body.password;
      delete body.username;
      if (dataDetail.ipPhone === values.ipPhone) {
        delete body.ipPhone;
      }

      if (values.manager === '') {
        delete body.manager;
      }

      resp = await userServices.editAccount(dataDetail?.id, body);
      if (resp?.status === 200) {
        notification.success({
          message: 'Chỉnh sửa tài khoản',
          description: 'Thành công',
        });
        if (handleOk) handleOk();
      } else {
        if (resp.data.errorCode === 'duplicate_ip_phone') {
          notification.error({
            message: 'Ip Phone đã tồn tại trong hệ thống',
          });
        } else {
          if (resp?.status === 500) {
            notification.error({
              message: 'Có lỗi xảy ra với hệ thống ',
            });
            return;
          }

          notification.error({
            message: 'Có lỗi xảy ra',
          });
        }
      }
    } else {
      if (convertValues.manager === '') {
        delete convertValues.manager;
      }

      resp = await userServices.createAccount(convertValues);
      if (resp?.status === 200) {
        notification.success({
          message: 'Thêm mới tài khoản',
          description: 'Thành công',
        });
        if (handleOk) handleOk();
      } else {
        if (resp?.data?.errorCode === 'duplicate_user' && formRef?.current) {
          formRef?.current?.setFields([
            {
              name: 'username',
              errors: ['Tên đăng nhập đã tồn tại!'],
            },
          ]);
        }

        if (resp.data.errorCode === 'duplicate_ip_phone') {
          notification.error({
            message: 'Ip Phone đã tồn tại trong hệ thống',
          });
        } else {
          notification.error({
            message: 'Có lỗi xảy ra',
          });
        }
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const ROLES_LIST_PERMISSION = isSaleManager ? ROLES_LIST_MANAGER : ROLES_LIST;
  return (
    <Modal
      title={dataDetail?.id ? 'Chỉnh sửa tài khoản' : 'Thêm mới tài khoản'}
      visible={modalStatus}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Hủy bỏ
        </Button>,
        <Button form="myForm" key="submit" htmlType="submit" type="primary">
          {dataDetail?.id ? 'Lưu' : 'Thêm mới'}
        </Button>,
      ]}
    >
      <Form
        ref={formRef}
        id="myForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onFormLayoutChange}
        layout="vertical"
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Tên đăng nhập"
              name="username"
              validateTrigger={'onBlur'}
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                {
                  pattern: new RegExp(REGEX_USERNAME),
                  message: 'Tên đăng nhập không hợp lệ!',
                },
                { min: 4, message: 'Tên đăng nhập không thể ngắn hơn 4 ký tự' },
                {
                  max: 60,
                  message: 'Tên đăng nhập không thể dài hơn 60 ký tự',
                },
              ]}
            >
              <Input disabled={!!dataDetail?.id} placeholder="Nhập Username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Họ và tên"
              name="fullName"
              rules={[
                { required: true, message: 'Vui lòng nhập họ tên!' },
                { max: 30, message: 'Họ và tên không thể dài hơn 30 ký tự' },
              ]}
            >
              <Input placeholder="Nhập họ và tên" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Mật khẩu"
              name="password"
              validateTrigger={'onBlur'}
              rules={[
                {
                  required: true,
                  pattern: new RegExp(REGEX_PASSWORD),
                  message:
                    'Mật khẩu có ít nhất 8 ký tự bao gồm: tối thiểu 1 chữ cái, 1 số.',
                },
              ]}
            >
              <Input.Password
                disabled={!!dataDetail?.id}
                placeholder="Nhập Username"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Roles"
              name="roles"
              rules={[{ required: true, message: 'Vui lòng chọn Roles!' }]}
            >
              <Select allowClear onChange={handleOnChangeRoles}>
                {ROLES_LIST_PERMISSION.map((item, index) => (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label="Người quản lý" name="manager">
              <Select
                disabled={isManagerDisabled}
                placeholder="Chọn người quản lý"
              >
                {Array.isArray(managers) &&
                  managers.map((x: any, y) => (
                    <Select.Option key={y} value={x.username}>
                      {x?.fullName} ({x?.username})
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="IP phone CareSoft" name="ipPhone">
              <Input placeholder="Nhập IP phone" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Trạng thái tài khoản"
              name="active"
              rules={[{ required: true, message: 'Vui lòng chọn trạng thái!' }]}
            >
              <Select
                onChange={(value: any) => {
                  setValueSelect(value);
                }}
              >
                <Select.Option value={1}>Đang hoạt động</Select.Option>
                <Select.Option value={0}>Ngừng hoạt động</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phòng ban"
              name="department"
              rules={[{ required: true, message: 'Vui lòng chọn phòng ban' }]}
            >
              <Select>
                {DEPARTMENT.map((item, index) => (
                  <Select.Option key={index} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ModalCreate;
