import React, { useEffect, useState } from 'react';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, InputNumber, notification, Table, Empty } from 'antd';
import searchServices from '../../services/search.service';
import {
  arrStringToString,
  convertSuggestedPackages,
  updateQueryStringParameter,
} from '../../utils/stringUtils';
import { useQuery, useWindowSize } from '../../utils/customHooks';
import { useNavigate } from 'react-router-dom';
import ModalInfo from './ModalInfo';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { projectName } from 'src/utils';
import { inputStyleDivided } from 'src/styles';

function Search() {
  const navigate = useNavigate();
  const query = useQuery();
  const windowSize = useWindowSize();

  const msisdnQuery = query.get('msisdn')
    ? parseFloat(query.get('msisdn'))
    : '';

  const type = query.get('type');

  const [data, setData] = useState();
  const [modalData, setModalData] = useState();
  const [accountInfoDetail, setAccountInfoDetail] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [msisdn, setMsisdn] = useState(msisdnQuery);

  useEffect(() => {
    if (msisdnQuery) {
      getListData();
    }
  }, [msisdnQuery, type]);

  const getListData = async () => {
    if (!msisdn) {
      return;
    }
    setIsLoading(true);
    let query = buildQueryString();
    let resp = '';
    if (!type) {
      resp = await searchServices.search(query);
    } else if (type === 'b9') {
      resp = await searchServices.b9Search(query);
    } else if (type === 'account') {
      resp = await searchServices.accountInfoSearch(query);
    }

    const data = resp?.data;
    if (resp?.status === 200) {
      if (type === 'b9') {
        setModalData(data);
        showModal();
      } else if (type === 'account') {
        setAccountInfoDetail(data);
      } else {
        setData(data);
      }
    } else {
      notification.error({
        message:
          data?.message ||
          'Hệ thống có lỗi vui lòng liên hệ kỹ thuật viên để được hỗ trợ.',
      });
    }
    setIsLoading(false);
  };

  /**
   * xây dựng query string theo các giá trị trong state
   * @returns {string}
   */
  const buildQueryString = (data = {}, isUrl, isClear) => {
    let queryString = '';
    let dataValue = {
      msisdn,
      type,
      ...data,
    };

    if (isClear) {
      dataValue = {
        ...data,
      };
    }

    if (dataValue.msisdn) {
      queryString = updateQueryStringParameter(
        queryString,
        'msisdn',
        dataValue.msisdn
      );
    }

    if (isUrl) {
      if (dataValue.type) {
        queryString = updateQueryStringParameter(
          queryString,
          'type',
          dataValue.type
        );
      }
    }

    return queryString;
  };

  const onSearch = (type) => {
    let query = buildQueryString({ type }, true);
    const currPath = window.location.search;
    if (msisdn !== accountInfoDetail?.phoneNumber) {
      // if msisdn change and search => clear accountInfoDetail
      setAccountInfoDetail(null);
    }
    if (query !== currPath) {
      navigate(`/search${query || ''}`);
    } else {
      getListData();
    }
  };

  const onClearSearch = () => {
    setMsisdn(null);
    let query = buildQueryString({}, true, true);
    navigate(`/search${query || ''}`);
    setData(null);
    setAccountInfoDetail(null);
    setModalData(null);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columnsUsingPackages = [
    {
      title: 'Mã gói',
      dataIndex: 'packCode',
      key: 'packCode',
    },
    {
      title: 'Giá gói (VNĐ)',
      dataIndex: 'packPrice',
      key: 'packPrice',
      render: (cell) => {
        return cell ? cell.toLocaleString() : '--';
      },
    },
    {
      title: 'Thời gian đăng ký',
      dataIndex: 'registerDate',
      key: 'registerDate',
      render: (cell) => {
        return cell
          ? moment(cell, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY | HH:mm:ss')
          : '---';
      },
    },
    {
      title: 'Thời gian hết hạn',
      dataIndex: 'expireDate',
      key: 'expireDate',
      render: (cell) => {
        return cell
          ? moment(cell, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY | HH:mm:ss')
          : '---';
      },
    },
    {
      title: 'Dung lượng tổng',
      dataIndex: 'totalData',
      key: 'totalData',
      render: (cell, record) => {
        return (
          <div className="list-data-cell">
            {record?.totalData && (
              <div>DATA | {record?.totalData} MB | ngày</div>
            )}
            {record?.totalVoiceLM && (
              <div>VOICE_LM | {record?.totalVoiceLM} phút | tháng</div>
            )}
            {record?.totalVoiceKM && (
              <div>VOICE_KM | {record?.totalVoiceKM} phút | tháng</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Dung lượng còn lại',
      dataIndex: 'data',
      key: 'data',
      render: (cell, record) => {
        return (
          <div className="list-data-cell">
            {record?.data && <div>DATA | {record?.data} MB | ngày</div>}
            {record?.voiceLM && (
              <div>VOICE_LM | {record?.voiceLM} phút | tháng</div>
            )}
            {record?.voiceKM && (
              <div>VOICE_KM | {record?.voiceKM} phút | tháng</div>
            )}
          </div>
        );
      },
    },
  ];

  const columnsSuggestedPackages = [
    {
      title: 'Loại',
      dataIndex: 'name',
      key: 'name',
      render: (cell) => {
        return <div className="bold">{cell || '--'}</div>;
      },
    },
    {
      title: 'Danh sách gói cước',
      dataIndex: 'package',
      key: 'package',
      render: (cell, row) => {
        return <div className="bold">{cell || '--'}</div>;
      },
    },
  ];

  let tableHeight = windowSize?.height - 65 - 10 - 72 - 20;

  const renderBoxSearch = () => {
    return (
      <div className={'dpl-flex align-items-center pd-8 pdl-15 pdr-15'}>
        <InputNumber
          prefix={<SearchOutlined style={{ paddingRight: 8 }} />}
          placeholder="Nhập số điện thoại không chứa đầu 0 (Ví dụ: 931102968)"
          controls={false}
          style={{
            ...inputStyleDivided,
            width: '24%',
            marginLeft: 0,
            marginBottom: '0px !important',
          }}
          value={msisdn}
          onChange={(e) => {
            setMsisdn(e);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSearch();
            }
          }}
        />
        <Button
          className="mgl-10"
          onClick={() => onSearch()}
          type="primary"
          icon={<SearchOutlined />}
          loading={!type && isLoading}
        >
          Tra cứu
        </Button>

        {/* <Button
          disabled={true}
          className="mgl-10 btn-filters"
          // onClick={() => onSearch('b9')}
          icon={<SearchOutlined />}
          loading={type === 'b9' && isLoading}
        >
          Tra cứu B9
        </Button>

        <Button
          disabled={true}
          className="mgl-10 btn-filters"
          // onClick={() => onSearch('account')}
          icon={<SearchOutlined />}
          loading={type === 'account' && isLoading}
        >
          {accountInfoDetail?.amount
            ? `TKC ${parseFloat(accountInfoDetail?.amount).toLocaleString()}`
            : 'Tra cứu TKC'}
        </Button> */}
      </div>
    );
  };

  // get history packages text
  let historyPackagesText = arrStringToString(data?.historyPackages);

  return (
    <div>
      <Helmet>
        <title>Tra cứu thông tin</title>
        <meta name="description" content={projectName} />
      </Helmet>
      {renderBoxSearch()}

      {data && (
        <div
          className="scroll-box custom-empty"
          style={{ maxHeight: tableHeight }}
        >
          <div className="txt-title txt-blue pdbt-10 pdl-15">
            Danh sách gói cước đang sử dụng
          </div>
          <Table
            rowKey={'id'}
            dataSource={
              Array.isArray(data?.usingPackages) ? data?.usingPackages : []
            }
            columns={columnsUsingPackages}
            pagination={{
              hideOnSinglePage: true,
              total: Array.isArray(data?.usingPackages)
                ? data?.usingPackages.length
                : 0,
            }}
          />

          {/* <div className="txt-title txt-blue pdbt-10 pdt-20 pdl-15">
            Lịch sử đăng ký gói cước
          </div>
          <div className="pdl-15 pdr-15">
            {historyPackagesText ? (
              <div className="bold">{historyPackagesText}</div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div> */}

          <div className="txt-title txt-blue pdbt-10 pdt-20 pdl-15">
            Danh sách gói cước có thể đăng ký
          </div>
          <Table
            rowKey={'id'}
            dataSource={convertSuggestedPackages(data?.suggestedPackages)}
            columns={columnsSuggestedPackages}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </div>
      )}

      {isModalOpen && (
        <ModalInfo
          dataDetail={modalData}
          modalStatus={isModalOpen}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default Search;
