import React, { useContext, useEffect, useRef } from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_VALUE } from 'src/utils/enum';
import LocalStoreInstance from 'src/utils/localStorage';
import userServices from 'src/services/users.service';
import AppContext from 'src/context/AppContext';
import { APP_CONTEXT_ACTIONS } from 'src/context/appAction';
import { ROUTERS } from 'src/utils/constants';

function Login() {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const formRef = useRef();
  const onFinish = async (values) => {
    const resp = await userServices.login(values);
    if (resp?.status === 200) {
      if (resp?.data?.needUpdatePassword || resp?.data?.firstLogin) {
        navigate(ROUTERS.ChangePassword.path);
      } else {
        LocalStoreInstance.getInstance().save(
          LOCAL_STORAGE_VALUE.loginSession,
          resp?.data
        );
        getProfile();
      }
    } else {
      if (formRef?.current) {
        formRef?.current?.setFields([
          {
            name: 'username',
            errors: [''],
          },
          {
            name: 'password',
            errors: ['Sai thông tin đăng nhập'],
          },
        ]);
      }
    }
  };

  const getProfile = async () => {
    const resp = await userServices.getProfile();
    const data = resp?.data;
    if (resp?.data?.firstLogin === 1) {
      navigate(ROUTERS.ChangePassword.path);
      return;
    }
    if (resp?.status === 200) {
      dispatch({
        type: APP_CONTEXT_ACTIONS.UPDATE_PROFILE,
        payload: data,
      });
      LocalStoreInstance.getInstance().save(
        LOCAL_STORAGE_VALUE.currentLoggedUser,
        data
      );
      const redirectUrl = LocalStoreInstance.getInstance().read(
        LOCAL_STORAGE_VALUE.redirectUrl
      );
      if (redirectUrl) {
        LocalStoreInstance.getInstance().save(
          LOCAL_STORAGE_VALUE.redirectUrl,
          null
        );
        window.location.href = redirectUrl;
      } else {
        window.location.reload();
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    dispatch({
      type: APP_CONTEXT_ACTIONS.CLEAR_APP_DATA,
    });
  }, []);

  return (
    <div className="login-container">
      <div className="login-box">
        <h1 className="text-center txt-blue text-4xl pb-16 text-{#1677FF}">
          WELCOME
        </h1>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size={'large'}
        >
          <p className="p-0 text-zinc-400 mb-1.5 text-lg font-bold">
            Tên đăng nhập
          </p>
          <Form.Item
            style={{ paddingBottom: 20 }}
            name="username"
            validateTrigger={'onBlur'}
            rules={[
              { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
            ]}
          >
            <Input
              prefix={
                <UserOutlined className="site-form-item-icon pdl-10 pdr-10" />
              }
              placeholder="Nhập tên đăng nhập..."
              style={{ height: 45, border: '1px solid #1677FF' }}
            />
          </Form.Item>
          <p className="p-0 text-zinc-400 mb-1.5 text-lg font-bold">Mật khẩu</p>
          <Form.Item
            name="password"
            validateTrigger={'onBlur'}
            rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}
          >
            <Input.Password
              prefix={
                <LockOutlined className="site-form-item-icon pdl-10 pdr-10" />
              }
              placeholder="Nhập mật khẩu..."
              style={{ height: 45, border: '1px solid #1677FF' }}
            />
          </Form.Item>

          <div className="text-center pdt-20 mgbt-20">
            <Button
              type="primary"
              htmlType="submit"
              style={{ minWidth: '11.3rem', height: '3.19rem', fontSize: 20 }}
            >
              Đăng nhập
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
