import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { Spin } from 'antd';

import dataManagerServices from 'src/services/dataManager.service';
import { isNullOrEmpty } from 'src/utils/stringUtils';
import Filters from './components/Filters';
import Info from './components/Info';
import InteractionHistory from './components/InteractionHistory';
import SubscriberProfile from './components/SubscriberProfile';
import UpdateLevelSale from './components/UpdateLevelSale';
import { dataDividedDetailService } from 'src/hooks/dataDivided/DataDividedDetailService';
import { ErrorBox } from 'src/components';
import { projectName } from 'src/utils';
import './style.scss';
import { useNotificationWebSocket } from 'src/hooks/useNotificationWebSocket';

type UIState = {
  profile?: DataDividedProfile;
  customerStages: SaleBaseData[];
  loading: boolean;
};

export const DataDividedDetailContext = React.createContext<any>(null);

function DataDividedDetail() {
  const { phone } = useParams();
  const nav = useNavigate();
  const [UIState, setState] = useState<UIState>({
    profile: undefined,
    customerStages: [],
    loading: true,
  });
  const { message } = useNotificationWebSocket();

  const onReloadData = () => {
    setState((cur) => ({ ...cur, loading: true }));
    fetchData();
  };

  const {
    handleHideTopUp,
    handleSearch,
    hiddenModal,
    hideModalRegisterPackage,
    isDisableCRM,
    isEnableBtnRegisterAndTopup,
    onCreateRequest,
    onOpenChangeRegister,
    onOpenChangeTopup,
    onRegisterPackage,
    onSaveOtp,
    openModalRegisterPackage,
    state: stateHook,
    updateOtp,
    openTopupTransaction,
    openTopupRecordOutside,
    handleFinishTopupTransfer,
    openModalConfirmTransaction,
    refConfirmTransaction,
    handleSearchVip,
  } = dataDividedDetailService.useInfoDataDivided(
    UIState?.profile?.dataId as any,
    UIState?.profile,
    phone ?? '',
    onReloadData
  );

  const [isShowErrorMessage, setIsShowErrorMessage] = useState<boolean>(true);

  const fetchData = async () => {
    if (!phone) {
      setIsShowErrorMessage(true);
      return;
    }
    const response = await dataManagerServices.getDataDividedDetail(phone);
    const response2 = await dataManagerServices.getCustomerStages(
      response?.data?.dataId
    );

    if (response.status === 200) {
      setIsShowErrorMessage(false);
      setState((cur) => ({
        ...cur,
        profile: response?.data,
        customerStages: response2?.data,
        loading: false,
      }));
    } else {
      setIsShowErrorMessage(true);
      setState((cur) => ({
        ...cur,
        loading: false,
      }));
    }
  };
  useEffect(() => {
    if (message?.isSuccess === true) {
      onReloadData();
    }
  }, [message]);

  useEffect(() => {
    fetchData();
  }, []);

  if (!UIState.loading && isShowErrorMessage) {
    return <ErrorBox onClick={() => nav('/data-divided')} />;
  }

  return (
    <div>
      <Helmet>
        <title>{phone}</title>
        <meta name="description" content={projectName} />
      </Helmet>
      {!isNullOrEmpty(UIState.profile) && (
        <Info
          profile={UIState?.profile}
          onReloadData={onReloadData}
          handleHideTopUp={handleHideTopUp}
          handleSearch={handleSearch}
          hiddenModal={hiddenModal}
          isDisableCRM={isDisableCRM}
          isEnableBtnRegisterAndTopup={isEnableBtnRegisterAndTopup}
          onCreateRequest={onCreateRequest}
          onOpenChangeRegister={onOpenChangeRegister}
          onOpenChangeTopup={onOpenChangeTopup}
          onRegisterPackage={onRegisterPackage}
          onSaveOtp={onSaveOtp}
          openModalRegisterPackage={openModalRegisterPackage}
          hideModalRegisterPackage={hideModalRegisterPackage}
          state={stateHook}
          updateOtp={updateOtp}
          openTopupTransaction={openTopupTransaction}
          openTopupRecordOutside={openTopupRecordOutside}
          handleFinishTopupTransfer={handleFinishTopupTransfer}
          callBackUpdate={onReloadData}
          openModalConfirmTransaction={openModalConfirmTransaction}
          refConfirmTransaction={refConfirmTransaction}
        />
      )}
      <Spin spinning={UIState.loading}>
        <Filters profile={UIState.profile} />
        <div className="flex flex-1 h-full" id="data-divided">
          <div className="flex-1 flex flex-col overflow-hidden">
            <div className="mr-1 mb-1">
              <UpdateLevelSale
                customerStages={UIState.customerStages}
                profile={UIState.profile}
                onSubmitSuccess={onReloadData}
                dataId={UIState?.profile?.dataId}
              />
            </div>
            <div className="mt-1 mr-1" style={{ overflowWrap: 'break-word' }}>
              <SubscriberProfile
                dataId={UIState?.profile?.dataId ?? 0}
                searchedSubscriberInfo={UIState.profile?.searchedSubscriberInfo}
                subscriberInfoWithSearching={
                  UIState.profile?.subscriberInfoWithSearching
                }
                subscriberInfoWithoutSearching={
                  UIState.profile?.subscriberInfoWithoutSearching
                }
                onSubmitSuccess={onReloadData}
                profile={UIState?.profile}
                handleSearch={handleSearch}
                usingPackageCode={stateHook.usingPackageCode}
                loadingTurnUp={stateHook.loadingTurnUp}
                loadingSearchVip={stateHook?.loadingSearchVip}
                handleSearchVip={handleSearchVip}
              />
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-hidden">
            <InteractionHistory data={UIState?.profile?.interactionHistories} />
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default DataDividedDetail;
