import { Button, DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { datePickerStyle, filterBoxStyle } from 'src/styles';
import { isUserHaveOneOfPermissions } from 'src/utils/stringUtils';
import SelectSaleMan from 'src/components/SelectSaleMan';
import { DownloadOutlined } from '@ant-design/icons';
import React from 'react';
import { DATE_FORMAT } from 'src/utils/constants';

type Props = {
  fromDate: moment.Moment;
  toDate: moment.Moment;
  treeData: any[];
  staffs: string[];
  loading: boolean;
  onChangeSelectDate: (_: any, value: string[]) => void;
  handleLoadingExport: () => void;
  loadingExport: boolean;
  onChangeStaffs: (value: string[]) => void;
  handleSelectBox: (item: any) => void;
};

export const Filter = ({
  fromDate,
  toDate,
  treeData,
  staffs,
  loadingExport,
  handleLoadingExport,
  onChangeSelectDate,
  onChangeStaffs,
  handleSelectBox,
}: Props) => {
  const fromDateMoment: Moment = moment(fromDate);
  const toDateMoment: Moment = moment(toDate);
  const [listFilter, setListFilter] = React.useState([
    {
      id: 1,
      name: 'Hôm nay',
      value: [moment().startOf('day'), moment().endOf('day')],
    },
    {
      id: 2,
      name: 'Tuần này',
      isActive: false,
      value: [
        moment().startOf('week').startOf('day'),
        moment().endOf('week').endOf('day'),
      ],
    },
    {
      id: 3,
      name: 'Tháng này',
      isActive: true,
      value: [moment().startOf('month').startOf('day'), moment().endOf('day')],
    },
    {
      id: 4,
      name: 'Hôm qua',
      value: [
        moment().subtract(1, 'days').startOf('day'),
        moment().subtract(1, 'days').endOf('day'),
      ],
    },
    {
      id: 5,
      name: 'Tuần trước',
      isActive: false,
      value: [
        moment().subtract(1, 'weeks').startOf('week').startOf('day'),
        moment().subtract(1, 'weeks').endOf('week').endOf('day'),
      ],
    },
    {
      id: 6,
      name: 'Tháng trước',
      isActive: false,
      value: [
        moment().subtract(1, 'months').startOf('month').startOf('day'),
        moment().subtract(1, 'months').endOf('month').endOf('day'),
      ],
    },
  ]);
  return (
    <div className="box-search pdbt-10 pdt-10">
      {listFilter?.map((item, index) => {
        const selectBox = (selectedValue: any[]) => {
          const updatedListFilter = listFilter.map((item) => ({
            ...item,
            isActive:
              selectedValue[0] === item.value[0] &&
              selectedValue[1] === item.value[1],
          }));
          setListFilter(updatedListFilter);
          handleSelectBox?.(selectedValue);
        };

        return (
          <Button
            key={index}
            className={`mgr-5 ${item?.isActive ? 'active' : 'default'} `}
            onClick={() => selectBox(item.value)}
          >
            {item.name}
          </Button>
        );
      })}
      <DatePicker.RangePicker
        style={{
          ...datePickerStyle,
          width: '17vw',
          marginLeft: 0,
          borderRadius: 5,
        }}
        placeholder={['Ngày tạo từ', 'Ngày tạo đến']}
        onChange={onChangeSelectDate}
        format={DATE_FORMAT}
        value={[fromDateMoment, toDateMoment]}
      />
      {isUserHaveOneOfPermissions([
        'ROLE_ADMIN',
        'ROLE_SALE_LEADER',
        'ROLE_SALE_MANAGER',
        'ROLE_ACCOUNTANT',
      ]) && (
        <SelectSaleMan
          style={filterBoxStyle}
          treeData={treeData}
          value={staffs}
          placeholder={'Người tạo'}
          onChange={onChangeStaffs}
        />
      )}

      <Button
        onClick={handleLoadingExport}
        className="ml-2"
        type="primary"
        icon={<DownloadOutlined />}
        loading={loadingExport}
      >
        Export
      </Button>
    </div>
  );
};
